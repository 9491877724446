import React,{ useEffect } from 'react'

export const AnalyticalDashborad = (props) => {

    useEffect(() => {
        const containerDiv = document.getElementById('tableauVizContainer');
    
        const options = {
          width: '100%',
          height: '245vh',
          hideTabs: false,
          hideToolbar: false,
          onFirstInteractive: () => {
            console.log('Tableau Viz is ready');
          },
        };
        
    
        const vizUrl = 'https://public.tableau.com/views/PoliceDashboard_16319740976170/Dashboard1?:language=en-US&:display_count=n&:origin=viz_share_link';
        const viz = new window.tableau.Viz(containerDiv, vizUrl, options);
    
        return () => {
          viz.dispose(); // Clean up when the component is unmounted
        };
      }, []); // Run once when the component mounts
    

    return (
        <div className="pageLayout">
            <div id="tableauVizContainer"></div>
        </div>
    )
}

export default AnalyticalDashborad