import React, { useEffect, useState } from 'react'
import { Button, Col, Row, Modal, Form } from 'react-bootstrap'
import TableData from '../../Component/Common/Tables'
import { FaPlus } from "react-icons/fa6";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import moment from 'moment';
import S3 from "react-aws-s3";
import Compressor from "compressorjs";
import CryptoJS from 'crypto-js';
import Pagination from '../../Component/Common/Pagination';
import EditIncident from './Modal/EditIncident';
import DeleteIncident from './Modal/DeleteIncident';

window.Buffer = window.Buffer || require("buffer").Buffer;

const IncidentCategory = () => {

    // Table variable
    const [userData, setUserData] = useState([])
    const [loading, setLoading] = useState(false)

    // Add category varibles
    const [showAdd, setShowAdd] = useState(false)
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [image, setImage] = useState('')
    const [loader, setLoader] = useState(false)
    const [descError, setDescError] = useState(false)
    const [titleError, setTitleError] = useState(false)

    // for edit model
    const [showEdit, setShowEdit] = useState(false);
    const [item, setItem] = useState();
    const [incidentid, setIncidentId] = useState()

    // for delete modal
    const [showdeletemodal, setShowDeleteModal] = useState(false);
    const [incidentData, setIncidentData] = useState([])

    // state for pagination
    const [docsPerPage, setDocsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);


    useEffect(() => {
        getData()
    }, [currentPage, docsPerPage])

    // for edit open and close modal
    const handleShowEditModal = (id) => {
        setShowEdit(id);
        setItem(id)
    };
    const handlaEditModalClose = () => {
        setShowEdit(false);
        setItem("")
        getData()
    };

    // function for delete modal
    const handleShowDeleteModal = (id) => {
        setIncidentId(id);
        setShowDeleteModal(true);
    };

    const handleDeleteModalClose = () => {
        getData()
        setShowDeleteModal(false);
        setIncidentId("");
    };

    // function for pagination
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleDocsPerPage = (docsPageProp) => {
        setCurrentPage(1);
        setDocsPerPage(docsPageProp);
    };

    // Incident API Integration
    const getData = async () => {
        try {
            setLoading(true)
            const res = await axios.get(`https://server.sps.foxberry.link/v1/incident/get/all?documentsPerPage=${docsPerPage}&page=${currentPage}`)
            if (res.status === 200) {
                if (res.data.data.length > 0) {
                    getincidentData(res.data.data)
                    setIncidentData(res.data)
                }
                setLoading(false)
            } else {
                toast.error("No data available")
                setLoading(false)

            }
        } catch (err) {
            toast.error("No data available")
            setLoading(false)

        }
    }

    // Data Array management according to TableData Compoenent
    const getincidentData = (data) => {
        const array = data.length > 0 ? data.map((item, index) => {
            return {
                SrNo: currentPage !== 1 ? (<> {index + 1 + docsPerPage * (currentPage - 1)}</>) : <>{index + 1}</>,
                incidentId: item.incidentId,
                title: item.title,
                description: item.description,
                image: item.image,
                status: item.status,
                createdAt: moment(item.createdAt).format(" DD-MMM-YYYY hh:mm a "),
                action: <div className='d-flex justify-content-center align-items-center'>
                    <Button className='primaryBtn ' onClick={() => { handleShowEditModal(item) }} >Edit</Button>
                    <Button className='secondaryBtn ms-3' onClick={() => handleShowDeleteModal(item?.incidentId)} >Delete</Button>
                </div>
            };
        })
            : [];
        setUserData(array)

    }

    // Colums Title and variable access name for TableData Compoenent 
    const columns = [
        { header: 'Sr No', field: 'SrNo' },
        // { header: 'Incident Id', field: 'incidentId' },
        { header: 'Title', field: 'title' },
        { header: 'Description', field: 'description' },
        { header: 'Reported Date', field: 'createdAt' },
        { header: 'Action', field: 'action' },

    ]

    const showHideModal = () => {
        setShowAdd(!showAdd)
    }

    const handleChange = (e) => {
        if (e.target.files.length > 0) {
            const img = URL.createObjectURL(e.target.files[0]);
            setLoader(true);
            if (e.target.files[0].type.split('/')[0] === 'image') {
                uploadImageToS3(e.target.files[0]);
                setLoader(false)
            } else {
                toast.error("Something Else")
                setLoader(false)
            }
        }
    };

    const decryptData = cyphertext => {
        var bytes = CryptoJS.AES.decrypt(cyphertext, 'foxberry@12345');
        var plaintext = bytes.toString(CryptoJS.enc.Utf8);

        return plaintext;
    };

    const uploadImageToS3 = async (event) => {

        const res = await axios.post(process.env.REACT_APP_BASE_URL + '/credentials/getcredentialsbyname', {
            name: 'AWS'
        })

        let encritedAccessKey = res.data[0]?.accessKey;
        let encritedSecretKey = res.data[0]?.secretKey;
        let encritedbucket = res.data[0]?.bucketName;
        let key = 'foxberry@12345';

        let accessKey = decryptData(encritedAccessKey);
        let secretKey = decryptData(encritedSecretKey);
        let bucket = decryptData(encritedbucket);

        const config = {
            bucketName: bucket,
            // dirName: res.data[0].dirName,
            region: res.data[0].region,
            accessKeyId: accessKey,
            secretAccessKey: secretKey,
            // s3Url: res.data[0].s3url,
        };

        let fileInput = false;
        const image = event;
        if (event) {
            fileInput = true;
        }
        if (fileInput) {
            new Compressor(image, {
                quality: 0.6,
                success: (compressedResult) => {
                    let getTimeStamp = new Date().getTime();
                    let newFileName = getTimeStamp + "_";


                    const ReactS3Client = new S3(config);

                    ReactS3Client.uploadFile(compressedResult, newFileName)
                        .then((res) => {
                            if (res.status === 204) {
                                setImage(res.location);
                            } else {
                                toast.error("AWS Error")
                            }
                        })
                        .catch((err) => {
                            toast.error(err);
                        });
                },
            });

        }
    }

    const validate = () => {
        let validate = true

        if (title) {
            validate = true
            setTitleError(false)
        } else {
            validate = false
            setTitleError(true)
        }

        if (description) {
            validate = true
            setDescError(false)
        } else {
            validate = false
            setDescError(true)
        }

        return validate
    }

    const saveChanges = async () => {

        if (validate()) {
            let payload = {
                title: title,
                description: description,
                image: image
            }

            const res = await axios.post(process.env.REACT_APP_BASE_URL + "/incident/insert", payload)
            if (res.status === 200) {
                setTitle('')
                setDescription('')
                setImage('')
                setCurrentPage(1)
                setDocsPerPage(10)
                getData()
                setShowAdd(false)
            }
        }

    }


    return (
        <div className="pageLayout">
            <EditIncident show={showEdit} close={handlaEditModalClose} item={item} />
            <DeleteIncident show={showdeletemodal} close={handleDeleteModalClose} incidentId={incidentid} />
            <ToastContainer />
            <Row>
                <Col md={8} sx={6}>

                </Col>
                {/* Add Incident Button */}
                <Col md={4} sx={6} style={{ justifyContent: "flex-end", display: "flex" }}>
                    <Button className='primaryBtn ms-2' style={{ width: '15%' }} onClick={() => showHideModal()}>
                        <span><FaPlus style={{ fontSize: "20px" }} title='Add' /></span>
                    </Button>
                </Col>
            </Row>
            {/* Incident Table */}
            {
                loading ?
                    <div className="text-center">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div> : userData.length > 0 ?
                        <>
                            <TableData data={userData} columns={columns} />
                            <Pagination
                                currentPage={currentPage}
                                totalPages={incidentData?.totalPages}
                                onPageChange={handlePageChange}
                                onDocsPerPage={handleDocsPerPage}
                                docsPerPage={docsPerPage}
                            />
                        </>
                        : <span>No data dound</span>
            }
            <Modal show={showAdd} onHide={showHideModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add Incident Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                        <Row style={{ width: '100%' }}>
                            <Form.Label>Add Incident Title</Form.Label>
                            <Form.Control placeholder='*Title' value={title}
                                onChange={(e) => {
                                    if (
                                        !e.target.value.match(/[#$&%!~=^_{}\[\]*|//\/?<>;`/\\0-9'.,@()-+]/) ||
                                        e.target.value === ''
                                    ) {
                                        setTitle(e.target.value)

                                    }
                                }} />
                            {titleError ? (<p className="errMsg">* Required Field</p>) : null}

                        </Row>
                        <Row style={{ width: '100%' }}>
                            <Form.Label>Add Incident Description</Form.Label>
                            <Form.Control placeholder='*Description' value={description}
                                onChange={(e) => {
                                    if (
                                        !e.target.value.match(/[#$&%!~=^_{}\[\]*|//\/?<>;`/\\0-9'.,@()-+]/) ||
                                        e.target.value === ''
                                    ) {
                                        setDescription(e.target.value)
                                    }
                                }} />
                            {descError ? (<p className="errMsg">* Required Field</p>) : null}

                        </Row>
                        <Row style={{ width: '100%' }}>
                            <Form.Label>Add Incident Image/Logo</Form.Label>
                            <div>
                                {image !== '' ? <img src={image} style={{ width: 100, height: 100, margin: 10 }} /> : null}
                                <label htmlFor='Image' className='btn btn-primary choosefile' >
                                    Choose file
                                </label>
                                <input id='Image' style={{ display: 'none' }} accept="image/*" type="file" onChange={(e) => handleChange(e)} />
                            </div>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => saveChanges()}>
                        Add Incident
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default IncidentCategory