import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
    SOSList: [],
    AllSosList:[],
    loading: 'idle',
};

export const getSosListData = createAsyncThunk("getSosDataPromise", async (data, { rejectWithValue }) => {

    const response = await fetch(process.env.REACT_APP_BASE_URL + data, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
    })
    try {
        const result = await response.json()
        return result
    } catch (error) {
        return rejectWithValue(error);
    }
}
)

export const getAllSosData = createAsyncThunk("getAllSosDataPromise", async (data, { rejectWithValue }) => {

    const response = await fetch(process.env.REACT_APP_BASE_URL + data, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
    })
    try {
        const result = await response.json()
        return result
    } catch (error) {
        return rejectWithValue(error);
    }
}
)
const SosSlice = createSlice({
    name: 'soslist',
    initialState,
    extraReducers: builder => {
        builder
            .addCase(getSosListData.pending, (s, a) => {
                s.loading = 'pending';
            })
            .addCase(getSosListData.rejected, (s, a) => {
                s.loading = 'idle';
            })
            .addCase(getSosListData.fulfilled, (s, a) => {
                s.SOSList = a.payload;
                s.loading = 'success';
            })
            .addCase(getAllSosData.pending, (s, a) => {
                s.loading = 'pending';
            })
            .addCase(getAllSosData.rejected, (s, a) => {
                s.loading = 'idle';
            })
            .addCase(getAllSosData.fulfilled, (s, a) => {
                s.AllSosList = a.payload;
                s.loading = 'success';
            });
    }
});



export const { } = SosSlice.actions;
export default SosSlice.reducer;