import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Row,
  Table,
  ToggleButton,
} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { FcRules } from "react-icons/fc";
import { useDispatch, useSelector } from "react-redux";
import { setVolunteeList, getVolunteerData } from "./VolunteerSlice";
import Pagination from "react-bootstrap/Pagination";
import { AiFillBell } from "react-icons/ai";
import TableData from "../../Component/Common/Tables";
import file from "../../assets/docs 1.png";
import VerifyModel from "./Model/VerifyModel";
import { setActiveToggle } from "../../Component/Sidebar/sidebarSlice";

const VolunteerList = () => {
  const dispatch = useDispatch();
  const VolunteerListData = useSelector((state) => state?.volunteerData?.volunteerList);
  console.log("VolunteerListData", VolunteerListData)

  const currentToggle = useSelector(state => state?.SideBarData?.activeToggle)
  console.log("currentToggle", currentToggle)

  // state for pagination
  const [docsPerPage, setDocsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  // State variable for search term
  const [searchTerm, setSearchTerm] = useState('');

  // Function to handle search term change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Function to filter data based on search term
  const filterData = () => {
    return VolunterrAllData.filter((item) =>
      // Check if the item details contain the search term
      Object.values(item).some((value) =>
        String(value).toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  };

  // for verify
  const [showverify, setShowVerify] = useState(false);
  const [modaldetails, setModalDetails] = useState();

  // for verifydetails
  const [showverifydetail, setShowVerifyDetail] = useState(false);

  const handleCloseVerifyModel = () => {
    setShowVerify(false);
  };

  const handleCloseVerifyDetailsModel = () => {
    setShowVerifyDetail(false);
  };

  // function for pagination
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setCurrentPage(1);
    setDocsPerPage(docsPageProp);
  };

  // for status toggle change
  const handleToggleChange = (toggleValue) => {
    dispatch(setActiveToggle(toggleValue));
  };

  const openEditModal = (item) => {
    setModalDetails(item);
    setShowVerify(true);
  };


  const getALLVolunteer = () => {
    const payload = {
      verifyStatus: currentToggle,
      documentsPerPage: docsPerPage,
      page: currentPage,
    };
    dispatch(getVolunteerData(payload))
  }

  useEffect(() => {
    getALLVolunteer();
  }, [currentPage, docsPerPage, currentToggle]);

  // krupa changes
  const VolunterrAllData =
    VolunteerListData?.data !== undefined &&
      VolunteerListData?.data !== null &&
      VolunteerListData?.data?.length > 0
      ? VolunteerListData?.data
        ?.map((itm, index) => {
          return {
            Name: itm?.firstName + " " + itm?.lastName,
            Mobile: itm?.contactNo,
            Email: itm?.emailId,
            details: (
              <>
                {currentToggle === "verified" ? (
                  <Button
                    key={index}
                    className="primaryBtn"
                    onClick={() => openEditModal(itm)}
                  >
                    View
                  </Button>
                ) : (
                  <Button
                    className="primaryBtn"
                    onClick={() => openEditModal(itm)}
                  >
                    Verify
                  </Button>
                )}
              </>
            ),
          };
        })
      : [];

  const columns = [
    { header: "Name", field: "Name" },
    { header: "Mobile", field: "Mobile" },
    { header: "Email", field: "Email" },
    { header: "Details", field: "details" },
  ];

  return (
    <div className="pageLayout">
      <VerifyModel
        show={showverify}
        data={modaldetails}
        close={handleCloseVerifyModel}
        allvolunteer={getALLVolunteer}
      />

      <Row className='d-flex flex-column-reverse flex-md-row justify-content-between align-items-center  mt-5'>
        <Col md={4} className='mt-4 md-mb-0 pb-2'>
          <Form.Group>
            <Form.Control
              type="search"
              placeholder="Search Data"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </Form.Group>
        </Col>

        <Col md={6} className='d-flex justify-content-end mt-2 md-mt-0'>
          <ButtonGroup aria-label="Basic example">
            <Button
              disabled={currentToggle === "verified" ? true : false}
              variant={currentToggle === 'verified' ? 'success' : 'outline-success'} // Use 'warning' when active, 'light' otherwise
              onClick={() => {
                handleToggleChange('verified');
                // handleDocsPerPage(10);
              }}
            >
              Verified
            </Button>
            <Button
              disabled={currentToggle === "unverified" ? true : false}
              variant={currentToggle === 'unverified' ? 'warning' : 'outline-warning'}
              onClick={() => { handleToggleChange('unverified'); }}
            >
              Unverified
            </Button>
            <Button
              disabled={currentToggle === "rejected" ? true : false}
              variant={currentToggle === 'rejected' ? 'danger' : 'outline-danger'}
              onClick={() => { handleToggleChange('rejected') }}
            >
              Rejected
            </Button>
          </ButtonGroup>
        </Col>
      </Row>

      <div className='outer-wrapper mx-auto'>
        <div className='table-wrapper'>
          <TableData data={filterData()} columns={columns} />
        </div>
      </div>

      <Pagination
        currentPage={currentPage}
        totalPages={VolunterrAllData?.totalPages}
        onPageChange={handlePageChange}
        onDocsPerPage={handleDocsPerPage}
        docsPerPage={docsPerPage}
      />
    </div >
  );
};
export default VolunteerList;
