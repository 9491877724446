import React, { useEffect, useState } from 'react'
import TableData from '../../Component/Common/Tables'
import DateRange from '../../Component/Common/DateRange'
import { useDispatch, useSelector } from 'react-redux';
import { getSosData } from './SosSlice';
import audio from '../../assets/audio 1.png'
import Pagination from '../../Component/Common/Pagination';
import location from "../../assets/pin 1.png"


const SosList = () => {

  const dispatch = useDispatch()
  const sosList = useSelector((state) => state?.sosData?.SosList)

  // state for pagination
  const [docsPerPage, setDocsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [fromDate, setFromDate] = useState("")
  const [toDate, setToDate] = useState("")
  const [loading, setLoading] = useState(true);


  const handleFromDateChange = (e) => {
    setFromDate(e.target.value)
  }

  const handleToDateChange = (e) => [
    setToDate(e.target.value)
  ]

  // function for pagination
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setCurrentPage(1);
    setDocsPerPage(docsPageProp);
  };

  useEffect(() => {
    const payload = `/sos/filtersos?page=${currentPage}&documentsPerPage=${docsPerPage}`
    dispatch(getSosData(payload))
      .then(() => {
        setLoading(false);
      }).catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  }, [docsPerPage, currentPage])

  const handleFilter = () => {
    const filterPayload = `/sos/filtersos?startDate=${fromDate}&endDate=${toDate}`
    dispatch(getSosData(filterPayload))
  }

  const handleReset = () => {
    setFromDate("")
    setToDate("")
    const payload = "/sos/filtersos"
    dispatch(getSosData(payload))
  }

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const sosData = sosList?.data !== undefined && sosList?.data !== null && sosList?.data?.length > 0 ?
    sosList?.data?.map((itm, index) => {
      const createdAtDate = new Date(itm.createdAt);

      const formattedDate = createdAtDate.toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      });

      const formattedTime = createdAtDate.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      });
      // ui for status text color
      const textColor = itm?.trackingStatus === 'stopby' ? 'red' : 'green';
      return {
        SrNo: currentPage !== 1 ? (<> {index + 1 + docsPerPage * (currentPage - 1)}</>) : <>{index + 1}</>,
        name: itm?.user?.first_name + " " + itm?.user?.last_name,
        mobile: itm?.user?.mobile,
        sosRaised: `${formattedDate} ${formattedTime}`,
        status: itm?.trackingStatus === 'stopby' ? <span style={{ color: 'red' }}>Stop By</span> : <span style={{ color: 'orange' }}>Tracking</span>,
        audio:  itm.audioURL ? <img src={audio} onClick={() => { window.open(itm.audioURL) }} /> : 'Not Available' ,
        location: <img style={{ cursor: 'pointer' }} src={location} onClick={() =>
          openInNewTab(
            `https://sps.foxberry.link/trackme?trackingId=${itm?.trackeMeId}`
          )
        } />,
      };
    })
    : [];

  const columns = [
    { header: 'Sr No', field: 'SrNo' },
    { header: 'Name', field: 'name' },
    { header: 'Mobile', field: 'mobile' },
    { header: 'SOS Raised', field: 'sosRaised' },
    { header: 'Status', field: 'status' },
    { header: 'Audio', field: 'audio' },
    { header: 'Location', field: 'location' },
  ];

  return (
    <div className="pageLayout">
      <DateRange fromDate={fromDate} toDate={toDate} handleFromChange={handleFromDateChange}
        handleToChange={handleToDateChange} handleFilter={handleFilter} handleReset={handleReset} />
      {
        loading ? (
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : <>
          {sosData.length > 0 ? <>
            <div className='outer-wrapper mx-auto'>
              <div className='table-wrapper'>
                <TableData data={sosData} columns={columns} />
              </div>
            </div>
            <Pagination
              currentPage={currentPage}
              totalPages={sosList?.totalPages}
              onPageChange={handlePageChange}
              onDocsPerPage={handleDocsPerPage}
              docsPerPage={docsPerPage}
            />
          </> : <span>No data found</span>}
        </>}

    </div>)
}

export default SosList