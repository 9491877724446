import React, { useState } from 'react'
import { Button, ButtonGroup, Card, Col, Container, Form, Modal, Row, ToggleButton } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const AddNewPoliceStation = ({ show, close }) => {
    const navigate = useNavigate()
    const [selectedOption, setSelectedOption] = useState('');

    const stationTypeOptions = [{ value: 'rural', label: 'Rural' }, { value: 'arban', label: 'Arban' }];
    const [stationType, setstationType] = useState("")

    const policeStationAdded = () => {
        toast.success("Police station added successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000
        });
    }

    // state for new user
    const [addpolicestation, setAddPoliceStation] = useState({
        stationName: "",
        division: "",
        zone: "",
        email: "",
        telephoneNo: "",
        address: "",
        state: "",
        city: "",
        registartionNo: "",
    })
    const [iscybercrime, setIsCyberCrime] = useState(false)

    // error handling
    const [stationanmeerr, setStationNameErr] = useState(false)
    const [divisionerr, setDivisionErr] = useState(false)
    const [zoneerr, setZoneErr] = useState(false)
    const [emailerr, setEmailErr] = useState(false)
    const [telephoneerr, setTelephoneErr] = useState(false)
    const [addresserr, setAddressErr] = useState(false)
    const [stateerr, setStateErr] = useState(false)
    const [cityerr, setCityErr] = useState(false)
    const [registrationnoerr, setRegistrationNoErr] = useState(false)
    const [stationtypeerr, setStationTypeErr] = useState(false)
    const [cybererr, setCyberErr] = useState(false)

    // function for error handling
    const handlerValidate = () => {
        let FormValid = true;

        // if (addpolicestation.telephoneNo.length === 0) {
        //     FormValid = false;
        //     setTelephoneErr(true);
        // } else if (addpolicestation.telephoneNo.length !== 10) {
        //     FormValid = false;
        //     setTelephoneErr(true);
        // } else {
        //     setTelephoneErr(false);
        // }


        if (addpolicestation.registartionNo.length === 0) {
            FormValid = false;
            setRegistrationNoErr(true);
        }
        else if (addpolicestation.stationName.length === 0) {
            FormValid = false;
            setStationNameErr(true);
        }
        else if (stationType.length === 0) {
            FormValid = false;
            setStationTypeErr(true);
        }
        else if (addpolicestation.division.length === 0) {
            FormValid = false;
            setDivisionErr(true);
        }
        else if (addpolicestation.zone.length === 0) {
            FormValid = false;
            setZoneErr(true);
        }
        else if (addpolicestation.email.length === 0) {
            FormValid = false;
            setEmailErr(true);
        }
        else if (addpolicestation.telephoneNo.length === 0) {
            FormValid = false;
            setTelephoneErr(true);
        } else if (addpolicestation.telephoneNo.length !== 10) {
            FormValid = false;
            setTelephoneErr(true);
        }
        else if (iscybercrime.length === 0) {
            FormValid = false;
            setCyberErr(true);
        }
        else if (addpolicestation.address.length === 0) {
            FormValid = false;
            setAddressErr(true);
        }
        else if (addpolicestation.state.length === 0) {
            FormValid = false;
            setStateErr(true);
        }
        else if (addpolicestation.city.length === 0) {
            FormValid = false;
            setCityErr(true);
        }
        return FormValid;
    };

    const handleInput = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setAddPoliceStation({ ...addpolicestation, [name]: value })
    }

    const handleOptionChange = (option) => {
        setSelectedOption(option);
    };

    const handleAddPoliceStation = async (e) => {
        setStationNameErr(false)
        setDivisionErr(false)
        setStationTypeErr(false)
        setZoneErr(false)
        setCyberErr(false)
        setEmailErr(false)
        setTelephoneErr(false)
        setAddressErr(false)
        setStateErr(false)
        setCityErr(false)
        setRegistrationNoErr(false)
        if (handlerValidate()) {
            const payload = {
                "stationName": addpolicestation.stationName,
                "division": addpolicestation.division,
                "zone": addpolicestation.zone,
                "email": addpolicestation.email,
                "telephoneNo": addpolicestation.telephoneNo,
                "address": addpolicestation.address,
                "stationType": stationType?.value,
                "city": addpolicestation.city,
                "state": addpolicestation.state,
                "isCyberCrime": iscybercrime,
                "registartionNo": addpolicestation.registartionNo
            }
            console.log("payload", payload)

            await fetch(process.env.REACT_APP_BASE_URL + "/policestation/insertpolicestation", {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    "stationName": addpolicestation.stationName,
                    "division": addpolicestation.division,
                    "zone": addpolicestation.zone,
                    "email": addpolicestation.email,
                    "telephoneNo": addpolicestation.telephoneNo,
                    "address": addpolicestation.address,
                    "stationType": stationType?.value,
                    "city": addpolicestation.city,
                    "state": addpolicestation.state,
                    "isCyberCrime": iscybercrime,
                    "registartionNo": addpolicestation.registartionNo
                })
            })
                .then((res) => res.json())
                .then((response) => {
                    if (response?.message === "Policestation Add Successfully") {
                        policeStationAdded()
                        close()
                    }
                    navigate("/admin/policeStationList")
                })
                .catch((err) => {
                    console.log("err", err);
                })
        }
    }
    return (
        <>
            <ToastContainer />
            <div className='pageLayout'>
                <Modal show={show} onHide={close} size="lg" centered backdrop='static' keyboard={false}>
                    <Modal.Header closeButton className="mngmodelheader">
                        <Modal.Title><h5 style={{ fontWeight: "600" }}>Police Station</h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body size="lg">
                        <Row className='h-100 justify-content-center align-items-center px-3'>
                            <Row>
                                <Col md={12} className='pe-4 ps-4 ps-md-2'>
                                    <Form>
                                        <Row className='gy-3 mt-1'>
                                            <Col md={4}>
                                                <Form.Label>Registration No</Form.Label>
                                                <Form.Control placeholder='* Registration No' value={addpolicestation.registartionNo} name="registartionNo"
                                                    onChange={(e) => {
                                                        if (
                                                            e.target.value.match(/^[0-9]*(\.[0-9]*)?$/) &&
                                                            e.target.value.trim()
                                                        ) {
                                                            handleInput(e);
                                                        } else if (e.target.value.length === 0) {
                                                            handleInput(e);
                                                        }
                                                        addpolicestation.registartionNo.length >= 0
                                                            ? setRegistrationNoErr(false)
                                                            : setRegistrationNoErr(true);
                                                    }} />
                                                {registrationnoerr ? (<p className="errMsg">* Required Field</p>) : null}
                                            </Col>

                                            <Col md={4}>
                                                <Form.Label>Station Name</Form.Label>
                                                <Form.Control placeholder='* Station Name' value={addpolicestation.stationName} name="stationName"
                                                    onChange={(e) => {
                                                        if (
                                                            !e.target.value.match(/[#$&%!~=^_{}\[\]*|//\/?<>;`/\\]/) &&
                                                            e.target.value.trim()
                                                        ) {
                                                            handleInput(e);
                                                        } else if (e.target.value.length === 0) {
                                                            handleInput(e);
                                                        }
                                                        addpolicestation.stationName.length >= 0
                                                            ? setStationNameErr(false)
                                                            : setStationNameErr(true);
                                                    }} />
                                                {stationanmeerr ? (<p className="errMsg">* Required Field</p>) : null}
                                            </Col>

                                            <Col md={4}>
                                                <Form.Label>Station Type</Form.Label>
                                                <Select
                                                    isMulti={false}
                                                    isSearchable={true}
                                                    value={stationType}
                                                    options={stationTypeOptions}
                                                    onChange={(option) => {
                                                        setstationType(option);
                                                        stationType.length < 0
                                                            ? setStationTypeErr(true)
                                                            : setStationTypeErr(false);
                                                    }} />
                                                {stationtypeerr ? (<p className="errMsg">* Required Field</p>) : null}
                                            </Col>

                                            <Col md={6}>
                                                <Form.Label>Division</Form.Label>
                                                <Form.Control placeholder='* Division' value={addpolicestation.division} name="division"
                                                    onChange={(e) => {
                                                        if (
                                                            !e.target.value.match(/[#$&%!~=^_{}\[\]*|//\/?<>;`/\\]/) &&
                                                            e.target.value.trim()
                                                        ) { handleInput(e); }
                                                        else if (e.target.value.length === 0) {
                                                            handleInput(e);
                                                        }
                                                        addpolicestation.division.length >= 0 ?
                                                            setDivisionErr(false) :
                                                            setDivisionErr(true);
                                                    }} />
                                                {divisionerr ? (<p className="errMsg">* Required Field</p>) : null}
                                            </Col>

                                            <Col md={6}>
                                                <Form.Label>Zone</Form.Label>
                                                <Form.Control placeholder='* Zone' value={addpolicestation.zone} name="zone"
                                                    onChange={(e) => {
                                                        if (!e.target.value.match(/[#$&%!~=^_{}\[\]*|//\/?<>;`/\\]/) &&
                                                            e.target.value.trim()
                                                        ) {
                                                            handleInput(e);
                                                        } else if (e.target.value.length === 0) {
                                                            handleInput(e);
                                                        }
                                                        addpolicestation.zone.length >= 0 ?
                                                            setZoneErr(false) :
                                                            setZoneErr(true);
                                                    }} />
                                                {zoneerr ? (<p className="errMsg">* Required Field</p>) : null}
                                            </Col>

                                            <Col md={6}>
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control placeholder='Name@gmail.com' value={addpolicestation.email} name="email"
                                                    onChange={(e) => {
                                                        handleInput(e)
                                                        addpolicestation.email.length >= 0 ?
                                                            setEmailErr(false) :
                                                            setEmailErr(true);
                                                    }} />
                                                {emailerr ? (<p className="errMsg">* Required Field</p>) : null}
                                            </Col>

                                            <Col md={6}>
                                                <Form.Label>Telephone No</Form.Label>
                                                <Form.Control placeholder='+91' value={addpolicestation.telephoneNo} name="telephoneNo"
                                                    onChange={(e) => {
                                                        if (!e.target.value.match(/[A-Za-z+@#$&%!~=*./\s/g/\-/?<>,"/]/) &&
                                                            e.target.value.length <= 10
                                                        ) {
                                                            handleInput(e)
                                                        }
                                                        // addpolicestation.telephoneNo.length < 0 ?
                                                        //     setTelephoneErr(true) :
                                                        //     setTelephoneErr(false)
                                                        setTelephoneErr(e.target.value.length > 0 && e.target.value.length !== 10);
                                                    }} />
                                                {telephoneerr && addpolicestation.telephoneNo.length === 0 ? (
                                                    <p className='errMsg'>* Required Field</p>
                                                ) : telephoneerr && addpolicestation.telephoneNo.length !== 10 ? (
                                                    <p className='errMsg'>* Enter 10 digit mobile number</p>
                                                ) : null}
                                            </Col>

                                            <Col md={6}>
                                                <Form.Check inline label="CyberCrime Support"
                                                    value={iscybercrime} name="isCyberCrime" onChange={() => setIsCyberCrime(true)} />
                                            </Col>

                                            <Col md={12}>
                                                <Form.Label>Address</Form.Label>
                                                <Form.Control as="textarea" placeholder='Street Address'
                                                    value={addpolicestation.address} name="address"
                                                    onChange={(e) => {
                                                        if (!e.target.value.match(/[#$&%!~=^_{}\[\]*|//\/?<>;`/\\]/) &&
                                                            e.target.value.trim()
                                                        ) {
                                                            handleInput(e);
                                                        } else if (e.target.value.length === 0) {
                                                            handleInput(e);
                                                        }
                                                        addpolicestation.address.length >= 0
                                                            ? setAddressErr(false)
                                                            : setAddressErr(true);
                                                    }} />
                                                {addresserr ? (<p className="errMsg">* Required Field</p>) : null}
                                            </Col>

                                            <Col md={6}>
                                                <Form.Control placeholder='State' value={addpolicestation.state} name="state"
                                                    onChange={(e) => {
                                                        if (!e.target.value.match(/[0-9#$&%!~=^_{}\[\]*|//\/?<>;`/\\]/) &&
                                                            e.target.value.trim()
                                                        ) {
                                                            handleInput(e);
                                                        } else if (e.target.value.length === 0) {
                                                            handleInput(e);
                                                        }
                                                        addpolicestation.state.length >= 0
                                                            ? setStateErr(false)
                                                            : setStateErr(true);
                                                    }} />
                                                {stateerr ? (<p className="errMsg">* Required Field</p>) : null}
                                            </Col>

                                            <Col md={6}>
                                                <Form.Control placeholder='City' value={addpolicestation.city} name="city"
                                                    onChange={(e) => {
                                                        if (!e.target.value.match(/[0-9#$&%!~=^_{}\[\]*|//\/?<>;`/\\]/) &&
                                                            e.target.value.trim()
                                                        ) {
                                                            handleInput(e);
                                                        } else if (e.target.value.length === 0) {
                                                            handleInput(e);
                                                        }
                                                        addpolicestation.city.length >= 0
                                                            ? setCityErr(false)
                                                            : setCityErr(true);
                                                    }} />
                                                {cityerr ? (<p className="errMsg">* Required Field</p>) : null}
                                            </Col>
                                        </Row>
                                    </Form>

                                    <Row className='mt-5 mb-3'>
                                        <Col className='d-flex justify-content-center'>
                                            <Button className="submit-Btn px-5" onClick={() => handleAddPoliceStation()}>
                                                Submit
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Row>
                    </Modal.Body>
                </Modal>
            </div>
        </>

    )
}

export default AddNewPoliceStation
