import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import TableData from '../../Component/Common/Tables'
import { BsFillArrowRightCircleFill } from 'react-icons/bs';
import { BiArrowFromBottom } from 'react-icons/bi';
import Select from 'react-select'
import { Icon } from '@iconify/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import fileImage from "../../assets/docs 1.png"
import location from "../../assets/pin 1.png"
import { getPoliceStationList } from './PoliceSlice';
import Pagination from '../../Component/Common/Pagination';
import AddNewPoliceStation from './AddNewPoliceStation';

const PoliceStationList = () => {
  const navigation = useNavigate()

  // state for pagination
  const [docsPerPage, setDocsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [showAddModel, setShowAddModel] = useState(false)
  const [loading, setLoading] = useState(true);

  const handleAddPoliceModel = () => {
    setShowAddModel(true)
  }

  const closeAddPoliceModel = () => {
    setShowAddModel(false)
    getAllPoliceData();
  }

  // function for pagination
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setCurrentPage(1);
    setDocsPerPage(docsPageProp);
  };

  // usestate for search data
  const [selectedStation, setSelectedStation] = useState(null);
  const [stationlist, setStationList] = useState('')

  const handleronChange = (selectedValue) => {
    if (selectedValue === "All") {
      setSelectedStation(null);
    } else {
      setSelectedStation(selectedValue);
    }
  };
  const getAllPoliceData = async () => {
    const payload = {
      stationName: selectedStation?.value === "All" ? "" : selectedStation?.value || '', // Set to empty string if "All" is selected, otherwise use the selected value
      documentsPerPage: docsPerPage,
      page: currentPage

    }
    await fetch(process.env.REACT_APP_BASE_URL + "/policestation/getpolicestation", {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    })
      .then((res) => res.json()).then((response) => {

        setStationList(response)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        console.log("Error while getting leads =>", err);
      });
  };

  useEffect(() => {
    getAllPoliceData()
  }, [currentPage, docsPerPage, selectedStation])

  const PoliceStationData = stationlist && stationlist.data && Array.isArray(stationlist.data)
    ? stationlist.data.map((itm, index) => {
      const handleViewClick = () => {
        navigation("/admin/policeStationDetails", { state: { data: itm } });
      };

      return {
        CaseNumber: currentPage !== 1 ? (<> {index + 1 + docsPerPage * (currentPage - 1)}</>) : <>{index + 1}</>,
        division: itm.division,
        stationName: itm.stationName,
        emailId: itm.emailId,
        telephoneNo: itm.telephoneNo,
        address: itm.address,
        action: (
          <>
            <>
              <img
                src={fileImage}
                onClick={handleViewClick}
                title='View'
              />{" "}
            </>
          </>
        ),
      };
    })
    : [];




  const columns = [
    { header: 'Sr.No', field: 'CaseNumber' },
    { header: 'Division', field: 'division' },
    { header: 'Station', field: 'stationName' },
    { header: 'Email Id', field: 'emailId' },
    { header: 'Telephone No', field: 'telephoneNo' },
    { header: 'Address', field: 'address' },
    { header: 'Details', field: 'action' },
  ];

  const options = [...new Set(PoliceStationData?.map((station) => station.stationName))]
    .map((stationName) => ({
      value: stationName,
      label: stationName,
    }));


  return (
    <div className="pageLayout" style={{ marginLeft: "6px" }}>
      <AddNewPoliceStation show={showAddModel} close={closeAddPoliceModel} />
      <div className='m-4'>
        <Row className='d-flex flex-column-reverse flex-md-row justify-content-between align-items-center  mt-5'>
          <Col md={4} className='mt-4 md-mb-0 pb-2'>
            <Form.Group>
              <Select placeholder="Search station" options={[{ label: "All", value: "All" }, ...options]} onChange={handleronChange}
                value={selectedStation}
              />
            </Form.Group>
          </Col>

          <Col md={6} className='d-flex justify-content-end mt-2 md-mt-0'>
            <Button className='primaryBtn' onClick={() => handleAddPoliceModel()}>
              <Icon style={{ fontSize: "18px", marginRight: "8px" }} icon="gg:add" />
              Police Station
            </Button>
          </Col>
        </Row>
      </div>

      {
        loading ? (
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div className='outer-wrapper mx-auto'>
            <div className='table-wrapper'>
              <TableData data={PoliceStationData} columns={columns} />
            </div>
          </div>
        )
      }

      <Pagination
        currentPage={currentPage}
        totalPages={stationlist?.totalPages}
        onPageChange={handlePageChange}
        onDocsPerPage={handleDocsPerPage}
        docsPerPage={docsPerPage}
      />
    </div>)
}

export default PoliceStationList