// for dot pagination
import React from "react";
import { Container, Button, Form } from "react-bootstrap";
import * as Icon from 'react-bootstrap-icons';
import { useSelector } from "react-redux";

function Pagination({ currentPage, totalPages, onPageChange, onDocsPerPage, docsPerPage }) {

  // Logic for rendering pagination buttons
  const pageNumbers = []; 

  // Generate page numbers based on the total number of pages
  const maxPageButtons = 4;

  // Calculate the starting and ending page numbers for pagination
  let startPage = Math.max(1, currentPage - Math.floor(maxPageButtons / 2));
  let endPage = Math.min(totalPages, startPage + maxPageButtons - 1);

  // Adjust the starting and ending page numbers if there are not enough pages to show
  if (endPage - startPage + 1 < maxPageButtons) {
    if (startPage === 1) {
      endPage = Math.min(totalPages, maxPageButtons);
    } else {
      startPage = Math.max(1, endPage - maxPageButtons + 1);
    }
  }

  // Add the first page number
  if (startPage > 1) {
    pageNumbers.push(1);
  }

  // Add "..." dot if there are pages before the first page shown
  if (startPage > 2) {
    pageNumbers.push("...");
  }

  // Add individual page numbers
  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  // Add "..." dot if there are pages after the last page shown
  if (endPage < totalPages - 1) {
    pageNumbers.push("...");
  }

  // Add the last page number
  if (endPage < totalPages) {
    pageNumbers.push(totalPages);
  }

  return (
    <div className="pagination my-3">
      <Container className="d-flex justify-content-end w-100">
        <div className="d-flex flex-column flex-md-row">
          <div className='d-flex mb-4 mb-md-0' style={{ width: 'maxContent' }}>
            {/* Render previous page button */}
            <Button className='prev' disabled={currentPage === 1} onClick={() => onPageChange(currentPage - 1)}>
              <Icon.ChevronLeft className='me-1' />Prev
            </Button>

            {/* Render individual page numbers */}
            {pageNumbers.map((pageNumber, index) => (
              <React.Fragment key={index}>
                {pageNumber === "..." ? (
                  <span className="dotPagination">{pageNumber}</span>
                ) : (
                  <Button
                    className={pageNumber === currentPage ? 'activePageButton' : 'pageButton'}
                    onClick={() => onPageChange(pageNumber)} >
                    {pageNumber}
                  </Button>
                )}
              </React.Fragment>
            ))}

            {/* Render next page button */}
            <Button className='next' disabled={currentPage === totalPages} onClick={() => onPageChange(currentPage + 1)} >
              Next<Icon.ChevronRight className="ms-1" />
            </Button>
          </div>

          <div>
            <Form.Select value={docsPerPage} onChange={(e) => onDocsPerPage(e.target.value)}>
               <>
                  <option value='10'>10/Page</option>
                  <option value='25'>25/Page</option>
                  <option value='50'>50/Page</option>
                  <option value='100'>100/Page</option>
                  <option value='500'>500/Page</option>
                  <option value='600'>600/Page</option>
                </>
            </Form.Select>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Pagination;


















// import { Container, Row, Col, Button, Form } from "react-bootstrap";
// import * as Icon from 'react-bootstrap-icons';
// import { useSelector } from "react-redux";

// function Pagination({ currentPage, totalPages, onPageChange, onDocsPerPage, docsPerPage }) {
// const loginUserData = useSelector((state) => state.loginData.userData);

//   // Logic for rendering pagination buttons
//   const pageNumbers = []; // Array to store individual page numbers

//   // Generate page numbers based on the total number of pages

//   for (let i = 1; i <= totalPages; i++) {
//     pageNumbers.push(i);
//   }

//   return (
//     <div className="pagination my-3">
//       <Container className="d-flex justify-content-end w-100">
//         <div className="d-flex flex-column flex-md-row" >
//           <div className='d-flex mb-4 mb-md-0' style={{ width: 'maxContent' }}>

//             {/* Render previous page button */}
//             <Button className='prev' disabled={currentPage === 1} onClick={() => onPageChange(currentPage - 1)}>
//               <Icon.ChevronLeft className='me-1' />Prev
//             </Button>

//             {/* Render individual page numbers */}
//             {pageNumbers.map((pageNumber) => (
//               <Button
//                 key={pageNumber}
//                 className={pageNumber === currentPage ? 'activePageButton' : 'pageButton'}
//                 onClick={() => onPageChange(pageNumber)} >
//                 {pageNumber}
//               </Button>
//             ))}


//             {/* Render next page button */}
//             <Button className='next' disabled={currentPage === totalPages} onClick={() => onPageChange(currentPage + 1)} >
//               Next<Icon.ChevronRight className="ms-1" />
//             </Button>
//           </div>

//           <div>
            
//             <Form.Select value={docsPerPage} onChange={(e) => onDocsPerPage(e.target.value)}>
//               {loginUserData.resourceType === "employee" ? 
//               <>
//               <option value='10'>10/Page</option>
//               <option value='30'>30/Page</option>
//               <option value='60'>60/Page</option>
//               <option value='100'>100/Page</option>
//               </>
//                :
//                <>
//                <option value='10'>10/Page</option>
//               <option value='50'>50/Page</option>
//               <option value='100'>100/Page</option>
//               <option value='500'>500/Page</option>
//               <option value='600'>600/Page</option>
//                </>
//             }
//             </Form.Select>
//           </div>
//         </div>
//       </Container>
//     </div>

//   );
// }

// export default Pagination;



















// // for dot pagination
// import React, { useState } from "react";
// import { Container, Button, Form } from "react-bootstrap";
// import * as Icon from 'react-bootstrap-icons';
// import { useSelector } from "react-redux";

// function Pagination({ totalPages,currentPage }) {
// //   const loginUserData = useSelector((state) => state.loginData.userData);

// const [currentPage, setCurrentPage] = useState(2);
// const [docsPerPage, setDocsPerPage] = useState(10);

// const handlePageChange = (pageNumber) => {
//   setCurrentPage(pageNumber);
// };

// const handleDocsPerPage = (event) => {
//   const newDocsPerPage = event.target.value;
//   setDocsPerPage(newDocsPerPage);
//   setCurrentPage(1); // Reset to the first page when changing docsPerPage
// };


//   // Logic for rendering pagination buttons
//   const pageNumbers = []; // Array to store individual page numbers

//   // Generate page numbers based on the total number of pages
//   const maxPageButtons = 4; // You can change this value to set the maximum number of page buttons shown

//   // Calculate the starting and ending page numbers for pagination
//   let startPage = Math.max(1, currentPage - Math.floor(maxPageButtons / 2));
//   let endPage = Math.min(totalPages, startPage + maxPageButtons - 1);

//   // Adjust the starting and ending page numbers if there are not enough pages to show
//   if (endPage - startPage + 1 < maxPageButtons) {
//     if (startPage === 1) {
//       endPage = Math.min(totalPages, maxPageButtons);
//     } else {
//       startPage = Math.max(1, endPage - maxPageButtons + 1);
//     }
//   }

//   // Add the first page number
//   if (startPage > 1) {
//     pageNumbers.push(1);
//   }

//   // Add "..." dot if there are pages before the first page shown
//   if (startPage > 2) {
//     pageNumbers.push("...");
//   }

//   // Add individual page numbers
//   for (let i = startPage; i <= endPage; i++) {
//     pageNumbers.push(i);
//   }

//   // Add "..." dot if there are pages after the last page shown
//   if (endPage < totalPages - 1) {
//     pageNumbers.push("...");
//   }

//   // Add the last page number
//   if (endPage < totalPages) {
//     pageNumbers.push(totalPages);
//   }

//   return (
//     <div className="pagination my-3">
//       <Container className="d-flex justify-content-end w-100">
//         <div className="d-flex flex-column flex-md-row">
//           <div className='d-flex mb-4 mb-md-0' style={{ width: 'maxContent' }}>

//             {/* Render previous page button */}
//             <Button className='prev' disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)}>
//               <Icon.ChevronLeft className='me-1' />Prev
//             </Button>

//             {/* Render individual page numbers */}
//             {pageNumbers.map((pageNumber, index) => (
//               <React.Fragment key={index}>
//                 {pageNumber === "..." ? (
//                   <span className="dotPagination">{pageNumber}</span>
//                 ) : (
//                   <Button
//                     className={pageNumber === currentPage ? 'activePageButton' : 'pageButton'}
//                     onClick={() => handlePageChange(pageNumber)} >
//                     {pageNumber}
//                   </Button>
//                 )}
//               </React.Fragment>
//             ))}

//             {/* Render next page button */}
//             <Button className='next' disabled={currentPage === totalPages} onClick={() => handlePageChange(currentPage + 1)} >
//               Next<Icon.ChevronRight className="ms-1" />
//             </Button>
//           </div>

//           <div>

//             <Form.Select value={docsPerPage} onChange={(e) => handleDocsPerPage(e.target.value)}>
//               {/* {loginUserData.resourceType === "employee" ? */}
//                 <>
//                   <option value='10'>10/Page</option>
//                   <option value='30'>30/Page</option>
//                   <option value='60'>60/Page</option>
//                   <option value='100'>100/Page</option>
//                 </>
//                 {/* : */}
//                 {/* <>
//                   <option value='10'>10/Page</option>
//                   <option value='50'>50/Page</option>
//                   <option value='100'>100/Page</option>
//                   <option value='500'>500/Page</option>
//                   <option value='600'>600/Page</option>
//                 </> */}
//            {/* } */}
//             </Form.Select>
//           </div>
//         </div>
//       </Container>
//     </div>
//   );
// }

// export default Pagination;







