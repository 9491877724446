// import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SideBar from "./Component/Sidebar/SideBar";
import LoginComp from "./Component/Auth/LoginComp";
import { useSelector } from "react-redux";
import VolunteerDetails from "./pages/Volunteer/VolunteerDetails";
import VolunteerList from "./pages/Volunteer/VolunteerList";
import CybercrimeList from "./pages/Cybercrime/CybercrimeList";
import IncidentLIst from "./pages/Incident/IncidentLIst";
import TrackmeList from "./pages/Trackme/TrackmeList";
import SosList from "./pages/Sos/SosList";
import PoliceStationList from "./pages/PoliceStation/PoliceStationList";
import PoliceStationDetails from "./pages/PoliceStation/PoliceStationDetails";
import PoliceList from "./pages/PoliceStation/PoliceList";
import PoliceStationPoliceLIst from "./pages/PoliceStation/PoliceStationPoliceLIst";
import TrackMe from "./pages/Trackme/TrackMe";
import AddNewPoliceStation from "./pages/PoliceStation/AddNewPoliceStation";
import AddNewPoliceUser from "./pages/PoliceStation/AddNewPoliceUser";
import SosRaise from "./pages/Sos/SosRaise";
import { AssignTable } from "./pages/Sos/Assign/AssignTable";
import { PoliceListTable } from "./pages/Sos/Assign/PoliceListTable";
import { CommonLayout } from "./Layouts/CommonLayout";
import Dashboard from "./pages/Dashboard";
import { UserList } from "./pages/User/UserList";
import { CheckInOut } from "./pages/CheckInOut/CheckInOut";
import { PoliceUserSingleData } from "./pages/PoliceStation/PoliceUserSingleData";
import { AssignPolice } from "./pages/Sos/Assign/AssignPolice";
import { AssignVolenteer } from "./pages/Sos/Assign/AssignVolenteer";
import AssignCybercrimePolice from "./pages/Cybercrime/AssignCybercrimePolice";
import { AssignCybercrimeVolunteer } from "./pages/Cybercrime/AssignCybercrimeVolunteer";
import IncidentCategory from "./pages/Incident/IncidentCategory";
import CyberCrimeCategory from "./pages/Cybercrime/CyberCrimeCategory";
import AnalyticalDashborad from "./Component/Common/AnalyticalDashborad";

function App() {
  const Auth = useSelector(state=>state.loginData.userData) 

  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginComp/>} />
        <Route path="*" element={<LoginComp />} />
        {
          <Route path="/admin" element={<CommonLayout/>}>
            <Route path="/admin/dashboard" element={<Dashboard />} />
            <Route path="/admin/ckeckIn-Out" element={<CheckInOut />}/>
            <Route path="/admin/messages" element={<VolunteerList />} />
            <Route path="/admin/volunteer" element={<VolunteerList />} />
            <Route path ="/admin/trackeme" element = {<TrackMe/>} />
            <Route path="/admin/addpolicestation" element = {<AddNewPoliceStation/>} />
            <Route path ="/admin/addpoliceuser" element = {<AddNewPoliceUser/>} />
            <Route path="/admin/volunteerDetails" element={<VolunteerDetails/>} />
            <Route path="/admin/CyberCrime" element={<CybercrimeList/>} />
            <Route path="/admin/cybercrime/AssignPolice" element={<AssignCybercrimePolice/>}/>
            <Route path="/admin/cybercrime/AssignVolunteer" element={<AssignCybercrimeVolunteer/>}/>
            <Route path="/admin/incident" element={<IncidentLIst/>} />
            <Route path="/admin/incidentCategory" element={<IncidentCategory/>} />
            <Route path="/admin/CyberCrimeCategory" element={<CyberCrimeCategory/>} />
            <Route path="/admin/sosList" element={<SosList/>} />
            <Route path="/admin/sosRaise" element={<SosRaise/>}/>
            <Route path="/admin/assignPolice" element={<AssignPolice/>}/>
            <Route path="/admin/assignVolenteer" element={<AssignVolenteer/>}/>
            <Route path="/admin/policeListTable" element={<PoliceListTable/>}/>
            <Route path="/admin/trackmeList" element={<TrackmeList/>} />
            <Route path="/admin/policeStationList" element={<PoliceStationList/>} />
            <Route path="/admin/policeStationDetails" element={<PoliceStationDetails/>} />
            <Route path="/admin/UserList" element={<UserList/>}/>
            <Route path="/admin/policeStation/policeList" element={<PoliceStationPoliceLIst/>} />
            <Route path="/admin/policeUseSingleData" element={<PoliceUserSingleData/>}/>
            <Route path="/admin/analytical-dashboard" element={<AnalyticalDashborad/>}/>
          </Route>
        }
        <Route path="*" element={<> not found</>} />
      </Routes>
    </Router>
  );
}

export default App;
