import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    activeNumberKey: '',
    activePage:'',
    activeToggle:'verified',
};

const sidebarSlice = createSlice({
  name: 'activeKey',
  initialState,
  reducers:{
    setActiveKey(state, action){
      state.activeNumberKey = action.payload;
    },
    setActivePage(state,action){
      state.activePage = action.payload
    },
    setActiveToggle(state, action){
          state.activeToggle = action.payload
        },
  },
});

export const {setActiveKey,setActivePage,setActiveToggle} = sidebarSlice.actions;
export default sidebarSlice.reducer;