import { NavLink, Navigate, Outlet, Route, useNavigate } from "react-router-dom";
import "./sidebar.css"
import { FaBars, FaHome, FaLock, FaMoneyBill, FaUser } from "react-icons/fa";
import { MdMessage } from "react-icons/md";
import { BiAnalyse, BiSearch } from "react-icons/bi";
import { BiCog } from "react-icons/bi";
// import { AiFillHeart, AiTwotoneFileExclamation } from "react-icons/ai";
import { BsCartCheck } from "react-icons/bs";
import { AiFillBell } from "react-icons/ai";

import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from "./SidebarMenu";
import { IoIosArrowRoundBack, IoIosArrowRoundForward, } from "react-icons/io";
import { BsFillArrowLeftCircleFill, BsFillArrowRightCircleFill } from "react-icons/bs";
import sosIcon from "../../assets/sosIcon.png"
import DashboardIcon from "../../assets/DashboardIcon.png"
import Trackme from "../../assets/tracmeIcon.png"
import AddIcon from "../../assets/addicon.png"
import PoliceIcon from "../../assets/policeIcon.png"
import spsIcon from "../../assets/spsIcon.png"
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setLogout } from "../Auth/loginSlice";
import { setActiveKey, setActivePage } from "./sidebarSlice";
import { IoEllipseOutline } from "react-icons/ai";
import { BiRadioCircle } from "react-icons/bi";
const routes = [

  {
    path: "/admin/dashboard",
    name: "Dashboard",
    icon: <img
      src={DashboardIcon}
      alt="Description of the image"
      style={{ width: 'auto', height: 'auto' }}
    />,
  },
  {
    path: "/admin/sosRaise",
    name: "SOS Raised",
    icon: <img
      src={sosIcon}
      alt="Description of the image"
      style={{ width: 'auto', height: 'auto' }}
    />,
  },
  {
    path: "/admin/trackeme",
    name: "Trackme",
    icon: <img
      src={Trackme}
      alt="Description of the image"
      style={{ width: 'auto', height: 'auto' }}
    />,
  },
  {
    path: "/admin/Volunteer",
    name: "Volunteer",
    icon: <img
      src={sosIcon}
      alt="Description of the image"
      style={{ width: 'auto', height: 'auto' }}
    />,
  },
  {
    path: "",
    name: "Police",
    icon: <img
      src={PoliceIcon}
      alt="Description of the image"
      style={{ width: 'auto', height: 'auto'}}
    />, subRoutes: [

      {
        path: "/admin/policeStationList",
        name: "Police Station",
        icon: <img src={AddIcon} alt="Description of the image" style={{ width: 'auto', height: 'auto' }} />,
      },
      {
        path: "/admin/addpolicestation",
        name: "Add Police Station",
        icon: <img
          src={AddIcon}
          alt="Description of the image"
          style={{ width: 'auto', height: 'auto' }}
        />,
      },
      {
        path: "/admin/addpoliceuser",
        name: "Add Police User",
        icon: <img
          src={AddIcon}
          alt="Description of the image"
          style={{ width: 'auto', height: 'auto' }}
        />,
      },
    ],
  },
  // {
  //   path: "/order",
  //   name: "Order",
  //   icon: <BsCartCheck />,
  // },
  // {
  //   path: "/settings",
  //   name: "Settings",
  //   icon: <BiCog />,
  //   exact: true,
  //   subRoutes: [
  //     {
  //       path: "/settings/profile",
  //       name: "Add Police Station",
  //       icon: <img
  //       src={AddIcon}
  //       alt="Description of the image"
  //       style={{ width: 'auto', height: 'auto' }}
  //     />,      },
  //     {
  //       path: "/settings/2fa",
  //       name: "Add Police User",
  //       icon: <img
  //       src={AddIcon}
  //       alt="Description of the image"
  //       style={{ width: 'auto', height: 'auto' }}
  //     />,      },
  //     {
  //       path: "/settings/billing",
  //       name: "Billing",
  //       icon: <img
  //       src={AddIcon}
  //       alt="Description of the image"
  //       style={{ width: 'auto', height: 'auto' }}
  //     />,      },
  //   ],
  // },

];

const Lists = [
  {
    path: "/admin/incident",
    name: "Incident",
    icon: <BiRadioCircle />,
  },
  {
    path: "/admin/CyberCrime",
    name: "Cyber Crime",
    icon: <BiRadioCircle />,

  },

  {
    path: "/admin/trackmeList",
    name: "Track Me",
    icon: <BiRadioCircle />,

  },

  {
    path: "/admin/sosList",
    name: "SOS",
    icon: <BiRadioCircle />,

  },
]

const SideBar = () => {
    const [isOpen, setIsOpen] = useState(true);
    const toggle = () => setIsOpen(!isOpen);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const details = useSelector(state => state.SideBarData.activePage)
    const inputAnimation = {
      hidden: {
        width: 0,
        padding: 0,
        transition: {
          duration: 0.2,
        },
      },
      show: {
        width: "140px",
        padding: "5px 15px",
        transition: {
          duration: 0.2,
        },
      },
  };

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };
  const handleLogout = () => {
    dispatch(setLogout(null));
    navigate('/');
    localStorage.removeItem('persist:root')
  }

  const handleRouteClick = (routePath, name) => {
    dispatch(setActiveKey(routePath));
    dispatch(setActivePage(name))
  };


  return (
    <>
      <div style={{ display: "flex", position: "absolute", width: "100%", height: "100%" }}>
        <div>
          <motion.div
            animate={{
              width: isOpen ? "180px" : "90px",

              transition: {
                duration: 0.5,
                type: "spring",
                damping: 10,
              },
            }}
            className={`sidebar `}
          >

            <div style={{ display: "flex", alignItems: 'center', borderBottom: "8px solid #DDDDFF" }}>

              <span>
                <img src={spsIcon} style={{ width: "50px", height: "50px", padding: "8px" }} />
              </span>
              <span style={{ padding: '10px', fontSize: "14px" }}>{isOpen ? <p>Safety  Positioning System</p> : ""}</span>

            </div>
            <div className="top_section" style={{ position: "relative", top: "-20px", left: "20px", boarder: "2px solid", borderRadius: "25%", height: "0" }}>
              <AnimatePresence>
                {isOpen && (
                  <motion.h1
                    variants={showAnimation}
                    initial="hidden"
                    animate="show"
                    exit="hidden"
                    className="logo"
                  >
                    {/* DoSomeCodingyp0 */}
                  </motion.h1>
                )}
              </AnimatePresence>


              <div className="bars" >
                {/* <FaBars onClick={toggle} /> */}
                {

                  !isOpen ? <BsFillArrowRightCircleFill onClick={toggle} /> : <BsFillArrowLeftCircleFill onClick={toggle} />
                }
                {/* <IoIosArrowRoundBack  onClick={toggle} /> */}
              </div>
            </div>

            {/* <div className="search">
            <div className="search_icon">
              <BiSearch />
            </div>
            <AnimatePresence>
              {isOpen && (
                <motion.input
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  variants={inputAnimation}
                  type="text"
                  placeholder="Search"
                />
              )}
            </AnimatePresence>
          </div> */}
            <section className="routes">
              <span style={{color: "#183F91"}}>Menu</span>
              {routes.map((route, index) => {
                if (route.subRoutes) {
                  return (
                    <SidebarMenu
                      setIsOpen={setIsOpen}
                      route={route}
                      showAnimation={showAnimation}
                      isOpen={isOpen}
                    />
                  );
                }

                return (
                  <NavLink
                    to={route.path}
                    key={index}
                    className="link"
                    activeclassName="active"
                    onClick={() => handleRouteClick(route.path, route.name)}

                  >
                    <AnimatePresence>
                      <div className="icon">{route.icon}</div>
                      {isOpen && (
                        <motion.div
                          variants={showAnimation}
                          initial="hidden"
                          animate="show"
                          exit="hidden"
                          className="link_text"
                        >
                          {route.name}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </NavLink>
                );
              })}
            </section>
            <section className="routes">
              <span style={{marginTop:"15px", color: "#183F91"}}>Report Lists</span>
              {Lists.map((route, index) => {
                if (route.subRoutes) {
                  return (
                    <SidebarMenu
                      setIsOpen={setIsOpen}
                      route={route}
                      showAnimation={showAnimation}
                      isOpen={isOpen}
                    />
                  );
                }

                return (
                  <NavLink
                    to={route.path}
                    key={index}
                    className="link"
                    activeClassName="active"
                    onClick={() => handleRouteClick(route.path, route.name)}

                  >
                    <AnimatePresence>
                      <div className="icon">{route.icon}</div>
                      {isOpen && (
                        <motion.div
                          variants={showAnimation}
                          initial="hidden"
                          animate="show"
                          exit="hidden"
                          className="link_text"
                        >
                          {route.name}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </NavLink>
                );
              })}
            </section>
            <div><Button onClick={handleLogout}>Logout</Button></div>
          </motion.div>
        </div>
        <div style={{ flex: 1, overflowY: "auto" }}>
          <Row md={12} sm={12} style={{ position: "fixed", height: "50px", display: "flex", justifyContent: "space-between", alignItems: "center", top: 0, left: isOpen ? "250px" : "45px", width: isOpen ? "calc(100% - 250px)" : "calc(100% - 45px)", background: "#fff", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", zIndex: 1000 }}>
            <Col md={9} sm={9} style={{ display: "flex", justifyItems: "center" }}>{details}</Col>
            <Col md={3} sm={3} style={{ display: "flex", justifyContent: "space-between" }}>
              <div><AiFillBell /></div>
              <div>hey</div>
            </Col>
          </Row>
          <div style={{ paddingTop: "20px", paddingBottom: '20px' }}>
            <Outlet />
          </div>
        </div>



      </div>
    </>
  );
};

export default SideBar;
