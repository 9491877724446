import React, { useState } from 'react'
import { Accordion, Button, OverlayTrigger, Popover, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { setActiveKey } from '../Sidebar/sidebarSlice';
import spsIcon from "../../assets/spsIcon.png";
import { Icon } from '@iconify/react';
import { setLogout } from '../Auth/loginSlice';
import PoliceMan from "../../assets/policeImg.png"

export const MenuBar = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loader, setLoader] = useState(false);

  const activeKeyRedux = useSelector(state => state.SideBarData.activeNumberKey)
  const userState = useSelector(state => state.loginData.userData);

  const handleAccordionChange = (activeKey, subActiveKey, tabName) => {
    dispatch(setActiveKey({ activeKey: activeKey, subActiveKey: subActiveKey, activeTab: tabName }));
  };

  const handleLogout = () => {
    dispatch(setLogout(null));
    navigate('/');
    localStorage.removeItem('persist:root')
  }

  function setnav() {
    const mobilenav = document.querySelector(".mobile-nav");
    const toggle = document.querySelector(".mobile-nav-toggle");

    const visibility = mobilenav.getAttribute('data-visible');
    if (visibility === "false") {
      mobilenav.setAttribute("data-visible", true);
      toggle.setAttribute("aria-expanded", true);
      document.querySelector("body").style.overflow = 'hidden';
    }
    else if (visibility === "true") {
      mobilenav.setAttribute("data-visible", false);
      toggle.setAttribute("aria-expanded", false);
      document.querySelector("body").style.overflow = 'auto';
    }
  }

  const Popup = (
    <Popover>
      <Popover.Body>
        <div className='PopupData'>
          <div>
            <Icon icon="material-symbols:person-outline" className='me-2' width={20} height={20} /><p><strong>Name - </strong>{userState?.user?.first_name + " " + userState?.user?.last_name}</p>
          </div>

          <div>
            <Icon icon="ic:outline-email" className='me-2' width={20} height={20} /><p><strong>Email - </strong>{userState?.user?.email}</p>
          </div>
          <div>
            <Icon icon="mdi:gender-female" className='me-2' width={20} height={20} /><p><strong>Gender - </strong>{userState?.user?.gender}</p>
          </div>
          <div>
            <Icon icon="ph:phone" className='me-2' width={20} height={20} /><p><strong>Phone No - </strong>{userState?.user?.mobile}</p>
          </div>
        </div>
      </Popover.Body>
    </Popover>
  )

  const entering = (e) => {
    e.children[0].style.borderTopColor = 'white';
    e.children[1].style.backgroundColor = 'white';
    e.children[0].style.borderRadius = '12px';
    e.children[1].style.borderRadius = '12px';
  };

  return (
    <nav>
      <Link className='d-flex align-items-center text-decoration-none'>
        <h5 className='mb-0 ms-2'>{activeKeyRedux.activeTab}</h5>
      </Link>

      <div className='d-flex align-items-center'>
        <div className="mobile-nav-toggle" aria-controls='selectNav' aria-expanded="false" onClick={setnav}>
          <div className='menu-btn__burger'></div>
        </div>
      </div>

      <ul className="lg-nav">
        <OverlayTrigger key='bottom' placement='bottom' overlay={Popup} onEntering={entering}>
          <div className='d-flex' style={{ border: 'none', cursor: 'pointer' }}>
            <Icon icon="iconamoon:profile-fill" style={{ width: '30px', height: '35px' }} />
            <div className='ms-2'>
              <p style={{ fontWeight: '600', fontSize: '15px', marginBottom: '-4px' }}>{userState?.user?.first_name + " " + userState?.user?.last_name}</p>
              <p style={{ fontSize: '12px', marginBottom: '0px', textTransform: 'capitalize' }}>{userState?.user?.user_role[2]}</p>
            </div>
          </div>
        </OverlayTrigger>
      </ul>

      <ul id="selectNav" className="mobile-nav" data-visible="false">
        <div className="text-center">
          <img src={spsIcon} alt="SPS" width={50} className="mb-2" />
          <p className='text-dark'>Safety  Positioning System</p>
          <hr></hr>
        </div>

        <div className="d-flex justify-content-between flex-column h-100" >
          {
            <Accordion flush activeKey={activeKeyRedux?.activeKey}>
              <span style={{ marginLeft: "12px", fontSize: "14px", color: "#183F91", fontWeight: "600" }}>Menu</span>
              <Accordion.Item eventKey={1} onClick={() => {
                handleAccordionChange(1, 1, "Dashboard");
                navigate("Dashboard"); setnav();
              }} className={activeKeyRedux?.activeKey === 1 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 1 ?
                  <Icon icon="material-symbols:dashboard" className="me-3" fontSize={22} /> :
                  <Icon icon="material-symbols:dashboard" className="me-3" fontSize={22} />}Dashboard</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={2} onClick={() => { handleAccordionChange(2, 2, "SOS Raised"); navigate("sosRaise"); setnav(); }} className={activeKeyRedux?.activeKey === 2 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 2 ?
                  <Icon icon="ant-design:alert-filled" className="me-3" fontSize={22} /> :
                  <Icon icon="ant-design:alert-filled" className="me-3" fontSize={22} />}SOS Raised</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={3} onClick={() => { handleAccordionChange(3, 3, "Trackme"); navigate("trackeme"); setnav(); }} className={activeKeyRedux?.activeKey === 3 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 3 ?
                  <Icon icon="ic:outline-my-location" className="me-3" fontSize={22} /> :
                  <Icon icon="ic:outline-my-location" className="me-3" fontSize={22} />}Trackme</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={4} onClick={() => { handleAccordionChange(4, 4, "Volunteer"); navigate("Volunteer"); setnav(); }} className={activeKeyRedux?.activeKey === 4 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 4 ?
                  <Icon icon="maki:police" className="me-3" fontSize={22} /> :
                  <Icon icon="maki:police" className="me-3" fontSize={22} />}Volunteer</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={5} onClick={() => {
                handleAccordionChange(5, 5, "Police Station");
                navigate("policeStationList");
              }} className={activeKeyRedux?.activeKey === 5 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 5 ?
                  <Icon icon="mdi:police-badge" className="me-3" fontSize={22} /> :
                  <Icon icon="mdi:police-badge" className="me-3" fontSize={22} />}Police
                </Accordion.Header>
              </Accordion.Item>

              <span style={{ fontSize: "14px", color: "#183F91", margin: "7px 0px 7px 13px", fontWeight: "600" }}>Report List</span>
              <Accordion.Item eventKey={6} onClick={() => {
                handleAccordionChange(6, 6, "Incident");
                navigate("incident"); setnav();
              }} className={activeKeyRedux?.activeKey === 6 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 6 ?
                  <Icon icon="tabler:point-filled" className="me-3" fontSize={20} /> :
                  <Icon icon="tabler:point-filled" className="me-3" fontSize={20} />}Incident</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={7} onClick={() => {
                handleAccordionChange(7, 7, "Cyber Crime");
                navigate("CyberCrime"); setnav();
              }} className={activeKeyRedux?.activeKey === 7 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 7 ?
                  <Icon icon="tabler:point-filled" className="me-3" fontSize={20} /> :
                  <Icon icon="tabler:point-filled" className="me-3" fontSize={20} />}Cyber Crime</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={8} onClick={() => { handleAccordionChange(8, 8, "Track Me"); navigate("trackmeList"); setnav(); }} className={activeKeyRedux?.activeKey === 8 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 8 ?
                  <Icon icon="tabler:point-filled" className="me-3" fontSize={20} /> :
                  <Icon icon="tabler:point-filled" className="me-3" fontSize={20} />}Track Me</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={9} onClick={() => { handleAccordionChange(9, 9, "SOS"); navigate("sosList"); setnav(); }} className={activeKeyRedux?.activeKey === 9 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 9 ?
                  <Icon icon="tabler:point-filled" className="me-3" fontSize={20} /> :
                  <Icon icon="tabler:point-filled" className="me-3" fontSize={20} />}SOS</Accordion.Header>
              </Accordion.Item>

            </Accordion>
          }
          <div className='px-2'>
            <Button className='LogoutBtn w-100' onClick={handleLogout}>{loader ?
              <Spinner animation="border" variant="light" className='spinner' /> :
              <><Icon icon="ant-design:logout-outlined" className="me-2" />Logout</>}</Button>
          </div>
        </div>
      </ul>
    </nav>
  )
}
