import axios from 'axios';
import React, { useEffect, useState } from 'react'
import TableData from '../../Component/Common/Tables';
import Pagination from '../../Component/Common/Pagination';
import moment from 'moment/moment';

export const UserList = () => {
  const [userList, setUserList] = useState([])
  const [loading, setLoading] = useState(false)

  // state for pagination
  const [docsPerPage, setDocsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getData()
  }, [currentPage, docsPerPage])

  const getData = async () => {
    setLoading(true)
    try {
      // const response = await axios.post(process.env.REACT_APP_BASE_URL + `/user/viewrolewiseleuser`, { user_role: 'user',documentsPerPage:docsPerPage,page:currentPage });
      const response = await axios.post(process.env.REACT_APP_BASE_URL + `/user/viewrolewiseleuser?user_role=user&documentsPerPage=${docsPerPage}&page=${currentPage}`)
      setLoading(false)
      if (response.status === 200) {
        setUserList(response?.data)
      }
      else{
        setUserList([])
      }
      // if (data.data === "No data found") {
      //   setUserList([]);
      // } else {
      //   setUserList(data);
      // }
    } catch (error) {
      console.error("Error fetching user data:", error);
      setLoading(false)
    }
  };

  // function for pagination
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setCurrentPage(1);
    setDocsPerPage(docsPageProp);
  };


  const userData = userList.data !== undefined && userList !== null && userList?.data.length > 0 ?
    userList.data.map((itm, index) => {
      return {
        SrNo: currentPage !== 1 ? (<> {index + 1 + docsPerPage * (currentPage - 1)}</>) : <>{index + 1}</>,
        name: itm?.first_name + " " + itm?.last_name,
        mobile: itm?.mobile,
        email: itm?.email,
        date: moment(itm.createdAt).format(" DD-MMM-YYYY hh:mm a "),
      };
    })
    : [];

  console.log(userData)


  const columns = [
    { header: 'Sr No', field: 'SrNo' },
    { header: 'Name', field: 'name' },
    { header: 'Mobile', field: 'mobile' },
    { header: 'Email', field: 'email' },
    { header: 'Registration Date', field: 'date' }
  ];

  return (
    <div className="pageLayout">
      {loading ? <div className="text-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div> : <>
        { userData.length > 0 ? <>
        <TableData data={userData} columns={columns} />
        <Pagination
          currentPage={currentPage}
          totalPages={userList?.totalPages}
          onPageChange={handlePageChange}
          onDocsPerPage={handleDocsPerPage}
          docsPerPage={docsPerPage}
        />
        </> : <span>No Users available</span> }
      </>}

    </div>
  )
}
