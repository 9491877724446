import React, { useState } from 'react'
import { Button, Col, Row, Form } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import TableData from '../../Component/Common/Tables'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import Select from 'react-select'

export const PoliceUserSingleData = () => {
    const navigate = useNavigate()
    const { state } = useLocation();
    const userData = state?.data

    const [firstName, setFirstName] = useState(userData?.firstName)
    const [lastName, setLastName] = useState(userData?.lastName)
    const [emailID, setEmailID] = useState(userData?.emailId)
    const [adharNo, setAdharNo] = useState(userData?.adharNo)
    const [contactNo, setContactNo] = useState(userData?.contactNo)
    const [dob, setDob] = useState(userData?.dob)
    const [gender, setGender] = useState(userData?.gender)
    const [address, setAddress] = useState(userData?.address)
    const [states, setStates] = useState(userData?.state);
    const [city, setCity] = useState(useState?.city);
    const [rank, setRank] = useState(userData?.rank)
    const [unit, setUnit] = useState(userData?.unit)
    const [vehicle_details, setVehicle_details] = useState(userData?.vehicle_details || []);
    const [weapon_details, setWeapon_details] = useState(userData?.weapon_details || []);
    const [policeRole, setpoliceRole] = useState(userData?.policeRole)
    const [other, setOther] = useState(userData?.other_details?.map((itm) => { return itm }) || []);

    // usestate for validation
    const [firstNameError, setFirstNameError] = useState(false)
    const [lastNameError, setLastNameError] = useState(false)
    const [emailIdError, setEmailIdError] = useState(false)
    const [adharNoError, setAdharNoError] = useState(false)
    const [mobileError, setMobileError] = useState(false)
    const [rankError, setRankError] = useState(false)
    const [unitError, setUnitError] = useState(false)
    const [policeRoleError, setPoliceRoleError] = useState(false)

    const validate = () => {
        let formValidate = true;
        if (firstName.length === 0) {
            formValidate = false
            setFirstNameError(true)
        } else if (lastName.length === 0) {
            formValidate = false
            setLastNameError(true)
        } else if (emailID.length === 0) {
            formValidate = false
            setEmailIdError(true)
        } else if (adharNo.length === 0) {
            formValidate = false
            setAdharNoError(true)
        } else if (contactNo.length === 0) {
            formValidate = false
            setMobileError(true)
        } else if (contactNo.length !== 10) {
            formValidate = false;
            setMobileError(true);
        } else if (rank.length === 0) {
            formValidate = false
            setRankError(true)
        } else if (unit.length === 0) {
            formValidate = false
            setUnitError(true)
        }
        else if (policeRole.length === 0) {
            formValidate = false
            setPoliceRoleError(true)
        }
        return formValidate
    }

    // for toast notification
    const udatePoliceUser = () => {
        toast.success("Updated Successfully.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000
        });
    }

    const options = [{ label: "Police", value: "police" }, { label: "PSI", value: "PSI" }, { label: "Police-admin", value: "police-admin" },
    { label: "Admin", value: "admin" }]



    const updatePoliceUser = (e) => {
        e.preventDefault();
        setFirstNameError(false)
        setLastNameError(false)
        setEmailIdError(false)
        setAdharNoError(false)
        setMobileError(false)
        setRankError(false)
        setUnitError(false)
        setPoliceRoleError(false)
        if (validate()) {
            axios.post(process.env.REACT_APP_BASE_URL + "/policestationuser/updatepolicestationuser", {
                "userId": userData?.userId,
                "policeStationId": userData?.policeStationId,
                "firstName": firstName,
                "lastName": lastName,
                "contactNo": contactNo,
                "adharNo": adharNo,
                "dob": dob,
                "emailId": emailID,
                "gender": gender,
                "blood_group": userData?.blood_group,
                "policeIdNo": userData?.policeIdNo,
                "rank": rank,
                "unit": unit,
                "vehicle_details": vehicle_details,
                "weapon_details": weapon_details,
                "other_details": other,
                "policeRole": policeRole,
                "isCyberCrime": false,
                "status": "active",
            })
                .then((res) => {
                    udatePoliceUser()
                    navigate(-1)
                })
                .catch((error) => {
                    console.log("error", error);
                })
        }

    }

    return (
        <div className="pageLayout">
            <ToastContainer />
            <Row style={{ marginLeft: "40px" }}>
                <Col md={6} sx={12} style={{ justifyContent: "flex-end" }}>
                    <Button className='primaryBtn' onClick={() => navigate(-1)}>
                        Back
                    </Button>
                </Col>
            </Row>

            <Row className='h-100 justify-content-center align-items-center px-5'>
                <Row className='gy-3 mt-1'>
                    <Col md={6}>
                        <Form.Label>First Name</Form.Label>
                        <Form.Control value={firstName} name='firstName'
                            onChange={(e) => {
                                setFirstName(e.target.value)
                                e.target.value.length >= 0 ?
                                    setFirstNameError(false) :
                                    setFirstNameError(true)
                            }}
                        />
                        {firstNameError ? <span style={{ color: "red" }}>Field is required</span> : ""}
                    </Col>
                    <Col md={6}>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control value={lastName} name='lastName'
                            onChange={(e) => {
                                setLastName(e.target.value)
                                e.target.value.length >= 0 ?
                                    setLastNameError(false) :
                                    setLastNameError(true)
                            }} />
                        {lastNameError ? <span style={{ color: "red" }}>Field is required</span> : ""}
                    </Col>

                    <Col md={6}>
                        <Form.Label>Email ID</Form.Label>
                        <Form.Control value={emailID} name='emailId'
                            onChange={(e) => {
                                setEmailID(e.target.value)
                                e.target.value.length >= 0 ?
                                    setEmailIdError(false) :
                                    setEmailIdError(true)
                            }}
                        />
                        {emailIdError ? <span style={{ color: "red" }}>Field is required</span> : ""}

                    </Col>
                    <Col md={6}>
                        <Form.Label>Aadhar Number</Form.Label>
                        <Form.Control value={adharNo} name='adharNo'
                            onChange={(e) => {
                                if (e.target.value.match(/^\d{0,14}$/) && e.target.value.trim()) {
                                    setAdharNo(e.target.value)
                                } else if (e.target.value.length === 0) {
                                    setAdharNo(e.target.value)
                                }
                                e.target.value.length >= 0 ?
                                    setAdharNoError(false) :
                                    setAdharNoError(true)
                            }} />
                        {adharNoError ? <span style={{ color: "red" }}>Field is required</span> : ""}
                    </Col>

                    <Col md={6}>
                        <Form.Label>Phone</Form.Label>
                        <Form.Control value={contactNo} name='contactNo'
                            onChange={(e) => {
                                if (e.target.value.match(/^\d{0,10}(\.\d{0,9})?$/) && e.target.value.trim()) {
                                    setContactNo(e.target.value)
                                } else if (e.target.value.length === 0) {
                                    setContactNo(e.target.value)
                                }
                                setMobileError(e.target.value.length > 0 && e.target.value.length !== 10);
                                // e.target.value.length >= 0 ?
                                //     setMobileError(false) :
                                //     setMobileError(true)
                            }} />
                        {mobileError && contactNo.length === 0 ? (
                            <p className='errMsg'>* Required Field</p>
                        ) : mobileError && contactNo.length !== 10 ? (
                            <p className='errMsg'>* Enter 10 digit mobile number</p>
                        ) : null}
                    </Col>
                    <Col md={6}>
                        <Form.Label>Date of Birth</Form.Label>
                        <Form.Control value={dob} readOnly
                        />
                    </Col>

                    {/* <Col md={12}>
                        <Form.Label>Gender</Form.Label><br />
                        <Form.Check type='radio' inline label="Male" value='male' name='gender'
                            checked={userData?.gender === 'male'} onChange={(e) => setGender(e.target.value)} />
                        <Form.Check type='radio' inline label="Female" value='female' name='gender'
                            checked={userData?.gender === 'female'} onChange={(e) => setGender(e.target.value)} />
                        <Form.Check type='radio' inline label="Other" value='other' name='gender'
                            checked={userData?.gender === 'other'} onChange={(e) => setGender(e.target.value)} />
                    </Col> */}

                    <Col md={12}>
                        <Form.Label>Gender</Form.Label><br />
                        <Form.Check
                            type='radio'
                            inline
                            label="Male"
                            value='male'
                            name='gender'
                            checked={userData?.gender === 'male'}
                            onChange={(e) => setGender(e.target.value)}
                        />
                        <Form.Check
                            type='radio'
                            inline
                            label="Female"
                            value='female'
                            name='gender'
                            checked={userData?.gender === 'female'}
                            onChange={(e) => setGender(e.target.value)}
                        />
                        <Form.Check
                            type='radio'
                            inline
                            label="Other"
                            value='other'
                            name='gender'
                            checked={userData?.gender === 'other'}
                            onChange={(e) => setGender(e.target.value)}
                        />
                    </Col>


                    <Col md={12}>
                        <Form.Label>Address</Form.Label>
                        <Form.Control value={userData?.address} readOnly />
                    </Col>

                    <Col md={6}>
                        <Form.Label>State</Form.Label>
                        <Form.Control value={userData?.state} readOnly />
                    </Col>
                    <Col md={6}>
                        <Form.Label>City</Form.Label>
                        <Form.Control value={userData?.city} readOnly />
                    </Col>

                    <Col md={12}>
                        <Form.Label>Office Credentials</Form.Label>
                    </Col>
                    <Col md={4}>
                        <Form.Label>Police ID</Form.Label>
                        <Form.Control value={userData?.policeIdNo} readOnly />
                    </Col>
                    <Col md={4}>
                        <Form.Label>Rank</Form.Label>
                        <Form.Control name="rank" value={rank} onChange={(e) => {
                            setRank(e.target.value)
                            e.target.value.length >= 0 ?
                                setRankError(false) :
                                setRankError(true)
                        }} />
                        {rankError ? <span style={{ color: "red" }}>Field is required</span> : ""}
                    </Col>
                    <Col md={4}>
                        <Form.Label>Unit</Form.Label>
                        <Form.Control value={unit} name="unit" onChange={(e) => {
                            setUnit(e.target.value)
                            e.target.value.length >= 0 ?
                                setUnitError(false) :
                                setUnitError(true)
                        }} />
                        {unitError ? <span style={{ color: "red" }}>Field is required</span> : ""}
                    </Col>

                    <Col md={12}>
                        <Form.Label>Police Role</Form.Label>
                        <Select value={{ label: policeRole, value: policeRole }} name="policeRole" onChange={(selectedOption) => setpoliceRole(selectedOption.label)} options={options} />
                    </Col>
                    <Col md={12}>
                        <Form.Label>Vehicle</Form.Label><br />
                        <Form.Check
                            type='checkbox'
                            inline
                            label="Car"
                            name="vehicle_details"
                            value="car"
                            checked={vehicle_details.includes("car")}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setVehicle_details([...vehicle_details, e.target.value]);
                                } else {
                                    setVehicle_details(vehicle_details.filter((item) => item !== e.target.value));
                                }
                            }} />
                        <Form.Check
                            type='checkbox'
                            inline
                            label="Bus"
                            name="vehicle_details"
                            value="bus"
                            checked={vehicle_details.includes("bus")}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setVehicle_details([...vehicle_details, e.target.value]);
                                } else {
                                    setVehicle_details(vehicle_details.filter((item) => item !== e.target.value));
                                }
                            }} />
                        <Form.Check
                            type='checkbox'
                            inline
                            label="Bike"
                            name="vehicle_details"
                            value="bike"
                            checked={vehicle_details.includes("bike")}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setVehicle_details([...vehicle_details, e.target.value]);
                                } else {
                                    setVehicle_details(vehicle_details.filter((item) => item !== e.target.value));
                                }
                            }} />
                    </Col>

                    <Col md={12}>
                        <Form.Label>Weapon</Form.Label><br />
                        <Form.Check
                            type='checkbox'
                            inline
                            label="Pistol"
                            name="weapon_details"
                            value="pistol"
                            checked={weapon_details.includes("pistol")}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setWeapon_details([...weapon_details, e.target.value]);
                                } else {
                                    setWeapon_details(weapon_details.filter((item) => item !== e.target.value));
                                }
                            }} />
                        <Form.Check
                            type='checkbox'
                            inline
                            label="Rifle"
                            name="weapon_details"
                            value="rifle"
                            checked={weapon_details.includes("rifle")}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setWeapon_details([...weapon_details, e.target.value]);
                                } else {
                                    setWeapon_details(weapon_details.filter((item) => item !== e.target.value));
                                }
                            }} />
                        <Form.Check
                            type='checkbox'
                            inline
                            label="Baton"
                            name="weapon_details"
                            value="baton"
                            checked={weapon_details.includes("baton")}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setWeapon_details([...weapon_details, e.target.value]);
                                } else {
                                    setWeapon_details(weapon_details.filter((item) => item !== e.target.value));
                                }
                            }} />
                        <Form.Check
                            type='checkbox'
                            inline
                            label="Bullet Proof Jacket"
                            name="weapon_details"
                            value="bullet proof jacket"
                            checked={weapon_details.includes("bullet proof jacket")}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setWeapon_details([...weapon_details, e.target.value]);
                                } else {
                                    setWeapon_details(weapon_details.filter((item) => item !== e.target.value));
                                }
                            }} />
                    </Col>

                    <Col>
                        <Form.Label>Other</Form.Label><br />
                        <Form.Control value={other} name="other" onChange={(e) => setOther(e.target.value)} />
                    </Col>
                </Row>

                <Row className='gy-3 mt-5 mb-5'>
                    <Col md={12} xs={12} className='d-flex justify-content-end'>
                        <Button className='secondaryBtn' onClick={(e) => updatePoliceUser(e)}>Save & Update</Button>
                    </Col>
                </Row>

            </Row>

        </div>
    )
}
