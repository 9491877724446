import { Icon } from '@iconify/react'
import React, { useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import Select from 'react-select'
import photo from "../../assets/policeProfilePhoto.png"
import TableData from '../../Component/Common/Tables'
import { useLocation, useNavigate } from 'react-router-dom'
import AddChowki from './AddChowki'
import { useDispatch, useSelector } from 'react-redux'
import { getPoliceChowkiList } from './PoliceSlice'
import axios from 'axios';
import { EditPoliceUser } from './EditPoliceUser'

const PoliceStationDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { state } = useLocation();
  const result = state?.data;
  const stationID = result?.stationId

  const PoliceChowkiListData = useSelector((state) => state?.PoliceStationData?.PoliceChowki);

  // state for pagination
  const [docsPerPage, setDocsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [taskId, setTaskId] = useState("")
  const [showaddchowkiModel, setShowAddChowkiModel] = useState(false)
  const [showEditPoliceModel, setShowEditPoliceModel] = useState(false)

  const [chowki, setChowki] = useState('')

  useEffect(() => {
    getChowkiData();
  }, [result?.stationId, , currentPage, docsPerPage,])

  const getChowkiData = () => {
    const payload = {
      stationId: result?.stationId,
    }

    if (result?.stationId) {
      axios.post(process.env.REACT_APP_BASE_URL + '/chowki/getPerticurerPoliceStationChowki', payload)
        .then((res) => {
          setChowki(res?.data);
        })
        .catch((err) => {
          console.log("Error", err)
        })
    }
  }

  const handleAddChowkiModelClose = (id) => {
    setTaskId(id);
    setShowAddChowkiModel(false);
    getChowkiData();
  }

  const handleAssignModelOpen = (id) => {
    setTaskId(id);
    setShowAddChowkiModel(true)
  }

  const handleShowEditPolice = (result) => {
    setShowEditPoliceModel(true)
  }

  const handleCloseEditPolice = () => {
    setShowEditPoliceModel(false)
  }

  const PoliceChowkiData = chowki !== undefined && chowki !== null && chowki?.length > 0 ?
    chowki?.map((itm, index) => {
      return {
        chowkiName: itm?.chowkiName,
        contactNo: itm?.contactNo,
      };
    })
    : <><p>No Data Found</p></>;

  const columns = [
    { header: 'Chowkies', field: 'chowkiName' },
    { header: 'Telephone Number', field: 'contactNo' },

  ]; return (
    <>
      <AddChowki show={showaddchowkiModel} close={handleAddChowkiModelClose} data={taskId} />
      <EditPoliceUser show={showEditPoliceModel} close={handleCloseEditPolice} data={result} />
      <div className="pageLayout" style={{ marginLeft: "10px" }}>
        <Row className='justify-content-between'>
          <Col md={2} className='mb-4 mb-md-0'>
            <Button className='primaryBtn' onClick={() => window.history.back()}>
              Back
            </Button>

          </Col>
          <Col md={6} className='d-flex justify-content-end'>
            <Button className='primaryBtn' onClick={() => navigate("/admin/policeStation/policeList", { state: { data: stationID } })}>
              List of Police Members
            </Button>
            <Button className='primaryBtn ms-3' onClick={() => handleShowEditPolice(result)}>
              Edit Details
            </Button>
          </Col>
        </Row>
        <Row className='my-3'>
          <Col md={12} className='my-3 d-flex align-items-center'>.
            <div>
              <img src={photo} style={{ width: "75px", height: "77.6px", borderRadius: "50%" }} />
            </div>
            <div className='ms-2 fs-6 m-0 p-0'>
              <p style={{ fontSize: "18px", margin: "0", padding: "0" }}>Arvind Kumar / अरविंद कुमार</p>
              <p>Police inspector</p>
            </div>

          </Col>
        </Row>
        <Row className='mx-4 my-2'>
          <Col className='d-flex' md={12}>
            <Col md={2}>
              <p style={{ marginBottom: "0.5rem" }} className='fw-bold'>Station</p>
            </Col>
            <Col md={10}>
              <p style={{ marginBottom: "0.5rem" }}>{result?.stationName}</p>
            </Col>

          </Col>
          <Col className='d-flex' md={12}>
            <Col md={2}>
              <p style={{ marginBottom: "0.5rem" }} className='fw-bold'>Division</p>
            </Col>
            <Col md={10}>
              <p style={{ marginBottom: "0.5rem" }}>{result?.division}n</p>
            </Col>

          </Col>
          <Col className='d-flex' md={12}>
            <Col md={2}>
              <p style={{ marginBottom: "0.5rem" }} className='fw-bold'>Zone</p>
            </Col>
            <Col md={10}>
              <p style={{ marginBottom: "0.5rem" }}>{result?.zone}</p>
            </Col>

          </Col>
          <Col className='d-flex' md={12}>
            <Col md={2}>
              <p style={{ marginBottom: "0.5rem" }} className='fw-bold'>Telephone</p>
            </Col>
            <Col md={10}>
              <p style={{ marginBottom: "0.5rem" }}>{result?.telephoneNo}</p>
            </Col>

          </Col>
          <Col className='d-flex' md={12}>
            <Col md={2}>
              <p style={{ marginBottom: "0.5rem" }} className='fw-bold'>Email</p>
            </Col>
            <Col md={10}>
              <p style={{ marginBottom: "0.5rem" }}>{result?.emailId}</p>
            </Col>

          </Col>
          <Col className='d-flex' md={12}>
            <Col md={2}>
              <p style={{ marginBottom: "0.5rem" }} className='fw-bold'>Address</p>
            </Col>
            <Col md={10}>
              <p style={{ marginBottom: "0.5rem" }}>{result?.address}</p>
            </Col>
          </Col>


        </Row>
        <Row className='mt-5'>
          <Col md={4}>
            <p style={{ color: '#183F91', fontWeight: 'bold', fontSize: '20px', marginLeft: '8px' }}>List of Police Chowki</p>
          </Col>
          <Col md={4}>
            <Button onClick={() => handleAssignModelOpen(result)} className='primaryBtn'>
              <Icon style={{ fontSize: "18px", marginRight: "8px" }} icon="gg:add" />
              Add Chowki
            </Button>
          </Col>
        </Row>
        <Row className='mx-4 my-0'>
          <Col md={4}>
            <TableData className="m-0" data={PoliceChowkiData} columns={columns} />
          </Col>
        </Row>
      </div>
    </>
  )
}

export default PoliceStationDetails