// PDMapView.js
import React, { useState, useEffect } from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
// import { AiOutlineClose } from 'react-icons/ai';
import { useSelector, useDispatch } from 'react-redux';
import {
  setScrollTo,
  setHighlightTo,
  setGlowSos,
} from '../Trackme/TrackMeSlice';
// import ViewUserData from './ViewUserData';
// Assuming you have this component defined
import { IoMdClose } from "react-icons/io";

const PDMapView = ({ path, sosData, google }) => {
  const dispatch = useDispatch();

  const [isSOSEnable] = useState(sosData.isSOSEnable)

  const [user, setUser] = useState(null);

  useEffect(() => {
    const intrvl = setInterval(() => {
      // dispatch(setHighlightTo(''));
      // dispatch(setScrollTo(''));
      // dispatch(setGlowSos([]));
    }, 1000);

    return () => {
      clearInterval(intrvl);
    };
  }, [dispatch]);
  const ViewUserData = ({ user, isSOSEnable, startLat, startLong }) => {

    const [Details, setDetails] = useState('')


    useEffect(() => {
      setDetails(user)
    }, [user])

    const onClose = () => {
      setDetails('')
    }

    return (
      <>
      <div
        style={{
          display: Details ? 'block' : 'none' }}>
        <div style={{ display:'flex', flexDirection:'row',justifyContent:'space-between' }}>
          <h5>
            Tracking Details
            {/* <AiOutlineClose style={{ float:'right',cursor:'pointer' }} onClick={onClose} /> */}
            <br />
            <small>
              <small>{isSOSEnable = "SOS"}</small>
            </small>
          </h5>
          <IoMdClose style={{ scale:2 }} onClick={ () => onClose() } />
        </div>
        
        <h6>
          Name: {user.first_name}{" "}{user.last_name}
        </h6>
        <p>
          Email: {user.email}
          <br />
          Gender: {user.gender}
          <br />
          Call:
          <span >{user.mobile}</span>
          Address: {startLat} {startLong}
        </p>
        <a target="_blank" href={`https://sps.foxberry.link/trackme?trackingId=${localStorage.getItem('trackingId')}`}>
          <button style={{ textAlign: "center", cursor: "pointer", background: "skyblue", border: "none", width: "150px", height: "30px", marginTop: "10px" }} >View Tracking</button></a>
      </div>
      </>
    );
  };

  return (
    <>

      <div>
      <Map 
        initialCenter={{ lat: 18.562207, lng: 73.813514 }}
        // center={{ lat: 18.562207, lng: 73.813514 }}
        google={google}
        zoom={14}
        className='mapView'
      >


        {sosData?.map((t, i) => {

          var selectedIcon;

          const sosIcon = {
            url: "https://i.imgur.com/rZHfqWc.gif",
            anchor: new google.maps.Point(18, 22),
            scaledSize: new google.maps.Size(42, 42),
          }

          const trackMeIcon = {
            url: "https://i.imgur.com/EUQxiaC.png",
            anchor: new google.maps.Point(18, 48),
            scaledSize: new google.maps.Size(42, 48),
          }

          const trackMeGrayIcon = {
            url: "https://i.imgur.com/NE08peP.png",
            anchor: new google.maps.Point(18, 48),
            scaledSize: new google.maps.Size(26, 48),
          }

          const highlighttrackMeIcon = {
            url: "https://foxberry-images.s3.amazonaws.com/VehicleTracking/Profile/Profile_628fb90bf431a200a81607c0/1663228166862_628fb90bf431a200a81607c0.jpeg",
            // url: "/images/EUQxiaC.png",
            anchor: new google.maps.Point(18, 48),
            scaledSize: new google.maps.Size(100, 100),

          }

          const glowMarker = {
            url: "https://icons8.com/vue-static/landings/animated-icons-new/icons/office-style/place-marker/place-marker_160.gif",
            anchor: new google.maps.Point(18, 48),
            scaledSize: new google.maps.Size(100, 100),
          }


          var OneDay = new Date().getTime() + (1 * 24 * 60 * 60 * 1000)
          if (OneDay < new Date(t.createdAt)) {
            // The yourDate time is more than 1 days from now
             selectedIcon = trackMeGrayIcon;
          }
          else {
            if (t.isSOSEnable) {
              selectedIcon = selectedIcon
            } else {
              selectedIcon = trackMeIcon
            }
          }

          return (
            <Marker
              key={Math.random() * i * 2}
              title={`View Track me of ${t.user?.first_name} ${t.user?.last_name} ${t.startLat} ${t.startLong}`}
              name={"Tracking Person"}
              onClick={() => {
                setUser(t.user);
                localStorage.setItem('trackingId', t.trackeMeId);
                dispatch(setScrollTo(t))
              }}
              position={{
                lat: t.startLat, lng: t.startLong

              }}
              icon={selectedIcon}

            />
            
            // <Polyline path={t.polylines.map(x => ({ lat: Number(x.Lat), lng: Number(x.Long) }))}
            //   strokeColor="#06f"
            //   strokeOpacity={0.8}
            //   strokeWeight={3} />
          )
        })}
      </Map>
    </div>

      {user && <ViewUserData user={user} isSOSEnable={isSOSEnable} />}
    </>
  );




};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyCUIPTEbTazVFkX5LZrm25XlUENtscFrBE',
})(PDMapView);
