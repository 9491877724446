import React, { useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { CloudArrowUpFill } from 'react-bootstrap-icons';
import * as XLSX from 'xlsx';
import * as Icon from 'react-bootstrap-icons';
import sampleFile from '../../assets/SampleFile.xlsx';
import { ToastContainer, toast } from 'react-toastify';

export const AddExcelFileModel = ({ show, close, getPoliceStationUserList, policeStationId }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileErr, setFileErr] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const uploadFile = async () => {
    setLoader(true);
    if (!selectedFile) {
      setFileErr('Please upload an Excel file');
      setLoader(false);
      return;
    } else if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);

      let jsonData;
      const reader = new FileReader();

      reader.onload = async (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
          raw: false,
          dateNF: 'm/d/yy',
          cellDates: true,
        });

        const missingFields = jsonData.filter((row) => {
          return (
            !row.firstName ||
            !row.lastName ||
            !row.mobile ||
            !row.emailId ||
            !row.gender ||
            !row.dob ||
            !row.policeRole ||
            !row.policeIdNo ||
            !row.rank ||
            !row.unit ||
            !row.vehicle_details ||
            !row.weapon_details ||
            !row.other_details ||
            !row.lat ||
            !row.long ||
            !row.isCyberCrime ||
            !row.adharNo ||
            !row.password ||
            !row.blood_group ||
            !row.state ||
            !row.city ||
            !row.address
          );
        });

        if (missingFields.length > 0) {
          setLoader(false);
          const missingFieldNames = missingFields.reduce((acc, row) => {
            Object.keys(row).forEach((key) => {
              if (!row[key]) acc.push(key);
            });
            return acc;
          }, []);

          toast.error(`Please ensure that the following fields are provided for all entries: ${missingFieldNames.join(', ')}`);
          return;
        }

        jsonData.forEach((row) => {
          if (row.dob) {
            const date = new Date(row.dob);
            row.dob = date.toISOString().split('T')[0];
          }
        });

        // Check if all mobile numbers are 10 digits
        const allMobilesValid = jsonData.every((row) => /^\d{10}$/.test(row.mobile));

        // Check if all Adhar numbers are 12 digits
        const allAdharValid = jsonData.every((row) => /^\d{12}$/.test(row.adharNo));

        if (!allMobilesValid && !allAdharValid) {
          setLoader(false);
          setSelectedFile('');
          toast.error('Enter correct digit for Mobile Number And Adhar Number');
        } else if (!allMobilesValid) {
          setLoader(false);
          setSelectedFile('');
          toast.error('10 digit required for Mobile Number in Excel File');
        } else if (!allAdharValid) {
          setLoader(false);
          setSelectedFile('');
          toast.error('12 digit required for Adhar Number in Excel File');
        } else {
          // If all validations pass, proceed to add data
          const payload = {
            policeStationId: policeStationId,
            userList: jsonData,
          };

          await fetch(process.env.REACT_APP_BASE_URL + '/policestationuser/insertMultiplePoliceStationUsers', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload),
          })
            .then((res) => res.json())
            .then((response) => {
              if (response?.data) {
                toast.success('File uploaded successfully.');
                close();
                getPoliceStationUserList();
              } else if (response?.data?.message === 'User already registered') {
                toast.error('User already registered');
              } else {
                toast.error('Error uploading the file.');
              }
            })
            .catch((error) => {
              console.error('An error occurred:', error);
            })
            .finally(() => setLoader(false));
        }
      };
      reader.readAsBinaryString(selectedFile);
    }
  };

  return (
    <div>
      <ToastContainer />
      <Modal show={show} onHide={close} size="md" centered backdrop="static" keyboard={false}>
        <Modal.Header closeButton className="mngmodelheader"></Modal.Header>
        <Form>
          <Modal.Body size="md">
            <Row>
              <Row className="justify-content-center">
                <Col md={12}>
                  <div className="d-flex justify-content-end align-items-center">
                    <a
                      href={sampleFile}
                      download="sampleFile"
                      style={{
                        backgroundColor: '#183F91',
                        padding: '6px 18px',
                        color: '#fff',
                        cursor: 'pointer',
                        textDecoration: 'none',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Icon.CloudArrowDownFill className="me-2" size={18} />
                      Download Sample File
                    </a>
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center mt-3">
                <Col md={8}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginBottom: '40px',
                    }}
                  >
                    <CloudArrowUpFill size={50} style={{ color: '#183F91' }} />
                    <h5>Drop your file here</h5>
                    <h6 style={{ color: 'grey' }}>File supported : XLSX</h6>
                    <Form.Control
                      type="file"
                      name="file"
                      onChange={(e) => {
                        handleFileChange(e);
                        setFileErr(false);
                      }}
                    />
                    {fileErr ? <p className="errMsg">Please upload an Excel file</p> : ''}
                  </div>
                </Col>
              </Row>
            </Row>

            {loader ? (
              <center>
                <Spinner variant="primary" />
              </center>
            ) : (
              <Button className="primaryBtn mx-auto" onClick={uploadFile}>
                <Icon.PersonAdd className="me-2" />
                Add Police User
              </Button>
            )}
          </Modal.Body>
        </Form>
      </Modal>
    </div>
  );
};




















// import React, { useState } from 'react'
// import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap'
// import { CloudArrowUp, CloudArrowUpFill } from 'react-bootstrap-icons'
// import * as Icon from "react-bootstrap-icons";
// import * as XLSX from "xlsx";
// import SampleFile from "../../assets/SampleFile.xlsx";
// import { ToastContainer, toast } from 'react-toastify';

// export const AddExcelFileModel = ({ show, close, getPoliceStationUserList, policeStationId }) => {

//   const [selectedFile, setSelectedFile] = useState(null);
//   const [fileErr, setFileErr] = useState(false);

//   const [loader, setLoader] = useState(false);

//   const handleFileChange = (event) => {
//     const file = event.target.files[0];
//     setSelectedFile(file);
//   };

//   const uploadFile = () => {

//     setLoader(true);
//     if (!selectedFile) {
//       setFileErr('Please upload an Excel file');
//       setLoader(false);
//       return;
//     }
//     else if (selectedFile) {
//       const formData = new FormData();
//       formData.append('file', selectedFile);

//       let jsonData;
//       const reader = new FileReader();

//       reader.onload = async (e) => {
//         const data = e.target.result;
//         const workbook = XLSX.read(data, { type: 'binary' });
//         const sheetName = workbook.SheetNames[0];
//         jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
//           raw: false,
//           dateNF: 'm/d/yy',
//           cellDates: true,
//         });

//         const missingFields = jsonData.filter((row) => {
//           return !row.firstName || !row.lastName || !row.mobile || !row.emailId || !row.gender || !row.dob ||
//             !row.policeRole || !row.policeIdNo || !row.rank || !row.unit || !row.vehicle_details || !row.weapon_details ||
//             !row.other_details || !row.lat || !row.long || !row.isCyberCrime || !row.adharNo || !row.password ||
//             !row.blood_group || !row.state || !row.city || !row.address
//         });

//         if (missingFields.length > 0) {
//           setLoader(false);
//           const missingFieldNames = [];
//           missingFields.forEach((row) => {
//             console.log("row", row)
//             if (!row.firstName) missingFieldNames.push('firstName');
//             if (!row.lastName) missingFieldNames.push('lastName');
//             if (!row.mobile) missingFieldNames.push('mobile');
//             if (!row.emailId) missingFieldNames.push('emailId');
//             if (!row.gender) missingFieldNames.push('gender');
//             if (!row.dob) missingFieldNames.push('dob');
//             if (!row.policeRole) missingFieldNames.push('policeRole');
//             if (!row.policeIdNo) missingFieldNames.push('policeIdNo');
//             if (!row.rank) missingFieldNames.push('rank');
//             if (!row.unit) missingFieldNames.push('unit');
//             if (!row.vehicle_details) missingFieldNames.push('vehicle_details');
//             if (!row.weapon_details) missingFieldNames.push('weapon_details');
//             if (!row.other_details) missingFieldNames.push('other_details');
//             if (!row.lat) missingFieldNames.push('lat');
//             if (!row.long) missingFieldNames.push('long');
//             if (!row.isCyberCrime) missingFieldNames.push('isCyberCrime');
//             if (!row.adharNo) missingFieldNames.push('adharNo');
//             if (!row.password) missingFieldNames.push('password');
//             if (!row.blood_group) missingFieldNames.push('blood_group');
//             if (!row.state) missingFieldNames.push('state');
//             if (!row.city) missingFieldNames.push('city');
//             if (!row.address) missingFieldNames.push('address');
//           });

//           toast.error(`Please ensure that the following fields are provided for all entries: ${missingFieldNames.join(', ')}`);
//           return;
//         }
//         console.log("jsonData", jsonData)

//         jsonData.forEach((row) => {
//           if (row.dob) {
//             const date = new Date(row.dob);
//             row.dob = date.toISOString().split('T')[0]; // Convert to "yyyy-mm-dd" format
//           }
//           if (row.mobile && !/^\d{10}$/.test(row.mobile)) {
//             // Check if mobile number is not 10 digits
//             setLoader(false);
//             toast.error(`Mobile number must be 10 digits for entry: ${row.firstName} ${row.lastName}`);
//             return;
//           }
//         });


//         const payload = {
//           "policeStationId": policeStationId,
//           "userList": jsonData
//         };
//         console.log("payload", payload)

//         const jsonString = JSON.stringify(payload);

//         await fetch(process.env.REACT_APP_BASE_URL + '/policestationuser/insertMultiplePoliceStationUsers', {
//           method: 'POST',
//           headers: { 'Content-Type': 'application/json' },
//           // body: formData,
//           body: JSON.stringify(payload),
//         }).then((res) => res.json())
//           .then(response => {
//             console.log("responsemultiple", response?.data);
//             if (response?.data) {
//               // File uploaded successfully
//               toast.success("File uploaded successfully.")
//               close();
//               getPoliceStationUserList();
//               setLoader(false);
//             } else if (response?.data?.message === "User already registered") {
//               // Handle the error
//               toast.error("User already registered")
//               setLoader(false);
//             }
//             else {
//               // Handle the error
//               toast.error("Error uploading the file.")
//               setLoader(false);
//             }
//           })
//           .catch(error => {
//             console.error('An error occurred:', error);
//           });
//       }
//       reader.readAsBinaryString(selectedFile);
//     }
//   };

//   return (
//     <div>
//       <ToastContainer />
//       <Modal show={show} onHide={close} size="md" centered backdrop='static' keyboard={false}>
//         <Modal.Header closeButton className="mngmodelheader">
//         </Modal.Header>
//         <Form>
//           <Modal.Body size="md">
//             <Row>
//               <Row className='justify-content-center'>
//                 <Col md={12}>
//                   <div className='d-flex justify-content-end align-items-center'>
//                     <a href={SampleFile} download="sampleFile" style={{ backgroundColor: "#183F91", padding: "6px 18px", color: "#fff", cursor: "pointer", textDecoration: 'none', display: 'flex', alignItems: "center" }}>
//                       <Icon.CloudArrowDownFill className='me-2' size={18} />Download Sample File</a>
//                   </div>
//                 </Col>
//               </Row>
//               <Row className='justify-content-center mt-3'>
//                 <Col md={8}>
//                   <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "40px" }}>
//                     <CloudArrowUpFill size={50} style={{ color: "#183F91" }} />
//                     <h5>Drop your file here</h5>
//                     <h6 style={{ color: "grey" }}>File supported : XLSX</h6>
//                     <Form.Control type='file' name="file"
//                       onChange={(e) => { handleFileChange(e); setFileErr(false); }} />
//                     {fileErr ? <p className='errMsg'>Please upload excel file</p> : ''}
//                   </div>
//                 </Col>
//               </Row>
//             </Row>

//             {loader ? <center><Spinner variant='primary' /></center> :
//               <Button className='primaryBtn mx-auto' onClick={uploadFile}>
//                 <Icon.PersonAdd className='me-2' />Add Police User
//               </Button>}
//           </Modal.Body>

//         </Form>
//       </Modal>
//     </div>
//   )
// }
