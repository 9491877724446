import React, { useEffect, useState } from "react";
import { Accordion, Button, Card, Form, Modal, Nav, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setActiveKey } from "./sidebarSlice";
import { setLogout } from "../Auth/loginSlice";
import spsIcon from "../../assets/spsIcon.png";
import { Icon } from '@iconify/react';

export const SideBars = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [loader, setLoader] = useState(false);

  const activeKeyRedux = useSelector(state => state?.SideBarData?.activeNumberKey)

  const handleAccordionChange = (activeKey, subActiveKey, tabName) => {
    dispatch(setActiveKey({ activeKey: activeKey, subActiveKey: subActiveKey, activeTab: tabName }));
  };

  const handleLogout = () => {
    dispatch(setLogout(null));
    navigate('/');
    localStorage.removeItem('persist:root')
  }


  // State variables for modal
  const [showModal, setShowModal] = useState(false);

  // Function to open the modal
  const openModal = () => setShowModal(true);

  // Function to close the modal
  const closeModal = () => setShowModal(false);


  function setnav() {
    const mobilenav = document.querySelector(".mobile-nav");
    const toggle = document.querySelector(".mobile-nav-toggle");

    const visibility = mobilenav.getAttribute('data-visible');
    if (visibility === "false") {
      mobilenav.setAttribute("data-visible", true);
      toggle.setAttribute("aria-expanded", true);
      document.querySelector("body").style.overflow = 'hidden';
    }
    else if (visibility === "true") {
      mobilenav.setAttribute("data-visible", false);
      toggle.setAttribute("aria-expanded", false);
      document.querySelector("body").style.overflow = 'auto';
    }
  }

  return (
    <>
      <div className="sidenav-lg d-flex justify-content-between flex-column" >
        <div>
          <div className="my-3 text-center">
            <img src={spsIcon} alt="SPS" width={50} className="mb-2" />
            <p>Safety  Positioning System</p>
            <hr></hr>
          </div>
          {
            <Accordion flush activeKey={activeKeyRedux?.activeKey}>
              <span style={{ marginLeft: "12px", fontSize: "14px", color: "#183F91", fontWeight: "600" }}>Menu</span>
              <Accordion.Item eventKey={1} onClick={() => {
                handleAccordionChange(1, 1, "Dashboard");
                navigate("Dashboard");
              }} className={activeKeyRedux?.activeKey === 1 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 1 ?
                  <Icon icon="material-symbols:dashboard" className="me-3" fontSize={22} /> :
                  <Icon icon="material-symbols:dashboard" className="me-3" fontSize={22} />}Dashboard</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={2} onClick={() => {
                handleAccordionChange(2, 2, "SOS Raised");
                navigate("sosRaise");
              }} className={activeKeyRedux?.activeKey === 2 ? "activeMenu" : ""}>
                <Accordion.Header >
                  {activeKeyRedux?.activeKey === 2 ?
                    <Icon icon="ant-design:alert-filled" className="me-3" fontSize={22} /> :
                    <Icon icon="ant-design:alert-filled" className="me-3" fontSize={22} />}SOS Raised</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={3} onClick={() => {
                handleAccordionChange(3, 3, "Trackme");
                navigate("trackeme");
              }} className={activeKeyRedux?.activeKey === 3 ? "activeMenu" : ""}>
                <Accordion.Header>
                  {activeKeyRedux?.activeKey === 3 ?
                    <Icon icon="ic:outline-my-location" className="me-3" fontSize={22} /> :
                    <Icon icon="ic:outline-my-location" className="me-3" fontSize={22} />}Trackme
                </Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={4} onClick={() => {
                handleAccordionChange(4, 4, "Volunteer");
                navigate("Volunteer");
              }} className={activeKeyRedux?.activeKey === 4 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 4 ?
                  <Icon icon="maki:police" className="me-3" fontSize={22} /> :
                  <Icon icon="maki:police" className="me-3" fontSize={22} />}Volunteer
                </Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={5} onClick={() => {
                handleAccordionChange(5, 5, "Police Station");
                navigate("policeStationList");
              }} className={activeKeyRedux?.activeKey === 5 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 5 ?
                  <Icon icon="mdi:police-badge" className="me-3" fontSize={22} /> :
                  <Icon icon="mdi:police-badge" className="me-3" fontSize={22} />}Police
                </Accordion.Header>
              </Accordion.Item>

              <Accordion.Item
                eventKey={6}
                onClick={() => {
                  handleAccordionChange(6, 6, "Analytical Dashboard");
                  // window.location.href = "https://public.tableau.com/app/profile/satej2556/viz/PoliceDashboard_16319740976170/Dashboard1";
                  navigate('analytical-dashboard')
                }}
                className={activeKeyRedux?.activeKey === 6 ? "activeMenu" : ""}
              >
                <Accordion.Header>
                  {activeKeyRedux?.activeKey === 6 ? (
                    <Icon icon="material-symbols:analytics" className="me-3" fontSize={22} />
                  ) : (
                    <Icon icon="material-symbols:analytics" className="me-3" fontSize={22} />
                  )}
                  Analytical Dashboard
                </Accordion.Header>
              </Accordion.Item>

              <span style={{ fontSize: "14px", color: "#183F91", margin: "10px 0px 10px 13px", fontWeight: "600" }}>Report List</span>
              <Accordion.Item eventKey={7} onClick={() => {
                handleAccordionChange(7, 7, "Report Incident");
                navigate("incident");
              }} className={activeKeyRedux?.activeKey === 7 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 7 ?
                  <Icon icon="tabler:point-filled" className="me-3" fontSize={20} /> :
                  <Icon icon="tabler:point-filled" className="me-3" fontSize={20} />}Incident</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={8} onClick={() => {
                handleAccordionChange(8, 8, "Cyber Crime");
                navigate("CyberCrime");
              }} className={activeKeyRedux?.activeKey === 8 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 8 ?
                  <Icon icon="tabler:point-filled" className="me-3" fontSize={20} /> :
                  <Icon icon="tabler:point-filled" className="me-3" fontSize={20} />}Cyber Crime</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={9} onClick={() => {
                handleAccordionChange(9, 9, "Track Me");
                navigate("trackmeList");
              }} className={activeKeyRedux?.activeKey === 9 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 9 ?
                  <Icon icon="tabler:point-filled" className="me-3" fontSize={20} /> :
                  <Icon icon="tabler:point-filled" className="me-3" fontSize={20} />}Track Me</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={10} onClick={() => { handleAccordionChange(10, 10, "SOS"); navigate("sosList"); }} className={activeKeyRedux?.activeKey === 10 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 10 ?
                  <Icon icon="tabler:point-filled" className="me-3" fontSize={20} /> :
                  <Icon icon="tabler:point-filled" className="me-3" fontSize={20} />}SOS</Accordion.Header>
              </Accordion.Item>

            </Accordion>
          }

        </div>

        <div className="pb-4 ps-2 mt-2">
          <Button className='logoutBtn w-100' onClick={openModal}>{loader ?
            <Spinner animation="border" variant="light" className='spinner' /> :
            <><Icon icon="ant-design:logout-outlined" className="me-2" />Logout</>}</Button>
        </div>
      </div>

      {/* for model */}
      <Modal show={showModal} onHide={closeModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Logout Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to logout?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Button variant="primary" className="logoutBtn" onClick={handleLogout}>
            Logout
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
