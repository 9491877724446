import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import TableData from "../../../Component/Common/Tables";
import { useLocation, useNavigate } from "react-router-dom";
import { FileX } from "react-bootstrap-icons";
import io from "socket.io-client";

const socket = io("https://server.sps.foxberry.link");

export const PoliceListTable = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userData = location?.state?.userData;
  const stationData = location?.state?.data;
  const [stationList, setStationList] = useState([]);
  const [assignPoliceRes, setAssignPoliceRes] = useState([]);
  const [caseAssign, setCaseAssign] = useState([]);
  const [buttonClicked, setButtonClicked] = useState({});

  useEffect(() => {
    getPolisStationList();
  }, []);

  const getPolisStationList = async () => {
    await fetch(
      process.env.REACT_APP_BASE_URL +
      "/policestationuser/getpolicestationuser",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          policeStationId: stationData?.stationId,
        }),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        setStationList(response?.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const assignPolice = async (item) => {

    const payload = {
      policeStationId: item?.policeStationId,
      policeStationUser_id: item?._id,
      police_UserId: item.userId,
      policeUserFirstName: item?.firstName,
      policeUserLastName: item?.lastName,
      policeUserContactNo: item?.contactNo,
      policeUserEmailId: item?.emailId,
      isSOSEnable: true,
      trackMeId: userData?.trackeMeId,
      statusOfCase: [
        {
          status: item?.status,
          Date: item?.updatedAt,
        },
      ],
      currentStatusOfCase: item?.status,
      descriptionOfCase: "",
      conclusionOf: "",
      isFIR: false,
      policeStationUsersAssignId: "",
    }
    console.log(userData,payload)
    await fetch(
      process.env.REACT_APP_BASE_URL +
      "/policeusercases/insert/policestationuser/case",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        socket.emit('getPoliceUserTrackMe', {
          uuid: `getPoliceUserTrackMe/${item?._id}`,
        });
        setAssignPoliceRes(response);
        setCaseAssign(response?.data?.currentStatusOfCase);
        getPolisStationList();
        navigate('/admin/sosRaise')
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const data =
    stationList !== "No data found" &&
      stationList !== undefined &&
      stationList !== null &&
      stationList?.length > 0
      ? stationList?.map((item) => {
        return {
          Designation: item?.policeRole,
          Name: item?.firstName + " " + item?.lastName,

          Action:
            // Display "Assign Police" button with onClick if assignCase is not true
            <Button
              variant="secondary"
              onClick={() => {
                console.log(item)
                assignPolice(item)
              }}
            >
              Assign Police
            </Button>
          ,
        };
      })
      : [];

  const columns = [
    { header: "Designation", field: "Designation" },
    { header: "Name", field: "Name" },
    { header: "Action", field: "Action" },
  ];

  return (
    <div className="pageLayout">
      <div style={{ marginLeft: "15px" }}>
        <Button
          className="primaryBtn"
          onClick={() => {
            navigate(-1);
          }}
        >
          Back
        </Button>
      </div>
      <div>
        <h4 style={{ textAlign: "center" }}>Shivaji nagar police station</h4>
      </div>
      <TableData data={data} columns={columns} />
    </div>
  );
};