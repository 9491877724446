import React from 'react'
import { SideBars } from '../Component/Sidebar/SideBars'
import { Col, Container, Row } from 'react-bootstrap'
import { Outlet } from 'react-router-dom'
import { MenuBar } from '../Component/Common/MenuBar'

export const CommonLayout = () => {
    return (
        <div>
        <Container fluid>
        <Row>
        <div className='sidenav-lg-parent'>
        <Col className='ps-0 d-none d-lg-block'>
          <SideBars/>
        </Col>
          <Col>
            <div className='outletDiv'>
            <MenuBar/>
            <Outlet/>
          </div>
          </Col>
          </div>
        </Row>
      </Container>
      </div>
      )
}
