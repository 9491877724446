import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Modal,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";

const AddNewPoliceUser = ({ show, close, data }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const stationID = state?.data;

  const policeUserAdded = () => {
    toast.success("Police station user added successfully", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
    });
  };

  const options = [
    { label: "Police", value: "police" },
    { label: "PSI", value: "PSI" },
    { label: "police-admin", value: "police-admin" },
    { label: "Admin", value: "admin" },
  ];

  // state for new user
  const [adduser, setAddUser] = useState({
    firstName: "",
    lastName: "",
    emailId: "",
    adharNo: "",
    mobile: "",
    dob: "",
    gender: "",
    address: "",
    state: "",
    city: "",
    policeIdNo: "",
    policeStationId: stationID,
    rank: "",
    unit: "",
    policeRole: "",
    password: "",
    vehicle_details: [],
    weapon_details: [],
    other_details: "",
  });

  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailIdError, setEmailIdError] = useState(false);
  const [adharNoError, setAdharNoError] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [dobError, setDobError] = useState(false);
  const [genderError, setGenderError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [policeIdNoError, setPoliceIdNoError] = useState(false);
  const [rankError, setRankError] = useState(false);
  const [unitError, setUnitError] = useState(false);
  const [policeRoleError, setPoliceRoleError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [vehicle_detailsError, setVehicle_detailsError] = useState(false);
  const [weapon_detailsError, setWeapon_detailsError] = useState(false);

  // function for handler change
  const handleAddUser = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (name === "vehicle_details" || name === "weapon_details") {
      const isChecked = e.target.checked;
      const selectedValue = e.target.value;

      if (isChecked) {
        setAddUser((prevUser) => ({
          ...prevUser,
          [name]: [...prevUser[name], selectedValue],
        }));
      } else {
        setAddUser((prevUser) => ({
          ...prevUser,
          [name]: prevUser[name].filter((item) => item !== selectedValue),
        }));
      }
    } else {
      setAddUser({ ...adduser, [name]: value });
    }
  };

  const validate = () => {
    let formValidate = true;

    if (adduser.firstName.length === 0) {
      formValidate = false;
      setFirstNameError(true);
    } else if (adduser.lastName.length === 0) {
      formValidate = false;
      setLastNameError(true);
    } else if (adduser.emailId.length === 0) {
      formValidate = false;
      setEmailIdError(true);
    } else if (adduser.adharNo.length === 0) {
      formValidate = false;
      setAdharNoError(true);
    } else if (adduser.mobile.length === 0) {
      formValidate = false;
      setMobileError(true);
    } else if (adduser.mobile.length !== 10) {
      formValidate = false;
      setMobileError(true);
    } else if (adduser.dob.length === 0) {
      formValidate = false;
      setDobError(true);
    } else if (adduser.gender.length === 0) {
      formValidate = false;
      setGenderError(true);
    } else if (adduser.address.length === 0) {
      formValidate = false;
      setAddressError(true);
    } else if (adduser.state.length === 0) {
      formValidate = false;
      setStateError(true);
    } else if (adduser.city.length === 0) {
      formValidate = false;
      setCityError(true);
    } else if (adduser.policeIdNo.length === 0) {
      formValidate = false;
      setPoliceIdNoError(true);
    } else if (adduser.rank.length === 0) {
      formValidate = false;
      setRankError(true);
    } else if (adduser.unit.length === 0) {
      formValidate = false;
      setUnitError(true);
    }
    // else if(adduser.policeRole.length === 0){
    //     formValidate = false
    //     setPoliceRoleError(true)
    // }
    else if (adduser.password.length === 0) {
      formValidate = false;
      setPasswordError(true);
    } else if (adduser.vehicle_details.length === 0) {
      formValidate = false;
      setVehicle_detailsError(true);
    } else if (adduser.weapon_details.length === 0) {
      formValidate = false;
      setWeapon_detailsError(true);
    }
    return formValidate;
  };

  // function for add user
  const handleSubmit = async (e) => {
    e.preventDefault();
    setFirstNameError(false);
    setLastNameError(false);
    setEmailIdError(false);
    setAdharNoError(false);
    setMobileError(false);
    setDobError(false);
    setGenderError(false);
    setAddressError(false);
    setStateError(false);
    setCityError(false);
    setPoliceIdNoError(false);
    setRankError(false);
    setUnitError(false);
    setPoliceRoleError(false);
    setPasswordError(false);
    setVehicle_detailsError(false);
    setWeapon_detailsError(false);

    if (validate()) {
      await fetch(
        process.env.REACT_APP_BASE_URL +
        "/policestationuser/insertpolicestationuser",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            firstName: adduser.firstName,
            lastName: adduser.lastName,
            emailId: adduser.emailId,
            adharNo: adduser.adharNo,
            mobile: adduser.mobile,
            dob: adduser.dob,
            gender: adduser.gender,
            address: adduser.address,
            state: adduser.state,
            city: adduser.city,
            policeIdNo: adduser.policeIdNo,
            policeStationId: stationID,
            rank: adduser.rank,
            unit: adduser.unit,
            policeRole: adduser.policeRole.label,
            password: adduser.password,
            vehicle_details: adduser.vehicle_details,
            weapon_details: adduser.weapon_details,
            other_details: adduser.other_details,
          }),
        }
      )
        .then((res) => res.json())
        .then((response) => {
          if (response?.msg === "add Police Station user Successfully") {
            policeUserAdded();
          } else if (response?.msg === "User already registered!") {
            toast.success(response?.msg, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
          }
          data();
          close();
        })
        .catch((err) => {
          console.log("Err while register", err);
        });
    } else {
      console.log("Not Validate!");
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="pageLayout">
        <Modal
          show={show}
          onHide={close}
          size="lg"
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton className="mngmodelheader">
            <Modal.Title>
              <h5 style={{ fontWeight: "600" }}>User Information</h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body size="lg">
            <Form>
              <Row className="gy-3 mt-1">
                <Col md={4}>
                  <Form.Label>
                    First Name <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    placeholder="Enter first name"
                    name="firstName"
                    value={adduser.firstName}
                    onChange={(e) => {
                      handleAddUser(e);
                      e.target.value.length > 0
                        ? setFirstNameError(false)
                        : setFirstNameError(true);
                    }}
                  />
                  {firstNameError ? (
                    <span style={{ color: "red" }}>
                      Field is required
                    </span>
                  ) : (
                    ""
                  )}
                </Col>

                <Col md={4}>
                  <Form.Label>
                    Last Name <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    placeholder="Enter last name"
                    name="lastName"
                    value={adduser.lastName}
                    onChange={(e) => {
                      handleAddUser(e);
                      e.target.value.length > 0
                        ? setLastNameError(false)
                        : setLastNameError(true);
                    }}
                  />
                  {lastNameError ? (
                    <span style={{ color: "red" }}>
                      Field is required
                    </span>
                  ) : (
                    ""
                  )}
                </Col>

                <Col md={4}>
                  <Form.Label>
                    Email ID <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    placeholder="Enter Email"
                    name="emailId"
                    value={adduser.emailId}
                    onChange={(e) => {
                      handleAddUser(e);
                      e.target.value.length > 0
                        ? setEmailIdError(false)
                        : setEmailIdError(true);
                    }}
                  />
                  {emailIdError ? (
                    <span style={{ color: "red" }}>
                      Field is required
                    </span>
                  ) : (
                    ""
                  )}
                </Col>

                <Col md={4}>
                  <Form.Label>
                    Aadhar Number <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    placeholder="000 000 000 000"
                    name="adharNo"
                    value={adduser.adharNo}
                    onChange={(e) => {
                      if (
                        e.target.value.match(/^\d{0,12}$/) &&
                        e.target.value.trim()
                      ) {
                        handleAddUser(e);
                      } else if (e.target.value.length === 0) {
                        handleAddUser(e);
                      }
                      e.target.value.length > 0
                        ? setAdharNoError(false)
                        : setAdharNoError(true);
                    }}
                  />
                  {adharNoError ? (
                    <span style={{ color: "red" }}>
                      Field is required
                    </span>
                  ) : (
                    ""
                  )}
                </Col>

                <Col md={4}>
                  <Form.Label>
                    Phone <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    placeholder="+91"
                    value={adduser.mobile}
                    name="mobile"
                    onChange={(e) => {
                      if (
                        e.target.value.match(/^\d{0,10}(\.\d{0,9})?$/) &&
                        e.target.value.trim()
                      ) {
                        handleAddUser(e);
                      } else if (e.target.value.length === 0) {
                        handleAddUser(e);
                      }
                      setMobileError(e.target.value.length > 0 && e.target.value.length !== 10);
                      // e.target.value.length > 0
                      //   ? setMobileError(false)
                      //   : setMobileError(true);
                    }}
                  />
                  {mobileError && adduser.mobile.length === 0 ? (
                    <p className='errMsg'>* Required Field</p>
                  ) : mobileError && adduser.mobile.length !== 10 ? (
                    <p className='errMsg'>* Enter 10 digit mobile number</p>
                  ) : null}
                </Col>

                <Col md={4}>
                  <Form.Label>
                    Date of Birth <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="DD/MM/YYYY"
                    name="dob"
                    value={adduser?.dob}
                    onChange={(e) => {
                      handleAddUser(e);
                      e.target.value.length > 0
                        ? setDobError(false)
                        : setDobError(true);
                    }}
                  />
                  {dobError ? (
                    <span style={{ color: "red" }}>
                      Field is required
                    </span>
                  ) : (
                    ""
                  )}
                </Col>

                <Col md={12}>
                  <Form.Label>
                    Gender <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <br />
                  <Form.Check
                    type="radio"
                    inline
                    label="Male"
                    value="male"
                    name="gender"
                    onChange={(e) => {
                      handleAddUser(e);
                      e.target.value.length > 0
                        ? setGenderError(false)
                        : setGenderError(true);
                    }}
                  />
                  <Form.Check
                    type="radio"
                    inline
                    label="Female"
                    value="female"
                    name="gender"
                    onChange={(e) => {
                      handleAddUser(e);
                      e.target.value.length > 0
                        ? setGenderError(false)
                        : setGenderError(true);
                    }}
                  />
                  <Form.Check
                    type="radio"
                    inline
                    label="Other"
                    value="other"
                    name="gender"
                    onChange={(e) => {
                      handleAddUser(e);
                      e.target.value.length > 0
                        ? setGenderError(false)
                        : setGenderError(true);
                    }}
                  />
                </Col>
                {genderError ? (
                  <span style={{ color: "red" }}>Field is required</span>
                ) : (
                  ""
                )}

                <Col md={12}>
                  <Form.Label>
                    Address <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="Street Address"
                    name="address"
                    value={adduser.address}
                    onChange={(e) => {
                      handleAddUser(e);
                      e.target.value.length > 0
                        ? setAddressError(false)
                        : setAddressError(true);
                    }}
                  />
                  {addressError ? (
                    <span style={{ color: "red" }}>
                      Field is required
                    </span>
                  ) : (
                    ""
                  )}
                </Col>

                <Col md={6}>
                  <Form.Control
                    placeholder="State"
                    name="state"
                    value={adduser.state}
                    onChange={(e) => {
                      handleAddUser(e);
                      e.target.value.length > 0
                        ? setStateError(false)
                        : setStateError(true);
                    }}
                  />
                  {stateError ? (
                    <span style={{ color: "red" }}>
                      Field is required
                    </span>
                  ) : (
                    ""
                  )}
                </Col>

                <Col md={6}>
                  <Form.Control
                    placeholder="City"
                    name="city"
                    value={adduser.city}
                    onChange={(e) => {
                      handleAddUser(e);
                      e.target.value.length > 0
                        ? setCityError(false)
                        : setCityError(true);
                    }}
                  />
                  {cityError ? (
                    <span style={{ color: "red" }}>
                      Field is required
                    </span>
                  ) : (
                    ""
                  )}
                </Col>

                <Col md={12}>
                  <Form.Label>
                    Office Credential{" "}
                    <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                </Col>
                <Col md={4}>
                  <Form.Control
                    placeholder="Police Id"
                    name="policeIdNo"
                    value={adduser.policeIdNo}
                    onChange={(e) => {
                      handleAddUser(e);
                      e.target.value.length > 0
                        ? setPoliceIdNoError(false)
                        : setPoliceIdNoError(true);
                    }}
                  />
                  {policeIdNoError ? (
                    <span style={{ color: "red" }}>
                      Field is required
                    </span>
                  ) : (
                    ""
                  )}
                </Col>

                <Col md={4}>
                  <Form.Control
                    placeholder="Rank"
                    name="rank"
                    value={adduser.rank}
                    onChange={(e) => {
                      handleAddUser(e);
                      e.target.value.length > 0
                        ? setRankError(false)
                        : setRankError(true);
                    }}
                  />
                  {rankError ? (
                    <span style={{ color: "red" }}>
                      Field is required
                    </span>
                  ) : (
                    ""
                  )}
                </Col>

                <Col md={4}>
                  <Form.Control
                    placeholder="Unit"
                    name="unit"
                    value={adduser.unit}
                    onChange={(e) => {
                      handleAddUser(e);
                      e.target.value.length > 0
                        ? setUnitError(false)
                        : setUnitError(true);
                    }}
                  />
                  {unitError ? (
                    <span style={{ color: "red" }}>
                      Field is required
                    </span>
                  ) : (
                    ""
                  )}
                </Col>

                <Col md={6}>
                  <Select placeholder="Police Role" options={options} />
                </Col>

                <Col md={6}>
                  <Form.Control
                    placeholder="Password"
                    name="password"
                    value={adduser.password}
                    onChange={(e) => {
                      handleAddUser(e);
                      e.target.value.length > 0
                        ? setPasswordError(false)
                        : setPasswordError(true);
                    }}
                  />
                  {passwordError ? (
                    <span style={{ color: "red" }}>
                      Field is required
                    </span>
                  ) : (
                    ""
                  )}
                </Col>

                <Col md={12}>
                  <Form.Label>
                    Vehicle <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <br />
                  <Form.Check
                    type="checkbox"
                    inline
                    label="Car"
                    name="vehicle_details"
                    value="car"
                    onChange={handleAddUser}
                    checked={adduser.vehicle_details.includes("car")}
                  />
                  <Form.Check
                    type="checkbox"
                    inline
                    label="Bus"
                    name="vehicle_details"
                    value="bus"
                    onChange={handleAddUser}
                    checked={adduser.vehicle_details.includes("bus")}
                  />
                  <Form.Check
                    type="checkbox"
                    inline
                    label="Bike"
                    name="vehicle_details"
                    value="bike"
                    onChange={handleAddUser}
                    checked={adduser.vehicle_details.includes("bike")}
                  />
                </Col>
                {vehicle_detailsError ? (
                  <span style={{ color: "red" }}>
                    Field is required
                  </span>
                ) : (
                  ""
                )}

                <Col md={12}>
                  <Form.Label>
                    Weapon<span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <br />
                  <Form.Check
                    type="checkbox"
                    inline
                    label="Pistol"
                    name="weapon_details"
                    value="pistol"
                    onChange={handleAddUser}
                    checked={adduser.weapon_details.includes("pistol")}
                  />
                  <Form.Check
                    type="checkbox"
                    inline
                    label="Rifle"
                    name="weapon_details"
                    value="rifle"
                    onChange={handleAddUser}
                    checked={adduser.weapon_details.includes("rifle")}
                  />
                  <Form.Check
                    type="checkbox"
                    inline
                    label="Baton"
                    name="weapon_details"
                    value="baton"
                    onChange={handleAddUser}
                    checked={adduser.weapon_details.includes("baton")}
                  />
                  <Form.Check
                    type="checkbox"
                    inline
                    label="Bullet Proof Jacket"
                    name="weapon_details"
                    value="bulletproofjacket"
                    onChange={handleAddUser}
                    checked={adduser.weapon_details.includes(
                      "bulletproofjacket"
                    )}
                  />
                </Col>
                {weapon_detailsError ? (
                  <span style={{ color: "red" }}>
                    Field is required
                  </span>
                ) : (
                  ""
                )}

                <Col md={12}>
                  <Form.Label>Other</Form.Label>
                  <Form.Control
                    name="other_details"
                    value={adduser.other_details}
                    onChange={(e) => handleAddUser(e)}
                  />
                </Col>
              </Row>

              <Row className="mt-5 mb-3">
                <Col className="d-flex justify-content-center">
                  <Button
                    className="submit-Btn px-5"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default AddNewPoliceUser;
