import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import TableData from '../../../Component/Common/Tables';
import { Button } from 'react-bootstrap';

export const AssignPolice = () => {
  const location = useLocation();
  const userData = location?.state?.data
  const navigate = useNavigate()

  // usestate
  const [policeStationList, setPoliceStationList] = useState([])
  const [loading, setLoading] = useState(true);


  // state for pagination
  const [docsPerPage, setDocsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getPoliceList()
  }, [])



  const getPoliceList = async () => {
    await fetch(process.env.REACT_APP_BASE_URL + "/policestation/getnearpolicestation", {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        poslat: userData?.startLat,
        poslng: userData?.startLong,
        km: "10"
      })
    })
      .then((res) => res.json())
      .then((response) => {
        setPoliceStationList(response)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        console.log("err", err);
      })
  }


  const data = policeStationList !== undefined && policeStationList !== null && policeStationList?.length > 0 ? policeStationList?.sort((a, b) => a.distance - b.distance) &&
    policeStationList?.map((itm) => {
      return {
        Station: itm?.stationName,
        Distance: itm?.distance.toFixed(2),
        PoliceAvailibility: "",
        List: <Button variant="outline-primary" className='policelist' onClick={() => { navigate("/admin/policeListTable", { state: { data: itm,userData:userData } }) }}>
          Police List
        </Button>
      }
    }) : []


  const columns = [
    { header: 'Station', field: 'Station' },
    { header: 'Distance(km)', field: 'Distance' },
    { header: 'Police Availibility', field: 'PoliceAvailibility' },
    { header: 'List', field: 'List' },
  ];

  return (
    <div className='pageLayout'>
      <Button className='primaryBtn' style={{ marginLeft: "15px" }} onClick={() => navigate(-1)}>Back</Button>
      {
        loading ? (
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <TableData data={data} columns={columns} />
        )
      }
    </div>
  )
}
