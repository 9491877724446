import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import TableData from '../../../Component/Common/Tables';
import axios from 'axios';
import { useSelector } from 'react-redux';

export const AssignVolenteer = () => {
  const location = useLocation();
  const userData = location?.state?.data
  const navigate = useNavigate()
  const [volunteerList, setVolunteerList] = useState([])
  const [loading, setLoading] = useState(true);
  const [incident,setIncident] = useState({})
  const user  = useSelector( (state) => state.loginData.userData.user )

  useEffect(() => {
    getVoluntyeerList()
  }, [])

  const getVoluntyeerList = async () => {
    await fetch(process.env.REACT_APP_BASE_URL + "/volunteer/getnearvolunteers", {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        poslat: userData.startLat,
        poslng: userData.startLong,
        km: "10"
      })
    })
      .then((res) => res.json())
      .then((response) => {
        setVolunteerList(response)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        console.log("err", err);
      })
  }

  const setAssignVolunteers = async (volunteer) => {
    console.log(volunteer)
    let payload = {
      trackMeId:userData.trackeMeId,
      volunteers_id:volunteer.volunteer_id,
      userId:volunteer.userId,
      firstName:userData.user.first_name,
      lastName:userData.user.last_name,
      contactNo:userData.user.mobile,
      emailId:userData.user.email,
      updatedBy:user.userId,
      incidentType:incident.incidentType,
      incidentId:incident.incidentId,
    }
    const res = await axios.put( process.env.REACT_APP_BASE_URL + '/policeusercases/add/volunteers',payload)

    if(res.status === 200){
      navigate('/admin/sosRaise')
    }
  }

  const dataVolunteer = volunteerList !== undefined && volunteerList !== null && volunteerList?.length > 0 ?
    volunteerList?.map((itm) => {
      return {
        Name: itm?.firstName,
        ContactNumber: itm?.contactNo,
        IncidentAllotment: <><div style={{ "display": "flex", "gap": "10px" }}>
          <Form.Select style={{ "width": "300px" }} placeholder='Select Incident Type' onChange={(e) => setIncident(JSON.parse(e.target.value)) }> 
            <option selected disabled>Open this select menu</option>
            {itm.Criteria.map((val) => {
              return <option value={JSON.stringify(val)}>{val.incidentType}</option>
            })}
          </Form.Select>
          <Button variant="outline-primary policelist"onClick={ () => setAssignVolunteers(itm) } >Assign</Button>
        </div></>
      }
    }) : []

  const columns = [
    { header: 'Name', field: 'Name' },
    { header: 'Contact Number', field: 'ContactNumber' },
    { header: 'Incident Allotment', field: 'IncidentAllotment' },
  ];

  return (
    <div className='pageLayout'>
      <Button className='primaryBtn' style={{ marginLeft: "15px" }} onClick={() => navigate(-1)}>Back</Button>
      {
        loading ? (
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <TableData data={dataVolunteer} columns={columns} />
        )
      }
    </div>

  )
}
