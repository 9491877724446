import React, { useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { BiArrowFromBottom } from 'react-icons/bi'
import { BsDot, BsFillArrowRightCircleFill } from 'react-icons/bs'
import { Icon } from '@iconify/react';
import { useDispatch, useSelector } from 'react-redux'
import TableData from '../../Component/Common/Tables'
import DateRange from '../../Component/Common/DateRange'
import fileImage from "../../assets/docs 1.png"
import location from "../../assets/pin 1.png"
import { getTrackMeData } from './TrackMeSlice';
import Pagination from '../../Component/Common/Pagination';

const TrackmeList = () => {

        const trackMeList = useSelector((state) => state?.TrackMelistData?.TrackMeList)

        const dispatch = useDispatch()
        const [toDate, setToDate] = useState("")
        const [fromDate, setFromDate] = useState("")

        // state for pagination
        const [docsPerPage, setDocsPerPage] = useState(10);
        const [currentPage, setCurrentPage] = useState(1);
        const [loading, setLoading] = useState(true);

        const handleFromDateChange = (e) => {
                setFromDate(e.target.value)
        }

        const handleToDateChange = (e) => {
                setToDate(e.target.value)
        }
        // function for pagination
        const handlePageChange = (pageNumber) => {
                setCurrentPage(pageNumber);
        };

        const handleDocsPerPage = (docsPageProp) => {
                setCurrentPage(1);
                setDocsPerPage(docsPageProp);
        };

        const openInNewTab = (url) => {
                window.open(url, "_blank", "noopener,noreferrer");
        };

        useEffect(() => {
                const payload = `/trackme/filtertrackme?page=${currentPage}&documentsPerPage=${docsPerPage}`;
                dispatch(getTrackMeData(payload))
                        .then(() => {
                                setLoading(false);
                        }).catch((error) => {
                                setLoading(false);
                                console.error("Error fetching data:", error);
                        });
        }, [currentPage, docsPerPage])

        const handleFilter = () => {
                const filterPayload = `/trackme/filtertrackme?startDate=${fromDate}&endDate=${toDate}`
                dispatch(getTrackMeData(filterPayload))
        }

        const handleReset = () => {
                setFromDate("")
                setToDate("")
                const payload = `/trackme/filtertrackme`;
                dispatch(getTrackMeData(payload))
        }

        const trackMeData = trackMeList?.data !== undefined && trackMeList?.data !== null && trackMeList?.data?.length > 0 ?
                trackMeList?.data?.map((itm, index) => {
                        const createdAtDate = new Date(itm.createdAt);

                        const formattedDate = createdAtDate.toLocaleDateString('en-US', {
                                day: 'numeric',
                                month: 'short',
                                year: 'numeric',
                        });

                        const formattedTime = createdAtDate.toLocaleTimeString('en-US', {
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: true,
                        });

                        // ui for status text color
                        const textColor = itm?.trackingStatus === 'stopby' ? 'red' : 'green';

                        return {
                                SrNo: currentPage !== 1 ? (<> {index + 1 + docsPerPage * (currentPage - 1)}</>) : <>{index + 1}</>,
                                name: itm?.user?.first_name + " " + itm?.user?.last_name,
                                mobile: itm?.user?.mobile,
                                sosRaised: `${formattedDate} ${formattedTime}`,
                                status: itm?.trackingStatus === 'stopby' ? <span style={{ color: 'red' }}>Stop By</span> : <span style={{ color: 'orange' }}>Tracking</span>,
                                audio: <img style={{ cursor: 'pointer' }} src={location} onClick={() =>
                                        openInNewTab(
                                                `https://sps.foxberry.link/trackme?trackingId=${itm?.trackeMeId}`
                                        )
                                } />
                        };
                })
                : [];


        const columns = [
                { header: 'Sr No', field: 'SrNo' },
                { header: 'Name', field: 'name' },
                { header: 'Mobile', field: 'mobile' },
                { header: 'Created On', field: 'sosRaised' },
                { header: 'Status', field: 'status' },
                { header: 'Location', field: 'audio' },
        ];

        return (
                <div className="pageLayout">
                        <DateRange fromDate={fromDate} toDate={toDate} handleFromChange={handleFromDateChange}
                                handleToChange={handleToDateChange} handleFilter={handleFilter} handleReset={handleReset} />
                        {
                                loading ? (
                                        <div className="text-center">
                                                <div className="spinner-border" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                </div>
                                        </div>
                                ) :
                                        <>
                                                {trackMeData.length > 0 ? <>
                                                        <div className='outer-wrapper mx-auto'>
                                                                <div className='table-wrapper'>
                                                                        <TableData data={trackMeData} columns={columns} />
                                                                </div>
                                                        </div>
                                                        <Pagination
                                                                currentPage={currentPage}
                                                                totalPages={trackMeList?.totalPages}
                                                                onPageChange={handlePageChange}
                                                                onDocsPerPage={handleDocsPerPage}
                                                                docsPerPage={docsPerPage}
                                                        />
                                                </> : <span>No data found</span>}
                                        </>}
                </div>
        )
}

export default TrackmeList