import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
// import * as Icon from "react-bootstrap-icons";
import { Icon } from "@iconify/react";
import StopCommentModel from "../../Component/Common/Model/StopCommentModel";
import { useNavigate } from "react-router-dom";
import SosList from "./SosList";
import ListOfSos from "./ListOfSos";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  setPreviousSos,
  setGlowSos,
  setDontGlowSos,
  setScrollTo,
} from "../Trackme/TrackMeSlice";
import PoliceSosMapView from "../Map/PoliceSosMapView";
import { getAllSosData } from "./SosListSlice";
import io from "socket.io-client";
import { IoMdClose } from "react-icons/io";
import { GiSpeaker, GiPauseButton } from "react-icons/gi";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";

const socket = io("https://server.sps.foxberry.link");


const SosRaise = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // for stopcomment model
  useEffect(() => {
    const endUrl = "/sos/allsos";
    dispatch(setScrollTo(''))
    dispatch(getAllSosData(endUrl));
  }, []);

  const previousSos = useSelector((state) => state.TrackMelistData.previousSos);
  const SosAllDataList = useSelector((state) => state.SosListData.AllSosList);
  const scrollTo = useSelector((state) => state.TrackMelistData.scrollTo)
  const [allsos, setallSOS] = useState([]);
  const [first, setfirst] = useState(0);
  const [glow, setglow] = useState();
  const [getnewsos, setgetnewsosdata] = useState();
  const [showstopcomment, setShowStopComment] = useState(false);
  const dataRef = useRef();
  // const [sosList,setSOS]
  // const [, setState] = useState();
  const [taskId, setTaskId] = useState("");
  // var audio = new Audio('')
  // const [play, setPlay] = useState(false)
  const [audio, setAudio] = useState();
  const [playing, setPlaying] = useState(false);
  const toggle = () => setPlaying(!playing);
  const [sosTimer, setSOSTimer] = useState(false)

  const handlerCloseStopCommentModel = () => setShowStopComment(false);

  const handlerOpenValue = (item) => {
    setTaskId(item);
    setShowStopComment(true);
  };


  useEffect(() => {
    socket.emit("newSOS")

    socket.on("allsos", (data) => {
      // console.log("Socket Log", data)
      setallSOS(data)
    });

    const timer = setTimeout(() => {
      setSOSTimer(true)
    }, 30000)

    return () => {
      clearTimeout(timer)
    }

  }, []);


  const difference = (a, b) => {
    const bIds = {};
    b.forEach((obj) => {
      bIds[obj._id] = obj;
    });

    return a.filter((obj) => !(obj._id in bIds));
  };
  ;

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };


  // console.log("13245", allsos)



  return (
    <div style={{ height: "80vh", marginTop: "65px", marginLeft: "-10px" }}>
      <ToastContainer />
      <Container className="p-0 mt-3">
        <StopCommentModel
          show={showstopcomment}
          close={handlerCloseStopCommentModel}
          data={taskId}
          event={'SOS'}
        />
        <Row className="p-0 m-0">
          <Col md={8}
            style={{ overflowY: "hidden" }}
          >
            {allsos && allsos.length > 0 ? <PoliceSosMapView sosData={allsos} /> : <PoliceSosMapView sosData={allsos} />}
          </Col>

          <Col md={4}
            className="p-0 m-0 h-100"
            style={{ overflowY: "scroll" }}>
            <div style={{ borderBottom: '2px solid #183F91', marginBottom: '10px' }}>
              <h2 className='text-center text'>SOS List</h2>
            </div>
            <div className="cardContainer">
              {allsos !== undefined &&
                allsos !== null &&
                allsos.length > 0 ? (
                allsos.map((itm, index) => {
                  const timestamp = itm?.createdAt;
                  const date = new Date(timestamp);
                  const formattedDate = `${date.getFullYear()}-${(
                    date.getMonth() + 1
                  )
                    .toString()
                    .padStart(2, "0")}-${date
                      .getDate()
                      .toString()
                      .padStart(2, "0")}`;
                  const formattedTime = `${date
                    .getUTCHours()
                    .toString()
                    .padStart(2, "0")}:${date
                      .getUTCMinutes()
                      .toString()
                      .padStart(2, "0")}:${date
                        .getUTCSeconds()
                        .toString()
                        .padStart(2, "0")}`;
                  return (
                    <>
                      <div className="border-bottom  w-100">
                        <Card className="cardBox" key={index}>
                          {/* <Icon
                            icon="basil:cross-outline"
                            className="fs-2 ms-auto"
                          /> */}
                          <Card.Body
                            className="cardBody p-2 rounded pl-4"
                            style={itm?.trackeMeId === scrollTo?.trackeMeId ? { background: "#183F91", color: 'white' } : { background: "#EEEEFF" }}
                          >
                            <div className="d-flex justify-content-end gap-2">

                              {playing ? <GiPauseButton style={itm?.trackeMeId === scrollTo?.trackeMeId ? { color: 'white', fontSize: 20, border: '1px solid white', cursor: 'pointer' } : { fontSize: 20, border: '1px solid black', cursor: 'pointer' }}
                                onClick={() => {
                                  //  toggle() 
                                }} />
                                : itm.audioURL ? <GiSpeaker style={itm?.trackeMeId === scrollTo?.trackeMeId ? { color: 'white', fontSize: 20, border: '1px solid white', cursor: 'pointer' } : { fontSize: 20, border: '1px solid black', cursor: 'pointer' }}
                                  onClick={() => {
                                    // setAudio(new Audio(itm.audioURL));  toggle() 
                                    if (itm.audioURL) {
                                      window.open(itm.audioURL)
                                    } else {
                                      toast.error("Audio File not found")
                                    }
                                  }} /> : null}
                              {sosTimer ?
                                <IoMdClose style={itm?.trackeMeId === scrollTo?.trackeMeId ? { color: 'white', fontSize: 20 } : { fontSize: 20, cursor: 'pointer' }} onClick={() => handlerOpenValue(itm)} />
                                : null}
                            </div>
                            <p>
                              <b>Name :</b>
                              <span className="ms-2">{`${itm?.user?.first_name} ${itm?.user?.last_name}`}</span>
                            </p>
                            <p>
                              <b>Date :</b>
                              <span className="ms-2">{moment(itm.createdAt).format("DD-MMM-YYYY")}</span>
                            </p>
                            <p>
                              <b>Time :</b>
                              <span className="ms-2">{moment(itm.createdAt).format("hh:mm a")}</span>
                            </p>
                            <div className="d-flex align-items-center mt-3 gap-2 flex-wrap">
                              <Button
                                className="primaryBtn"
                                onClick={() =>
                                  openInNewTab(
                                    `https://sps.foxberry.link/trackme?trackingId=${itm?.trackeMeId}`
                                  )
                                }
                              >
                                Direction
                              </Button>
                              {itm?.isAssign === true ? (
                                <Button>Assigned</Button>
                              ) : (
                                <Button
                                  className="primaryBtn"
                                  onClick={() => {
                                    navigate("/admin/assignPolice", {
                                      state: { data: itm },
                                    });
                                  }}
                                >
                                  Assign
                                </Button>
                              )}
                              {
                                itm?.isVolunteersAssign === true ? <Button>Volenteer Assigned</Button> :
                                  <Button
                                    className="primaryBtn"
                                    onClick={
                                      () => {
                                        navigate("/admin/assignVolenteer", {
                                          state: { data: itm },
                                        });
                                      }}
                                  >
                                    Volenteer
                                  </Button>
                              }
                            </div>
                          </Card.Body>
                        </Card>
                      </div >
                    </>
                  );
                })
              ) : (
                <div className="d-flex align-items-center justify-content-center mx-auto">
                  <h5>No Data Found</h5>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div >
  );
};

export default SosRaise;
