import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
    PoliceStation: [],
    PoliceChowki: [],
    PoliceStationUser: [],
    loading: 'idle',
};

export const getPoliceStationList = createAsyncThunk("getPoliceStationDataPromise", async (data, { rejectWithValue }) => {

    const response = await fetch(process.env.REACT_APP_BASE_URL + data, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
    })
    try {
        const result = await response.json()
        return result
    } catch (error) {
        return rejectWithValue(error);
    }
}
)

export const getPoliceChowkiList = createAsyncThunk("getPoliceChowkiDataPromise", async (data, { rejectWithValue }) => {

    const response = await fetch(process.env.REACT_APP_BASE_URL + data, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
    })
    try {
        const result = await response.json()
        return result
    } catch (error) {
        return rejectWithValue(error);
    }
}
)

const PoliceStationSlice = createSlice({
    name: 'policestation',
    initialState,
    // reducers: {
    //     setIncidentList(state, action) {
    //         state.IncidentList = action.payload;
    //     },
    extraReducers: builder => {
        builder
            .addCase(getPoliceStationList.pending, (s, a) => {
                s.loading = 'pending';
            })
            .addCase(getPoliceStationList.rejected, (s, a) => {
                s.loading = 'idle';
            })
            .addCase(getPoliceStationList.fulfilled, (s, a) => {
                s.PoliceStation = a.payload;
                s.loading = 'success';
            })
            .addCase(getPoliceChowkiList.pending, (s, a) => {
                s.loading = 'pending';
            })
            .addCase(getPoliceChowkiList.rejected, (s, a) => {
                s.loading = 'idle';
            })
            .addCase(getPoliceChowkiList.fulfilled, (s, a) => {
                s.PoliceChowki = a.payload;
                s.loading = 'success';
            });
    }
});

export const { } = PoliceStationSlice.actions;
export default PoliceStationSlice.reducer;