import axios from 'axios';
import React, { useEffect, useState } from 'react'
import TableData from '../../Component/Common/Tables';
import moment from 'moment/moment';
import Pagination from '../../Component/Common/Pagination';

export const CheckInOut = () => {
   const [CkeckInOutList, setCheckInOuList] = useState([])

     // state for pagination
    const [docsPerPage, setDocsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    
    // function for pagination
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setCurrentPage(1);
    setDocsPerPage(docsPageProp);
  };

  useEffect(()=>{
    getChekInOutData()
  },[currentPage, docsPerPage])

  const getChekInOutData = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_BASE_URL + `/police-check/check/all/details?page=${currentPage}&documentsPerPage=${docsPerPage}`);

      if(response?.data?.data){
        setCheckInOuList(response?.data?.data)
        console.log("checkinout",response?.data?.data)
        setLoading(false);
      }
      else{
        setCheckInOuList([])
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const CheckInUserData = CkeckInOutList?.data  !== undefined && CkeckInOutList?.data !== null && CkeckInOutList?.data?.length > 0 ?
    CkeckInOutList?.data?.map((itm,index) => {
     return {
      SrNo: currentPage !== 1 ? (<> {index + 1 + docsPerPage * (currentPage - 1)}</>) : <>{index + 1}</>,
      PoliceIdNo: itm?.policeIdNo,
      VehicalDetails: Array.isArray(itm?.vehicle_details) ? itm.vehicle_details.join(', ') : itm?.vehicle_details,
      WeaponDetails:Array.isArray(itm?.weapon_details) ?  itm?.weapon_details.join(' , '): itm?.weapon_details,
      CheckInDate :  moment(itm.inTime).format(' DD-MMM-YYYY hh:mm a '),
      CheckOutDate:itm?.outTime ? moment(itm.inTime).format(' DD-MMM-YYYY hh:mm a ') : " ---"
     };
  })
  : [];

    const columns = [
        { header: 'Sr No', field: 'SrNo' },
        { header: 'Police Id No', field: 'PoliceIdNo' },
        { header: 'Vehical Details', field: 'VehicalDetails' },
        { header: 'Weapon Details', field: 'WeaponDetails' },
        { header: 'CheckIn Date & Time', field: 'CheckInDate' },
        {header: 'CheckOut Date & Time', field: 'CheckOutDate'},
      ];

  return (
    <div className="pageLayout"> {
        loading ? (
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : <>
          {CheckInUserData.length > 0 ? <>
            <div className='outer-wrapper mx-auto'>
              <div className='table-wrapper'>
                <TableData data={CheckInUserData} columns={columns} />
              </div>
            </div>
            <Pagination
              currentPage={currentPage}
              totalPages={CkeckInOutList?.totalPages}
              onPageChange={handlePageChange}
              onDocsPerPage={handleDocsPerPage}
              docsPerPage={docsPerPage}
            />
          </> : <span>No data found</span>}
        </>}

    </div>)
}
