import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
// import * as Icon from "react-bootstrap-icons";
import { Icon } from "@iconify/react";
import StopCommentModel from "../../Component/Common/Model/StopCommentModel";
import PoliceSosMapView from "../Map/PoliceSosMapView";
import { getTrackMeData } from "./TrackMeSlice";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  setPreviousSos,
  setGlowSos,
  setDontGlowSos,
} from "../Trackme/TrackMeSlice";
import { useNavigate } from "react-router-dom";
import io from "socket.io-client";

const socket = io("https://server.sps.foxberry.link");

const TrackMe = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const previousSos = useSelector((state) => state.TrackMelistData.previousSos);
  const trackMeList = useSelector(
    (state) => state?.TrackMelistData?.TrackMeList?.data
  );

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const [alltrackme, setAllTrackMe] = useState([]);
  const [taskId, setTaskId] = useState("");
  // for stopcomment model
  const [showstopcomment, setShowStopComment] = useState(false);
  const handlerCloseStopCommentModel = () => setShowStopComment(false);

  const handlerOpenValue = (id) => {
    setTaskId(id);
    setShowStopComment(true);
  };

  useEffect(() => {
    const endUrl = `/trackme/filtertrackme`;
    dispatch(getTrackMeData(endUrl));
  }, []);

  // useEffect(() => {
  //   getdata();
  // }, []);

  // useEffect(() => {
  //   const intrvl = setInterval(() => {
  //     getdata();
  //   }, 5000);

  //   return () => {
  //     clearInterval(intrvl);
  //   };
  // }, []);

  useEffect(() => {
    socket.emit("getAllTrackMe")

    socket.on("getAllTrackMeData", (data) => {
      console.log("Socket Trackme Log", data)
      setAllTrackMe(data)
    });
  }, []);

  const difference = (a, b) => {
    const bIds = {};
    b.forEach((obj) => {
      bIds[obj._id] = obj;
    });

    return a.filter((obj) => !(obj._id in bIds));
  };

  const getdata = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL + "/trackme/getAllTrackMe",
        {
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
        }
      );
      const data = response.data;
      setAllTrackMe(data);
      dispatch(setPreviousSos(data));

      if (previousSos.length === 0) {
        dispatch(setDontGlowSos(data));
        dispatch(setPreviousSos(data));
      } else if (previousSos.length !== data.length) {
        const newGlowSos = difference(data, previousSos);
        dispatch(setGlowSos(newGlowSos));

        const array = difference(data, newGlowSos);
        dispatch(setDontGlowSos(array));
        dispatch(setPreviousSos(data));
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getTime = (item) => {
    var OneDay = new Date().getTime() + (1 * 24 * 60 * 60 * 1000)
    return OneDay < new Date(item.createdAt)
  }

  return (
    <div style={{ height: "80vh", marginTop: "65px", marginLeft: "-10px" }}>
      <Container className="p-0 mt-3">
        <StopCommentModel
          show={showstopcomment}
          close={handlerCloseStopCommentModel}
          data={taskId}
          event={'trackme'}
        />
        <Row className="p-0 m-0">
          <Col md={8} style={{ overflowY: "hidden" }}>
            {alltrackme.length > 0 ? <PoliceSosMapView sosData={alltrackme} /> : <PoliceSosMapView sosData={alltrackme} />}
          </Col>

          <Col md={4}
            className="p-0 m-0 h-100"
            style={{ overflowY: "scroll" }}
          >
            <div style={{ borderBottom: '2px solid #183F91', marginBottom: '10px' }}>
              <h2 className='text-center text'>Track Me List</h2>
            </div>

            <div className="cardContainer">
              {alltrackme !== null &&
                alltrackme !== undefined &&
                alltrackme?.length > 0 ? (
                alltrackme?.map((itm, index) => {
                  //  for date formate
                  const timestamp = itm?.createdAt; // Assuming itm?.createdAt is "2023-07-07T12:18:01.670Z"
                  const date = new Date(timestamp);
                  const formattedDate = `${date.getFullYear()}-${(
                    date.getMonth() + 1
                  )
                    .toString()
                    .padStart(2, "0")}-${date
                      .getDate()
                      .toString()
                      .padStart(2, "0")}`;
                  const formattedTime = `${date
                    .getUTCHours()
                    .toString()
                    .padStart(2, "0")}:${date
                      .getUTCMinutes()
                      .toString()
                      .padStart(2, "0")}:${date
                        .getUTCSeconds()
                        .toString()
                        .padStart(2, "0")}`;
                  return (
                    <div className="border-bottom w-100">
                      {itm.trackingStatus === 'tracking' && <Card className="cardBox">
                        {/* <Icon icon="basil:cross-outline" className="fs-2 ms-auto" /> */}
                        <Card.Body
                          className="cardBody  p-2 rounded pl-4"
                          style={{ background: "#EEEEFF" }}
                          key={index}
                        >
                          <p>
                            Name
                            <span className="ms-2">{`${itm?.user?.first_name} ${itm?.user?.last_name}`}</span>
                          </p>
                          <p>
                            Date<span className="ms-2">{formattedDate}</span>
                          </p>
                          <p>
                            Time<span className="ms-2">{formattedTime}</span>
                          </p>
                          <div className=" d-flex align-items-center gap-2 mt-3">
                            <Button
                              className="primaryBtn"
                              onClick={() =>
                                openInNewTab(
                                  `https://sps.foxberry.link/trackme?trackingId=${alltrackme[0]?.trackeMeId}`
                                )
                              }
                            >
                              Direction
                            </Button>
                            <Button
                              className="secondaryBtn"
                              onClick={() => handlerOpenValue(itm)}
                            >
                              Stop
                            </Button>
                          </div>
                        </Card.Body>
                      </Card>}
                    </div>
                  );
                })
              ) : (
                <div className="d-flex align-items-center justify-content-center mx-auto">
                  <h5>No Data Found</h5>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default TrackMe;
