import axios from 'axios';
import React, { useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const EditPoliceUser = ({ show, close, data }) => {

    const navigate = useNavigate()

    const [stationName, setStationName] = useState(data.stationName)
    const [division, setDivision] = useState(data?.division)
    const [zone, setZone] = useState(data?.zone)
    const [emailId, setEmailID] = useState(data?.emailId)
    const [contactNo, setContactNo] = useState(data?.telephoneNo)
    const [address, setAddress] = useState(data?.address)
    const [state, setState] = useState(data?.state)
    const [city, setCity] = useState(data?.city)

    const [stationNameError, setStationNameError] = useState(false)
    const [divisionError, setDivisionError] = useState(false)
    const [zoneError, setZoneError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [contactError, setContactError] = useState(false)
    const [addressError, setAddressError] = useState(false)
    const [stateError, setStateError] = useState(false)
    const [cityError, setCityError] = useState(false)

    const udatePoliceUser = () => {

        toast.success("Updated Successfully.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000
        });
    }

    const validate = () => {
        let FormValid = true

        if (stationName.length === 0) {
            FormValid = false
            setStationNameError(true)
        } else if (division.length === 0) {
            FormValid = false
            setDivisionError(true)
        } else if (zone.length === 0) {
            FormValid = false
            setZoneError(true)
        } else if (emailId.length === 0) {
            FormValid = false
            setEmailError(true)
        }
        else if (contactNo.length === 0) {
            FormValid = false;
            setContactError(true);
        } else if (contactNo.length !== 10) {
            FormValid = false;
            setContactError(true);
        } else if (address.length === 0) {
            FormValid = false
            setAddressError(true)
        } else if (state.length === 0) {
            FormValid = false
            setStateError(true)
        } else if (city.length === 0) {
            FormValid = false
            setCityError(true)
        }

        return FormValid
    }

    const updatePoliceUser = (e) => {
        e.preventDefault();
        if (validate()) {
            axios.post(process.env.REACT_APP_BASE_URL + "/policestation/updatepolicestation", {
                stationId: data.stationId,
                stationName: stationName,
                division: division,
                zone: zone,
                address: address,
                telephoneNo: contactNo,
                emailId: emailId,
                city: city,
                state: state
            })
                .then((res) => {
                    udatePoliceUser()
                    navigate("/admin/policeStationList")
                    close()
                })
                .catch((error) => {
                    console.log("error", error);
                })
        }
    }

    return (
        <div className='pageLayout'>
            <ToastContainer />
            <Modal show={show} onHide={close} size="lg" centered backdrop='static' keyboard={false}>
                <Modal.Header closeButton className="mngmodelheader">
                    <Modal.Title><h5 style={{ fontWeight: "600" }}>Edit Police User</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body size="lg">
                    <Row className='h-100 justify-content-center align-items-center px-3'>
                        <Row>
                            <Col md={12} className='pe-4 ps-4 ps-md-2'>
                                <Form>
                                    <Row className='gy-3 mt-1'>
                                        <Col md={12}>
                                            <Form.Label>Station Name<span style={{ color: "red" }}>*</span></Form.Label>
                                            <Form.Control
                                                placeholder='Enter Station name'
                                                name='stationName'
                                                value={stationName}
                                                onChange={(e) => {
                                                    setStationName(e.target.value);
                                                    e.target.value.length > 0 ?
                                                        setStationNameError(false) :
                                                        setStationNameError(true)
                                                }}
                                            />
                                            {stationNameError ? <span style={{ color: "red" }}>Field is required</span> : ""}
                                        </Col>

                                        <Col md={6}>
                                            <Form.Label>Division<span style={{ color: "red" }}>*</span></Form.Label>
                                            <Form.Control
                                                placeholder='Enter Division'
                                                name="division"
                                                value={division}
                                                onChange={(e) => {
                                                    setDivision(e.target.value);
                                                    e.target.value.length > 0 ?
                                                        setDivisionError(false) :
                                                        setDivisionError(true)
                                                }}
                                            />
                                            {divisionError ? <span style={{ color: "red" }}>Field is required</span> : ""}
                                        </Col>

                                        <Col md={6}>
                                            <Form.Label>Zone<span style={{ color: "red" }}>*</span></Form.Label>
                                            <Form.Control
                                                placeholder='Enter Zone'
                                                name="lastName"
                                                value={zone}
                                                onChange={(e) => {
                                                    setZone(e.target.value);
                                                    e.target.value.length > 0 ?
                                                        setZoneError(false) :
                                                        setZoneError(true)
                                                }}
                                            />
                                            {zoneError ? <span style={{ color: "red" }}>Field is required</span> : ""}
                                        </Col>

                                        <Col md={6}>
                                            <Form.Label>Email ID <span style={{ color: "red" }}>*</span></Form.Label>
                                            <Form.Control
                                                placeholder='Enter Email'
                                                name="emailId"
                                                value={emailId}
                                                onChange={(e) => {
                                                    setEmailID(e.target.value);
                                                    e.target.value.length > 0 ?
                                                        setEmailError(false) :
                                                        setEmailError(true)
                                                }}
                                            />
                                            {emailError ? <span style={{ color: "red" }}>Field is required</span> : ""}
                                        </Col>

                                        <Col md={6}>
                                            <Form.Label>Contact No.<span style={{ color: "red" }}>*</span></Form.Label>
                                            <Form.Control
                                                placeholder='+91'
                                                value={contactNo}
                                                name="contactNo"
                                                onChange={(e) => {
                                                    if (e.target.value.match(/^\d{0,10}(\.\d{0,9})?$/) && e.target.value.trim()) {
                                                        setContactNo(e.target.value)
                                                    } else if (e.target.value.length === 0) {
                                                        setContactNo(e.target.value)
                                                    }
                                                    // e.target.value.length > 0 ?
                                                    //     setContactError(false) :
                                                    //     setContactError(true)
                                                    setContactError(e.target.value.length > 0 && e.target.value.length !== 10);
                                                }}
                                            />
                                            {contactError && contactNo.length === 0 ? (
                                                <p className='errMsg'>* Required Field</p>
                                            ) : contactError && contactNo.length !== 10 ? (
                                                <p className='errMsg'>* Enter 10 digit mobile number</p>
                                            ) : null}
                                        </Col>

                                        <Col md={12}>
                                            <Form.Label>Address<span style={{ color: "red" }}>*</span></Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                placeholder='Street Address'
                                                name="address"
                                                value={address}
                                                onChange={(e) => {
                                                    setAddress(e.target.value);
                                                    e.target.value.length > 0 ?
                                                        setAddressError(false) :
                                                        setAddressError(true)
                                                }}
                                            />
                                            {addressError ? <span style={{ color: "red" }}>Field is required</span> : ""}
                                        </Col>

                                        <Col md={6}>
                                            <Form.Label>State<span style={{ color: "red" }}>*</span></Form.Label>
                                            <Form.Control placeholder='State' name="state"
                                                value={state}
                                                onChange={(e) => {
                                                    setState(e.target.value);
                                                    e.target.value.length > 0 ?
                                                        setStateError(false) :
                                                        setStateError(true)
                                                }}
                                            />
                                            {stateError ? <span style={{ color: "red" }}>Field is required</span> : ""}
                                        </Col>

                                        <Col md={6}>
                                            <Form.Label>City<span style={{ color: "red" }}>*</span></Form.Label>
                                            <Form.Control placeholder='City' name="city"
                                                value={city}
                                                onChange={(e) => {
                                                    setCity(e.target.value);
                                                    e.target.value.length > 0 ?
                                                        setCityError(false) :
                                                        setCityError(true)
                                                }}
                                            />
                                            {cityError ? <span style={{ color: "red" }}>Field is required</span> : ""}
                                        </Col>
                                    </Row>

                                    <Row className='mt-5 mb-3'>
                                        <Col className='d-flex justify-content-center'>
                                            <Button className="submit-Btn px-5" type="submit"
                                                onClick={(e) => updatePoliceUser(e)}
                                            >
                                                Update
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </Row>
                </Modal.Body>
            </Modal>
        </div>
    )
}
