import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { BiArrowFromBottom } from 'react-icons/bi'
import SlideDrawer from '../Cybercrime/SlideDrawer'
import TableData from '../../Component/Common/Tables'
import { BsDot, BsFillArrowRightCircleFill } from 'react-icons/bs'
import Backdrops from '../Volunteer/BackDrops'
import { useDispatch, useSelector } from 'react-redux';
import * as XLSX from "xlsx";
import { getIncidentList } from './IncidentListSlice'
import Pagination from '../../Component/Common/Pagination'

const IncidentLIst = () => {

  const dispatch = useDispatch()
  const IncidentListData = useSelector(state => state?.IncidentListData?.IncidentList)

  // state for pagination
  const [docsPerPage, setDocsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);

  // usestate for drawer
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);

  // state for search data
  const [combinedSearch, setCombinedSearch] = useState('');


  // function for handler search
  const handleCombinedSearchChange = (e) => {
    setCombinedSearch(e.target.value);
  };


  // function for pagination
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setCurrentPage(1);
    setDocsPerPage(docsPageProp);
  };

  // functiom for drawer
  const drawerToggleClickHandler = (rowData) => {
    // setDrawerOpen(!drawerOpen);
    setDrawerOpen(true);
    setSelectedRowData(rowData);
  };

  // for api calling
  useEffect(() => {
    const endUrl = `/reportincident/getallreportincident?page=${currentPage}&documentsPerPage=${docsPerPage}`
    dispatch(getIncidentList(endUrl))
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  }, [currentPage, docsPerPage])


  const IncidentData = IncidentListData?.data !== undefined && IncidentListData?.data !== null && IncidentListData?.data?.length > 0 ?
    IncidentListData?.data?.map((itm) => {
      const createdAtDate = new Date(itm.createdAt);

      const formattedDate = createdAtDate.toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      });

      const formattedTime = createdAtDate.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      });

      return {
        CaseNumber: itm.reportincidentid,
        Name: itm.first_name + " " + itm.last_name,
        incident: itm.incidentType,
        address: itm.address,
        reportTimeAndDate: `${formattedDate} ${formattedTime}`,
        action: <BsFillArrowRightCircleFill style={{ color: "#5D83D4" }} size={22} title='View'
          onClick={() => { drawerToggleClickHandler(itm) }} />,
      };
    })
    : [];

  const columns = [
    { header: 'Case Number', field: 'CaseNumber' },
    { header: 'Name', field: 'Name' },
    { header: 'Incident', field: 'incident' },
    { header: 'Address', field: 'address' },
    { header: 'Report Time & Date', field: 'reportTimeAndDate' },
    { header: 'Action', field: 'action' },
  ];

  // for serach data

  const filteredData = IncidentData.filter((row) => {
    const searchData = combinedSearch.toLowerCase();
    const nameMatch = row.Name.toLowerCase().includes(searchData);
    const incidentMatch = row.incident.toLowerCase().includes(searchData);

    return nameMatch || incidentMatch;
  });

  const backdropClickHandler = () => {
    setDrawerOpen(false);
  };

  let backdrop = null;
  if (drawerOpen) {
    backdrop = <Backdrops close={backdropClickHandler} />;
  }

  // excel export code
  const [sheetLoader, setSheetLoader] = useState(false);

  const downloadExcel = (customHeadings) => {
    let dataSet = [];
    dataSet = customHeadings;
    const worksheet = XLSX.utils.json_to_sheet(dataSet);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "Incident-Report.xlsx");
    setSheetLoader(false);
  };

  const CustomExcel = () => {
    setSheetLoader(true);
    if (IncidentListData.data !== undefined && IncidentListData.data.length > 0) {
      const customHeadings = IncidentListData.data.map((data) => {
        const createdAtDate = new Date(data.createdAt); // Fixed "itm" to "data"
        const formattedDate = createdAtDate.toLocaleDateString('en-US', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        });

        const formattedTime = createdAtDate.toLocaleTimeString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        });

        return {
          "Case Number": data?.reportincidentid ?? "-",
          "Name": data.first_name + " " + data.last_name ?? "-",
          "Incident": data.incidentType ?? "-",
          "Address": data.address ?? "-",
          "Report Date": `${formattedDate}` ?? "",
          "Report Time": `${formattedTime}` ?? ""
        };
      });
      downloadExcel(customHeadings);
    } else {
      console.error("No data available for Excel export.");
    }
  };


  return (
    <div className="pageLayout">

      <Row className='d-flex flex-column-reverse flex-md-row justify-content-between align-items-center  mt-5'>
        <Col md={4} className='mt-4 md-mb-0 pb-2'>
          <Form.Group>
            <Form.Control type="search" value={combinedSearch} onChange={handleCombinedSearchChange} placeholder="Search by Name or Incident" />
          </Form.Group>
        </Col>

        <Col md={6} className='d-flex justify-content-end mt-2 md-mt-0'>
          <Button className='primaryBtn ms-auto' onClick={() => CustomExcel()}>
            <span><BiArrowFromBottom style={{ fontSize: "20px" }} title='Excel export' />Excel export</span>
          </Button>
        </Col>
      </Row>
      {/* <Row>
        <div style={{ display: "flex", padding: "20px" }}>
          <span className='low'><BsDot className="low-icon" />Low</span>
          <span className='medium'><BsDot className='medium-icon' />Medium</span>
          <span className='high' ><BsDot className="high-icon" />High</span>
        </div>
      </Row> */}
      <SlideDrawer show={drawerOpen} title="Incident Crime" incidentData={selectedRowData} />
      {backdrop}
      {
        loading ? (
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : <>
          {filteredData.length > 0 ? <>
            <div className='outer-wrapper mx-auto'>
              <div className='table-wrapper'>
                <TableData data={filteredData} columns={columns} />
              </div>
            </div>
            <Pagination
              currentPage={currentPage}
              totalPages={IncidentListData?.totalPages}
              onPageChange={handlePageChange}
              onDocsPerPage={handleDocsPerPage}
              docsPerPage={docsPerPage}
            />
          </> : <span>No data found</span>}
        </>
      }

    </div>
  )
}

export default IncidentLIst