import React from 'react';
import '../Cybercrime/Backdrop.css';

function Backdrops(props) {
   return (
      <div
         className="backdrop"
         onClick={props.close}
      />
   );
}

export default Backdrops;
