import React, { useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import Select from 'react-select';

const AddChowki = ({show,close,data}) => {
    const stationTypeOptions = [{ value: 'rural', label: 'Rural' }, { value: 'arban', label: 'Arban' }];
    const [stationType, setstationType] = useState("");

    const navigate = useNavigate();

     // state for new user
    const [addchowki, setAddChowki] = useState({
        registartionNo:"",
        stationName: "",
        chowkiName: "",
        division: "",
        zone: "",
        emailId:"",
        telephoneNo: "",
        address: "",
        state: "",
        city: "",
    })

    // error handling
    const [registrationnoerr, setRegistrationNoErr] = useState(false)
    const [chowkianmeerr, setChowkiNameErr] = useState(false)
    const [stationtypeerr, setStationTypeErr] = useState(false)
    const [emailerr, setEmailErr] = useState(false)
    const [telephoneerr, setTelephoneErr] = useState(false)
    const [addresserr, setAddressErr] = useState(false)

    // function for error handling
    const handlerValidate = () => {
        let FormValid = true;

        if (addchowki.registartionNo.length === 0) {
            FormValid = false;
            setRegistrationNoErr(true);
        }
        else if (addchowki.chowkiName.length === 0) {
            FormValid = false;
            setChowkiNameErr(true);
        }
        else if (stationType.length === 0) {
            FormValid = false;
            setStationTypeErr(true);
        }
        else if (addchowki.emailId.length === 0) {
            FormValid = false;
            setEmailErr(true);
        }else if (addchowki.telephoneNo.length === 0) {
            FormValid = false;
            setTelephoneErr(true);
        } else if (addchowki.telephoneNo.length !== 10) {
            FormValid = false;
            setTelephoneErr(true);
        } 
        else if (addchowki.emailId.length === 0) {
            FormValid = false;
            setEmailErr(true);
        }
        else if (addchowki.telephoneNo.length === 0) {
            FormValid = false;
            setTelephoneErr(true);
        }
        else if (addchowki.address.length === 0) {
            FormValid = false;
            setAddressErr(true);
        }
        return FormValid;
    };


     const handleInput = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setAddChowki({ ...addchowki, [name]: value })
    }

    const handlerAddChowki = async (e) => {
        setRegistrationNoErr(false)
        setChowkiNameErr(false)
        setStationTypeErr(false)
        setEmailErr(false)
        setTelephoneErr(false)
        setAddressErr(false)

        if(handlerValidate()){
            await fetch(process.env.REACT_APP_BASE_URL + "/chowki/insertChowki", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "stationId":data?.stationId,
                "registartionNo":addchowki?.registartionNo,
                "stationName":data?.stationName,
                "chowkiName":addchowki.chowkiName,
                "division":data?.division,
                "zone":data?.zone,
                "address":addchowki.address,
                "contactNo":addchowki.telephoneNo,
                "emailId":addchowki.emailId,
                "city":data?.city,
                "state":data?.state
            })
        })
            .then((res) => res.json())
            .then((response) => {
                close()
                addchowki.registartionNo("")
                addchowki.chowkiName("")
                stationType("")
                addchowki.emailId("")
                addchowki.telephoneNo("")
                addchowki.address("")
            })
            .catch((err) => {
                console.log("err", err);
            })

        }
        
    }
  return (
    <div>
      <Modal show={show} onHide={close} size="lg" centered backdrop='static' keyboard={false}>
      <Modal.Header closeButton className="mngmodelheader">
        <Modal.Title><h5>Add Chowki</h5></Modal.Title>
      </Modal.Header>
      <Modal.Body size="lg">
        <Row className='h-100 justify-content-center align-items-center px-5'>
            <Row>
                <Col md={12} className='pe-4 ps-4 ps-md-2'>
                    <Form>
                        <Row>
                            <Col className='fw-bold fs-3 text-center'>Police Chowki</Col>
                        </Row>
                        <Row className='gy-3 mt-2'>
                            <Col md={4}>
                                <Form.Label>Registration No *</Form.Label>
                                <Form.Control placeholder='* Registration No' value={addchowki.registartionNo} name="registartionNo" 
                                 onChange={(e) => {
                                    if (
                                        e.target.value.match(
                                        /^[0-9]*(\.[0-9]*)?$/) &&
                                        e.target.value.trim()) {
                                        handleInput(e);
                                        } else if (e.target.value.length === 0) {
                                        handleInput(e);}
                                        addchowki.registartionNo .length >= 0
                                        ? setRegistrationNoErr(false)
                                                    : setRegistrationNoErr(true);
                                            }} />
                                        {registrationnoerr ? (<p className="errMsg">* Required Field</p>) : null}
                            </Col>
                            <Col md={4}>
                                <Form.Label>Police Chowki Name *</Form.Label>
                                <Form.Control placeholder='* Police Chowki Name' value={addchowki.chowkiName} name="chowkiName"
                                 onChange={(e) => {
                                                if (
                                                    !e.target.value.match(
                                                        // /[+@#$&%!~=^_:{}\[\]*|//\-/?<>,;`'""/\\]/
                                                        /[#$&%!~=^_{}\[\]*|//\/?<>;`/\\]/
                                                    ) &&
                                                    e.target.value.trim()
                                                ) {
                                                    handleInput(e);
                                                } else if (e.target.value.length === 0) {
                                                    handleInput(e);
                                                }
                                                addchowki.chowkiName.length >= 0
                                                    ? setChowkiNameErr(false)
                                                    : setChowkiNameErr(true);
                                            }} />
                                        {chowkianmeerr ? (<p className="errMsg">* Required Field</p>) : null} 
                            </Col>

                            <Col md={4}>
                                <Form.Label>Station Type *</Form.Label>
                                <Select
                                    isMulti={false}
                                    isSearchable={true}
                                    value={stationType}
                                    options={stationTypeOptions}
                                     onChange={(option) => {
                                                setstationType(option);
                                                stationType.length < 0
                                                    ? setStationTypeErr(true)
                                                    : setStationTypeErr(false);
                                            }}
                                        />
                                    {stationtypeerr ? (<p className="errMsg">* Required Field</p>) : null}
                            </Col>

                            <Col md={6}>
                                <Form.Label>Division</Form.Label>
                                <Form.Control placeholder='* Division' value={data?.division}  readOnly />
                            </Col>

                            <Col md={6}>
                                <Form.Label>Zone</Form.Label>
                                <Form.Control placeholder='* Zone' value={data?.zone} readOnly />
                            </Col>

                            <Col md={6}>
                                <Form.Label>Email *</Form.Label>
                                <Form.Control placeholder='Name@gmail.com' value={addchowki.emailId} name="emailId" 
                                 onChange={(e) => {handleInput(e)
                                        addchowki.emailId.length >= 0
                                        ? setEmailErr(false)
                                        : setEmailErr(true);
                                    }} />
                                {emailerr ? (<p className="errMsg">* Required Field</p>) : null}
                            </Col>

                            <Col md={6}>
                                <Form.Label>Telephone No *</Form.Label>
                                <Form.Control placeholder='+91' value={addchowki.telephoneNo} name="telephoneNo" 
                                onChange={(e) => {
                                    if (
                                     !e.target.value.match(/[A-Za-z+@#$&%!~=*./\s/g/\-/?<>,"/]/) && e.target.value.length <= 10) {
                                      handleInput(e)}
                                      setTelephoneErr(e.target.value.length > 0 && e.target.value.length !== 10);
                                    //   addchowki.telephoneNo.length < 0 ? setTelephoneErr(true) : setTelephoneErr(false)
                                    }} />
                                    {telephoneerr && addchowki.telephoneNo.length === 0 ? (
                                                    <p className='errMsg'>* Required Field</p>
                                                ) : telephoneerr && addchowki.telephoneNo.length !== 10 ? (
                                                    <p className='errMsg'>* Enter 10 digit mobile number</p>
                                                ) : null}
                            </Col>

                            <Col md={12}>
                                <Form.Label>Address *</Form.Label>
                                <Form.Control as="textarea" placeholder='Street Address' value={addchowki.address} name="address" 
                                onChange={(e) => {
                                                if (
                                                    !e.target.value.match(
                                                        /[#$&%!~=^_{}\[\]*|//\/?<>;`/\\]/
                                                    ) &&
                                                    e.target.value.trim()
                                                ) {
                                                    handleInput(e);
                                                } else if (e.target.value.length === 0) {
                                                    handleInput(e);
                                                }
                                                addchowki.address.length >= 0
                                                    ? setAddressErr(false)
                                                    : setAddressErr(true);
                                            }} />
                                        {addresserr ? (<p className="errMsg">* Required Field</p>) : null}
                            </Col>

                            <Col md={6}>
                                <Form.Control placeholder='State' value={data?.state} readonly />
                            </Col>

                            <Col md={6}>
                                <Form.Control placeholder='City' value={data?.city} readonly />
                            </Col>
                        </Row>
                    </Form>

                    <Row className='mt-5 mb-3'>
                        <Col className='d-flex justify-content-center'>
                            <Button className="submit-Btn px-5" onClick={() => handlerAddChowki()}>Add Chowki</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Row>
      </Modal.Body>
    </Modal>
    </div>
  )
}

export default AddChowki
