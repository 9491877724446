import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import axios from 'axios'
import io from "socket.io-client";

const socket = io("https://server.sps.foxberry.link");


const StopCommentModel = ({show, close,data,event}) => {

    const[stop,setStop] = useState('')
    const[stopdata,setStopData] = useState({
        trackeMeId:'',
        StopComment:'',
        userId:'',
    })

    const handleInput = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setStopData({ ...stopdata, [name]: value })
    }

    const handlerSubmit = async() => {
        console.log(event)
        const payload = {
            trackeMeId:data.trackeMeId,
            StopComment:stopdata.StopComment,
            userId:data.user.userId,
        }
        let link = event !== "SOS" ? "/trackme/trackme/stop" : "/sos/stop/status"
        await axios.put(process.env.REACT_APP_BASE_URL + link ,payload)
            .then((res)=>{
                console.log("STOP res",res)
                socket.emit('asktrackmeId', {
                    uuid: `SafetyIndex/${data.trackeMeId}`,
                  });
                   event !== "SOS" ? socket.emit("getAllTrackMe") : socket.emit("newSOS")

                  if(res.data.assignedPoliceId){
                    socket.emit('getPoliceUserTrackMe', {
                        uuid: `getPoliceUserTrackMe/${res.data.assignedPoliceId}`,
                      });
                  }

                setStopData({
                    ...stopdata,
                    StopComment : ''
                })
                close()
            })
            .catch((err)=>{
                console.log("error from api",err);
            })
    }

  return (
    <>
        <Modal
            show={show}
            onHide={close}
            size="md" centered backdrop="static" keyboard={false} contentClassName="custom-modal-content"
            dialogClassName="custom-modal-dialog">
            <Modal.Header closeButton>
                <Modal.Title>Stop Comment</Modal.Title>
            </Modal.Header>
            <Modal.Body className='pb-0'>
                <Row>
                    <Col md={12} className='mb-3'>
                        <Form.Group>
                            <Form.Control
                                as = "textarea"
                                placeholder="Give Reason.."
                                value = {stopdata.StopComment}
                                name = "StopComment"
                                onChange={(e) =>handleInput(e) }
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className='border-0 m-0 pt-0' >
                <Button className="submit-Btn" type = "submit" onClick = {() => handlerSubmit()} disabled = {stopdata.StopComment === ""}> Submit </Button>
            </Modal.Footer>
        </Modal>
    </>
  )
}

export default StopCommentModel
