import React from 'react'
import { Button, ButtonGroup, Col, Row, Table, ToggleButton } from "react-bootstrap";

const TableData = ({ data = [], columns }) => {

  return (
    <div>
      <Table hover size="sm" className='m-1'>
        <thead>
          <tr style={{ borderBottom: "1px solid #183F91",textAlign:'center' }}>
            {columns?.map((column, index) => (
              <th  key={index}>{column.header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Array.isArray(data) && data.length > 0 ? (
            data.map((item, rowIndex) => (
              <tr key={rowIndex} style={{ textAlign:'center' }}>
                {columns ? (
                  columns.map((column, colIndex) => (
                    <td style={{ padding: "0.75rem", fontSize: '13px' }} key={colIndex}>
                      {item[column.field]}
                    </td>
                  ))
                ) : (
                  <td>No Data Found</td>
                )}
              </tr>
            ))
          ) : (
            <tr>
              <td colspan="{columns?.length || 5}">No Data Found</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  )
}

export default TableData

