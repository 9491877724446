// import { Icon } from '@iconify/react';
import * as Icon from "react-bootstrap-icons";
import React, { useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import TableData from '../../Component/Common/Tables';
import Select from 'react-select'
import Pagination from '../../Component/Common/Pagination';
import axios from 'axios';
import AddNewPoliceUser from './AddNewPoliceUser';
import fileImage from "../../assets/docs 1.png";
import { FileEarmarkArrowDown, FileEarmarkArrowDownFill } from 'react-bootstrap-icons';
import { AddExcelFileModel } from './AddExcelFileModel';

const PoliceStationPoliceLIst = () => {
  const navigate = useNavigate()
  const [policeUser, setPoliceUser] = useState()
  const [showAddUserModel, setShowAddUserModel] = useState(false)
  const [showAddExcelModel, setShowAddExcelModel] = useState(false)
  const [taskId, setTaskId] = useState("")

  // state for pagination
  const [docsPerPage, setDocsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);

  const { state } = useLocation();
  const result = state?.data;

  // function for pagination
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setCurrentPage(1);
    setDocsPerPage(docsPageProp);
  };

  useEffect(() => {
    getPoliceStationUserList()
  }, [result, currentPage, docsPerPage])

  const getAllData = () => {
    getPoliceStationUserList()
  }

  const getPoliceStationUserList = async () => {
    const payload = {
      policeStationId: result
    };
    try {
      await axios.post(
        process.env.REACT_APP_BASE_URL + '/policestationuser/getpolicestationuser',
        payload
      )
        .then((res) => {
          setPoliceUser(res?.data)
        })
      setLoading(false)
    } catch (err) {
      console.log("Error", err);
    }
  };


  const handleAddUserModelClose = (id) => {
    setTaskId(id);
    setShowAddUserModel(false);
    // getPoliceStationUserList();
  }

  const handleAddUserModelOpen = (id) => {
    setTaskId(id);
    setShowAddUserModel(true)
  }

  const handleAddExcelModel = () => {
    setShowAddExcelModel(true)
  }

  const handleCloseExcelModel = () => {
    setShowAddExcelModel(false)
  }

  const PoliceStationUserData = policeUser?.data !== undefined && policeUser?.data !== null &&
    policeUser?.data?.length > 0 && Array.isArray(policeUser?.data) ? policeUser?.data?.map((itm, index) => {
      return {
        // CaseNumber: currentPage !== 1 ? (<> {index + 1 + docsPerPage * (currentPage - 1)}</>) : <>{index + 1}</>,
        uidNo: itm?.unit,
        adharNo: itm?.adharNo,
        rank: itm?.rank,
        name: itm?.firstName + " " + itm?.lastName,
        email: itm?.emailId,
        mobile: itm?.contactNo,
        role: itm?.policeRole,
        action: (<>
          <img src={fileImage} title='View & Edit' onClick={() => {
            navigate("/admin/policeUseSingleData", { state: { data: itm } })
          }} />
        </>)
      };
    })
    : "No Data Found";



  const columns = [
    { header: 'UId No', field: 'uidNo' },
    { header: 'Adhar No', field: 'adharNo' },
    { header: 'Rank', field: 'rank' },
    { header: 'Name', field: 'name' },
    { header: 'Email', field: 'email' },
    { header: 'Mobile', field: 'mobile' },
    { header: 'Role', field: 'role' },
    { header: 'Action', field: 'action' },
  ];

  const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
  ]

  return (
    <div className="pageLayout">
      <AddNewPoliceUser show={showAddUserModel} close={handleAddUserModelClose} data={getAllData} />
      <AddExcelFileModel show={showAddExcelModel} close={handleCloseExcelModel} getPoliceStationUserList={getPoliceStationUserList} policeStationId={result} />
      <Row className='justify-content-between'>
        <Col md={2} className='mb-4 mb-md-0'>
          <Button className='primaryBtn' onClick={() => navigate(-1)}>
            Back
          </Button>
        </Col>

        <Col md={8} ms={2} className='d-flex justify-content-end'>
          <Button className='primaryBtn' onClick={() => { handleAddUserModelOpen(result) }}>
            <Icon.Person style={{ fontSize: "18px", marginRight: "6px" }} icon="gg:add" />
            Add Police Station User
          </Button>

          <Button className='primaryBtn ms-2' onClick={() => { handleAddExcelModel() }}>
            <Icon.People style={{ fontSize: "18px", marginRight: "6px" }} icon="gg:add" />
            Add Multiple Police Station User
          </Button>
        </Col>
      </Row>

      {
        loading ? (
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div className='outer-wrapper mx-auto'>
            <div className='table-wrapper'>
              <TableData data={PoliceStationUserData} columns={columns} />
            </div>
          </div>

        )
      }
      <Pagination
        currentPage={currentPage}
        totalPages={policeUser?.totalPages}
        onPageChange={handlePageChange}
        onDocsPerPage={handleDocsPerPage}
        docsPerPage={docsPerPage}
      />
    </div>
  )
}
export default PoliceStationPoliceLIst