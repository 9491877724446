import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
    IncidentList: [],
    loading: 'idle',
};

export const getIncidentList = createAsyncThunk("getIncidentDataPromise", async (data, { rejectWithValue }) => {

    const response = await fetch(process.env.REACT_APP_BASE_URL + data, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
    })
    try {
        const result = await response.json()
        return result
    } catch (error) {
        return rejectWithValue(error);
    }
}
)
const IncidentSlice = createSlice({
    name: 'incidentlist',
    initialState,
    // reducers: {
    //     setIncidentList(state, action) {
    //         state.IncidentList = action.payload;
    //     },
    extraReducers: builder => {
        builder
            .addCase(getIncidentList.pending, (s, a) => {
                s.loading = 'pending';
            })
            .addCase(getIncidentList.rejected, (s, a) => {
                s.loading = 'idle';
            })
            .addCase(getIncidentList.fulfilled, (s, a) => {
                s.IncidentList = a.payload;
                s.loading = 'success';
            });
    }
});



export const { } = IncidentSlice.actions;
export default IncidentSlice.reducer;