import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setActiveKey, setActivePage } from '../Component/Sidebar/sidebarSlice'
import carImg from "../assets/card_img.png"
import { ArrowRight, ArrowRightShort } from 'react-bootstrap-icons'

const Dashboard = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userState = useSelector(state => state.loginData.userData);

  const [allData, setAllData] = useState([])
  useEffect(() => {
    getAllCount()
  }, [])

  const getAllCount = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_BASE_URL + "/dashboard/get/all/count")
      setAllData(response.data)
    }
    catch (error) {
      console.log("error", error);
    }
  }

  return (
    <>
      <Container style={{ marginTop: "75px" }}>
        <Row className='w-100 gy-3 gy-md-0 d-flex justify-content-center'>
          <Row className='gy-4'>
            <Col sx={6} md={6} lg={3}>
              <div className="main-div" onClick={() => { navigate("/admin/incidentCategory");dispatch(setActiveKey({ activeKey: 1, activeTab: 'Incident Category' })) }}>

                <div className='cardContent'>
                  <h5 className='cardHeading'>Incident Category</h5>
                  <h3 className='cordContent mb-3'>{allData?.incident}</h3>
                  <ArrowRight size={24} className='cardIcon' />
                </div>
                <div className='cardImg'>
                  <img src={carImg} className='cardsmallimg' />
                </div>
              </div>
            </Col>
            <Col sx={6} md={6} lg={3}>
              <div className="main-div" onClick={() => { navigate("/admin/CyberCrimeCategory");dispatch(setActiveKey({ activeKey: 1, activeTab: 'Cyber Crime Category' })) }}>
                <div className='cardContent'>
                  <h5 className='cardHeading'>Cyber Crime Category</h5>
                  <h3 className='cordContent mb-3'>{allData?.cyber_crime_categories}</h3>
                  <ArrowRight size={24} className='cardIcon' />
                </div>
                <div className='cardImg'>
                  <img src={carImg} className='cardsmallimg' />
                </div>
              </div>
            </Col>

            <Col xs={12} sm={6} md={6} lg={4} xl={4} xxl={3}>
              <div className="main-div" onClick={() => { navigate("/admin/trackeme"); dispatch(setActiveKey({ activeKey: 3, activeTab: 'Trackme' })) }}>
                <div className='cardContent'>
                  <h5 className='cardHeading'>Track Me</h5>
                  <h3 className='cordContent mb-3'>{allData?.trackme}</h3>
                  <ArrowRight size={24} className='cardIcon' />
                </div>
                <div className='cardImg'>
                  <img src={carImg} className='cardsmallimg' />
                </div>
              </div>
            </Col>

            <Col xs={12} sm={6} md={6} lg={4} xl={4} xxl={3}>
              <div className="main-div" onClick={() => { navigate("/admin/sosRaise"); dispatch(setActiveKey({ activeKey: 2, activeTab: 'SOS Raised' })) }}>
                <div className='cardContent'>
                  <h5 className='cardHeading'>All SOS</h5>
                  <h3 className='cordContent mb-3'>{allData?.sos}</h3>
                  <ArrowRight size={24} className='cardIcon' />
                </div>
                <div className='cardImg'>
                  <img src={carImg} className='cardsmallimg' />
                </div>
              </div>
            </Col>

            <Col xs={12} sm={6} md={6} lg={4} xl={4} xxl={3}>
              <div className="main-div" onClick={() => { navigate("/admin/policeStationList"); dispatch(setActiveKey({ activeKey: 5, activeTab: 'Police' })) }}>
                <div className='cardContent'>
                  <h5 className='cardHeading'>Police Station</h5>
                  <h3 className='cordContent mb-3'>{allData?.police_station}</h3>
                  <ArrowRight size={24} className='cardIcon' />
                </div>
                <div className='cardImg'>
                  <img src={carImg} className='cardsmallimg' />
                </div>
              </div>
            </Col>

            <Col sx={6} md={6} lg={3} >
              <div className="main-div" onClick={() => { navigate("/admin/UserList"); dispatch(setActiveKey({ activeKey: 1, activeTab: 'User List' })) }}>
                <div className='cardContent'>
                  <h5 className='cardHeading'>User</h5>
                  <h3 className='cordContent mb-3'>{allData?.user}</h3>
                  <ArrowRight size={24} className='cardIcon' />
                </div>
                <div className='cardImg'>
                  <img src={carImg} className='cardsmallimg' />
                </div>
              </div>
            </Col>

            <Col sx={6} md={6} lg={3}>
              <div className="main-div" onClick={() => { navigate("/admin/incident"); dispatch(setActiveKey({ activeKey: 6, activeTab: 'Report Incident' })) }}>
                <div className='cardContent'>
                  <h5 className='cardHeading'>Report Incident</h5>
                  <h3 className='cordContent mb-3'>{allData?.report_incident}</h3>
                  <ArrowRight size={24} className='cardIcon' />
                </div>
                <div className='cardImg'>
                  <img src={carImg} className='cardsmallimg' />
                </div>
              </div>
            </Col>

            <Col xs={12} sm={6} md={6} lg={4} xl={4} xxl={3}>
              <div className="main-div" onClick={() => { navigate("/admin/ckeckIn-Out") }}>
                <div className='cardContent'>
                  <h5 className='cardHeading'>CheckIn CheckOut</h5>
                  <h3 className='cordContent mb-3'>{allData?.checkin_checkout}</h3>
                  <ArrowRight size={24} className='cardIcon' />
                </div>
                <div className='cardImg'>
                  <img src={carImg} className='cardsmallimg' />
                </div>
              </div>
            </Col>
          </Row>
        </Row>
      </Container>
    </>
  )
}

export default Dashboard