import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, Row } from 'react-bootstrap'
import * as Icon from "react-bootstrap-icons";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import S3 from "react-aws-s3";
import Compressor from "compressorjs";
import CryptoJS from 'crypto-js';

const EditIncident = ({ show, close, item }) => {

    const [updatedata, setUpdateData] = useState({
        title: "",
        description: "",
        image: "",
    });

    // state for validation
    const [descError, setDescError] = useState(false)
    const [titleError, setTitleError] = useState(false)
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        setUpdateData({
            title: item?.title,
            description: item?.description,
            image: item?.image,
        });
    }, [item]);

    // for onchange
    let name, value;
    const handleInputs = (e) => {
        name = e.target.name;
        value = e.target.value;
        setUpdateData({ ...updatedata, [name]: value });
    };

    // for validation
    const handlerValidate = () => {
        let FormValid = true;

        if (updatedata.title.length === 0) {
            FormValid = false;
            setTitleError(true);
        }
        else if (updatedata.description.length === 0) {
            FormValid = false;
            setDescError(true);
        }

        return FormValid;
    };

    // function for update data
    const updateIncidentData = async (e) => {
        e.preventDefault();
        setTitleError(false);
        setDescError(false);
        if (handlerValidate()) {
            let upadatePayload = {
                incidentId: item?.incidentId,
                title: updatedata?.title,
                description: updatedata?.description,
                image: updatedata?.image,
            }
            await axios.post(process.env.REACT_APP_BASE_URL + "/incident/update", upadatePayload)
                .then((res) => {
                    toast.success("Updated Successfully")
                    close()
                })
                .catch((err) => {
                    console.log("Error from update api", err);
                })
        }
    }

    // for image
    const handleChange = (e) => {
        if (e.target.files.length > 0) {
            const selectedImage = e.target.files[0];
            setLoader(true);

            if (selectedImage.type.split('/')[0] === 'image') {
                setUpdateData((prevData) => ({ ...prevData, image: URL.createObjectURL(selectedImage) }));

                uploadImageToS3(selectedImage);
                setLoader(false);
            } else {
                toast.error('Please select a valid image file.');
                setLoader(false);
            }
        }
    };
    const decryptData = cyphertext => {
        var bytes = CryptoJS.AES.decrypt(cyphertext, 'foxberry@12345');
        var plaintext = bytes.toString(CryptoJS.enc.Utf8);

        return plaintext;
    };

    const uploadImageToS3 = async (event) => {

        const res = await axios.post(process.env.REACT_APP_BASE_URL + '/credentials/getcredentialsbyname', {
            name: 'AWS'
        })

        let encritedAccessKey = res.data[0]?.accessKey;
        let encritedSecretKey = res.data[0]?.secretKey;
        let encritedbucket = res.data[0]?.bucketName;
        let key = 'foxberry@12345';

        let accessKey = decryptData(encritedAccessKey);
        let secretKey = decryptData(encritedSecretKey);
        let bucket = decryptData(encritedbucket);

        const config = {
            bucketName: bucket,
            region: res.data[0].region,
            accessKeyId: accessKey,
            secretAccessKey: secretKey,
        };

        let fileInput = false;
        const image = event;
        if (event) {
            fileInput = true;
        }
        if (fileInput) {
            new Compressor(image, {
                quality: 0.6,
                success: (compressedResult) => {
                    let getTimeStamp = new Date().getTime();
                    let newFileName = getTimeStamp + "_";
                    const ReactS3Client = new S3(config);

                    ReactS3Client.uploadFile(compressedResult, newFileName)
                        .then((res) => {
                            if (res.status === 204) {
                                handleInputs(res.location);
                            } else {
                                toast.error("AWS Error")
                            }
                        })
                        .catch((err) => {
                            toast.error(err);
                        });
                },
            });

        }
    }


    return (
        <>
            <ToastContainer />
            <Modal show={show} onHide={close} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Incident Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                        <Row style={{ width: '100%' }}>
                            <Form.Label>Edit Incident Title</Form.Label>
                            <Form.Control
                                name="title"
                                value={updatedata?.title}
                                onChange={(e) => {
                                    if (
                                        !e.target.value.match(/[#$&%!~=^_{}\[\]*|//\/?<>;`/\\0-9'.,@()-+]/) ||
                                        e.target.value === ''
                                    ) {
                                        handleInputs(e)
                                    }
                                    updatedata.title.length <= 0
                                        ? setTitleError(true)
                                        : setTitleError(false);
                                }} />
                            {titleError ? (
                                <p className="errMsg"><><Icon.ExclamationTriangle className="me-2" /></>Required Field</p>
                            ) : null}

                        </Row>
                        <Row style={{ width: '100%' }}>
                            <Form.Label>Edit Incident Description</Form.Label>
                            <Form.Control value={updatedata?.description} name="description" onChange={(e) => {
                                if (
                                    !e.target.value.match(/[#$&%!~=^_{}\[\]*|//\/?<>;`/\\0-9'.,@()-+]/) ||
                                    e.target.value === ''
                                ) {
                                    handleInputs(e)
                                }
                                updatedata.description.length <= 0
                                    ? setDescError(true)
                                    : setDescError(false);
                            }}
                            />
                            {descError ? (<p className="errMsg">* Required Field</p>) : null}

                        </Row>
                        <Row style={{ width: '100%' }}>
                            <Form.Label>Edit Incident Image/Logo</Form.Label>
                            <div>
                                {updatedata?.image && <img src={updatedata?.image} style={{ width: 100, height: 100, margin: 10 }} />}
                                <label htmlFor="Image" className="btn btn-primary choosefile">
                                    Choose file
                                </label>
                                <input id="Image" style={{ display: 'none' }} accept="image/*" type="file" onChange={(e) => handleChange(e)} />
                            </div>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={(e) => updateIncidentData(e)}>
                        Edit Incident
                    </Button>
                </Modal.Footer>
            </Modal>
        </>


    )
}

export default EditIncident
