import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

const initialState = {
    volunteerList: '',
    loading: 'idle', // idle, pending, failed

};

export const getVolunteerData = createAsyncThunk("getVolunteerDataPromise", async (data, {rejectWithValue})=>{
    const response = await fetch(process.env.REACT_APP_BASE_URL + '/volunteer/getVolunteers',{
    method:"POST",
    headers:{"Content-Type":"application/json"},
    body:JSON.stringify(data)
    })
    try{
        const result = await response.json()
        return result
        
    }catch(error){
        return rejectWithValue(error); 
    }
  }
  )

// export const getVolunteerData = createAsyncThunk("getVolunteerDataPromise", async (data, {rejectWithValue})=>{
//     const response = await fetch(process.env.REACT_APP_BASE_URL + '/volunteer/getvolunteersbystatus',{
//     method:"POST",
//     headers:{"Content-Type":"application/json"},
//     body:JSON.stringify(data)
//     })
//     try{
//         const result = await response.json()
//         return result
        
//     }catch(error){
//         return rejectWithValue(error); 
//     }
//   }
//   )


const volunteerSlice = createSlice({
  name: 'volunteer',
  initialState,
  // reducers:{
  //   setVolunteeList(state, action){
  //     state.volunteerList = action.payload;
  //   },
  // },
  extraReducers: builder => {
    builder
      .addCase(getVolunteerData.pending, (s, a) => {
        s.loading = 'pending';
      })
      .addCase(getVolunteerData.rejected, (s, a) => {
        s.loading = 'idle';
        // alert('Failed');
      })
      .addCase(getVolunteerData.fulfilled, (s, a) => {
        s.volunteerList = a.payload;
        s.loading = 'success';
      });
  }
});

export const {} = volunteerSlice.actions;
export default volunteerSlice.reducer;