
import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Row } from "react-bootstrap";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from "react-redux";


const VerifyModel = ({ show, close, data, allvolunteer }) => {
  const currentToggle = useSelector(state => state?.SideBarData?.activeToggle)
  const [verifyComment, setVerifyCommet] = useState()
  const [selectedItems, setSelectedItems] = useState([]);


  const handleCheckboxChangeNew = (itemId) => {
    if (selectedItems.includes(itemId)) {
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((item) => item !== itemId)
      );
    } else {
      setSelectedItems((prevSelectedItems) => [...prevSelectedItems, itemId]);
    }
  };

  useEffect(() => {
    return () => {
      setSelectedItems([]);
      setVerifyCommet('')
    };
  }, [data])

  const submitVerify = (verifyStatus) => {
    const payload = {
      _ids: selectedItems,
      verifyStatus: verifyStatus,
      verifyComment: verifyComment
    };
    axios.post(process.env.REACT_APP_BASE_URL + "/volunteer/updateVerificationStatus", payload)
      .then((res) => {
        console.log("response", res)
        if (verifyStatus === "rejected") {
          toast.success("Data Reject Successfully");
        } else if (verifyStatus === "unverified") {
          toast.success("Data sent for re-verification");
        } else if (verifyStatus === "verified") {
          toast.success("Data verified successfully");
        }
        close();
        allvolunteer()
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="pageLayout">
      <ToastContainer />
      <Modal
        show={show}
        onHide={close}
        size="lg"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton className="mngmodelheader">
          <Modal.Title>
            <h5 style={{ fontWeight: "600" }}>Volunteer Details</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body size="lg">
          <Row className="h-100 justify-content-center align-items-center px-3">
            <div className="p-margin">
              <p>
                <span className="fw-bold">Name</span>
                <span>{data?.firstName + " " + data?.lastName}</span>
              </p>

              <p>
                <span className="fw-bold">Contact Number</span>
                <span>{data?.contactNo}</span>
              </p>

              <p>
                <span className="fw-bold">Email Id</span>
                <span>{data?.emailId}</span>
              </p>

              <p>
                <span className="fw-bold mb-2">Incident Type</span>
              </p>

              {data?.incidentTypes
                ?.map((itm, i) => (

                  <div key={i} >
                    <Form.Check
                      type="checkbox"
                      key={i}
                      label={itm?.incidentType}
                      checked={selectedItems.includes(itm._id) || false}
                      onChange={() => handleCheckboxChangeNew(itm._id)}
                    />
                  </div>
                ))}
              <Form.Control
                as="textarea"
                placeholder="Comment..."
                className="mt-2"
                value={verifyComment}
                onChange={(e) => setVerifyCommet(e.target.value)}
              />
            </div>
          </Row>
        </Modal.Body>

        <Modal.Footer className="mt-4">
          {currentToggle === "verified" && (
            <>
              <Button className="primaryBtn" onClick={() => submitVerify("unverified")}>
                Re-Verify
              </Button>
              <Button className="secondaryBtn" onClick={() => submitVerify("rejected")}>
                Reject
              </Button>
            </>


          )}
          {currentToggle === "unverified" && (
            <>
              <Button className="primaryBtn" onClick={() => submitVerify("verified")}>
                Verify
              </Button>
              <Button className="secondaryBtn" onClick={() => submitVerify("rejected")}>
                Reject
              </Button>
            </>
          )}
          {currentToggle === "rejected" && (
            <Button className="primaryBtn" onClick={() => submitVerify("unverified")}>
              Re-Verify
            </Button>
          )}
        </Modal.Footer>

      </Modal>
    </div >
  );
};

export default VerifyModel;
