import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

const initialState = {
    SosList: '',
    loading: 'idle'
};

export const getSosData = createAsyncThunk("getSosDataPromise", async (data, {rejectWithValue})=>{
    const response = await fetch(process.env.REACT_APP_BASE_URL + data ,{
    method:"GET",
    headers:{"Content-Type":"application/json"}
    })
    try{
        const result = await response.json()
        return result
    }catch(error){
        return rejectWithValue(error); 
    }
  }
  )
const sosSlice = createSlice({
  name: 'sos',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(getSosData.pending, (s, a) => {
        s.loading = 'pending';
      })
      .addCase(getSosData.rejected, (s, a) => {
        s.loading = 'idle';
      })
      .addCase(getSosData.fulfilled, (s, a) => {
        s.SosList = a.payload;
        s.loading = 'success';
      });
  }
});


export default sosSlice.reducer;