import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';

const VolunteerDetails = () => {
  return (
    <div className='pageLayout'>
        <Button style={{color:"#183F91",background:"#ffff",paddingLeft:"1.5rem",paddingRight:"1.5rem"}}>Back</Button>
        <Row style={{display:"flex",justifyContent:"center",marginTop:"42px"}}>
          <p style={{textAlign:"center",fontSize:"26px",fontWeight:'400'}}>Volunteer Details </p>
          </Row>
            <Row style={{display:"flex",justifyContent:"center"}}>
            <Card className='card' style={{ width: '40rem',height:"15rem" }}>
            <Card.Body className='m-3' style={{display:"flex" }}>
             <div className='d-flex' style={{marginLeft:"65px"}}>
              <div >
                <p>Name<br/> Mobile Number<br/>incident Type<br/>Adhar Number<br/>Email Id<br/>Address</p>
              </div>
              <div style={{marginLeft:"56px"}}>
              <p>Jitendra<br/> 9657149461<br/>Accident<br/>209900804870<br/>Jit@gmail<br/>premraj recidency anandnagr old sangvi</p>

              </div>
               {/* <p>Name<span className='ms-5'>Jitendra Pawar</span></p>
                <p>Number Number<span className='ms-5'>Jitendra Pawar</span></p>
                <p>Incident Type<span className='ms-5'>Jitendra Pawar</span></p>
                <p>Adhar Number<span className='ms-5'>Jitendra Pawar</span></p>
                <p>emil Id<span className='ms-5'>Jitendra Pawar</span></p>
                <p>Address<span className='ms-5'>Jitendra Pawar</span></p> */}

             </div>
          </Card.Body>
           </Card>
            </Row>
            <Row>
              <div style={{display:"flex",justifyContent:"center"}}>
            <Form.Group  controlId="exampleForm.ControlTextarea1" style={{width:"40rem" ,marginTop:"16px"}}>
           {/* <Form.Label>Example textarea</Form.Label> */}
           <Form.Control as="textarea" rows={2} className='W-100' placeholder='comment' />
           </Form.Group>
           </div>

            </Row>
            <Row style={{display:"flex",justifyContent:"center"}}>
              <div style={{display:"flex",justifyContent:"end",width:"40rem"}}>
                <div className='m-4' style={{display:"flex"}} >
              <Button style={{background:"#FFF",color:'red',border:"1px solid red"}}>Decline</Button>
              <Button style={{marginLeft:"8px",background:"#183F91"}}>verify</Button>
              </div>
           </div>

            </Row>
            <Row>
            <div style={{display:"flex",justifyContent:'flex-end'}}>
              
            </div>
            </Row>

    </div>
  )
}

export default VolunteerDetails