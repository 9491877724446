import React, { useEffect, useState } from 'react'
import logo from "../../assets/spslogo.png";
import { Button, Card, Col, Container, InputGroup, Row, Spinner } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "./LoginComp.css"
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUserData, setLogout } from './loginSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setActiveKey } from '../Sidebar/sidebarSlice';
import * as Icon from 'react-bootstrap-icons';
import Login from "../../assets/loginImage.png"

const LoginComp = () => {
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userState = useSelector(state => state?.loginData?.userData);

// ----------------- Data State--------------------------
  const [mobile,setMobile] = useState()
  const [password,setpassword] = useState()

  // ----------------- Data Error State--------------------------
  const [mobileError, setmobileError] = React.useState()
  const [passwordError, setpasswordError] = React.useState()

    // ----------------- Data Error State--------------------------
  
  const [loader, setLoader] = useState(false);
  const [showPass, setShowPass] = useState(false);
  
  // for backbutton
  useEffect(() => {
    if (userState) {
      navigate("/")
    }
  }, []);

  const NoUserFound = () => toast.error("Invalid credentials, No user found", {toastId:Math.random()});
  const LoginSuccess = () => toast.success("Login Successfully");

  useEffect(() => {
    if (userState) {
     if (userState.errors === 'No user found') {
        NoUserFound();
        dispatch(setLogout(null));
        setTimeout(() => {
          setLoader(false);
        setMobile('')
        setpassword('')
        },2000)
      }else{
        LoginSuccess();
        setLoader(false)
        toast.success("Login Successfully", {
          onClose: () => {
            navigate("/admin/dashboard");
          }
        });
      }
    }
  }, [userState])


 
  // useEffect(() => {
  //   if(userState){
  //       navigate("/admin/dashboard")
  //       }
  // }, [userState])

  const validate = () => {
    let FormValid = true;

    if (!mobile) {
      FormValid = false;
      setmobileError(true);
    }
    else if (!password) {
      FormValid = false;
      setpasswordError(true);
    }
    return FormValid;
  }

  const handlerSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    if (validate()) {
      dispatch(getUserData({mobile:mobile,password:password,}));
      dispatch(setActiveKey({ activeKey: 1, activeTab: 'Dashboard' }));
   } else {
      setLoader(false);
      
    }
}
  return (
    <div className="login">
      <ToastContainer position="top-right" autoClose={1000}/>
      <Container fluid className='h-100'>
        <Row className="parent">
          <Col xl={5} lg={12} md={12} className='LeftSideContainer left d-flex flex-column justify-content-center align-items-center'>
          <Row className='w-100 m-auto'>
            <Col xl={12} lg={12} md={12} className="text-center">
              <img className='img-fluid' src={logo} width={300} />
              <h4 style={{ fontWeight: "800", color: "#052F7E", margin: '12px 0 0 0',lineHeight:'49px' }}>Safety Positioning System</h4>
            </Col>
          </Row>
        </Col> 
        
          <Col xl={7} lg={12} className='RightSideContainer right py-0 py-md-0'>

            <Row className='loginHeadding'>LOGIN</Row>
              <Card className='cardmain mt-2'>
                <div data-aos='fade-up' data-aos-delay="100" className=' mt-2'>
                 <p className='text-center subHeading m-0'><Icon.BoxArrowInRight className="me-2" />Please login to your account</p>
               </div>
                <Card.Body className='cardBody px-5 py-2' >
                  <Form onSubmit={handlerSubmit} >
                    <Form.Group className="mb-3" data-aos="fade-up" data-aos-delay="100">
                  <Form.Label>
                    Mobile<span> *</span>
                  </Form.Label>
                  <InputGroup>
                    <InputGroup.Text><Icon.Telephone size={20} /></InputGroup.Text>
                    <Form.Control
                      value={mobile}
                      name="mobile"
                      id="mobile"
                      placeholder="Enter Mobile Number*"
                      onChange={(e) => {
                        if (!e.target.value.match(/[^0-9]/) && e.target.value.length <= 10) {
                          setMobile(e.target.value)
                        }
                        mobile?.length < 0 ? setmobileError(true) : setmobileError(false)
                      }}
                    />
                  </InputGroup>
                  {mobileError ? <p className='errMsg'>Please enter a valid mobile number</p> : null}
                </Form.Group>

                <Form.Group className="mb-3" data-aos="fade-up" data-aos-delay="100">
                  <Form.Label className="d-flex align-items-center">
                    Password<span> *</span>
                  </Form.Label>
                  <InputGroup>
                    <InputGroup.Text onClick={() => setShowPass(!showPass)}>
                      {showPass ? <Icon.EyeSlash size={20} style={{ cursor: 'pointer' }} onClick={() => setShowPass(!showPass)} /> :
                        <Icon.Eye size={20} style={{ cursor: 'pointer' }} />}
                    </InputGroup.Text>
                    <Form.Control type={showPass ? "text" : "password"} placeholder="Enter Password"
                      value={password}
                      onChange={(e) => {
                        if (!e.target.value.match(
                            /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$/g) && e.target.value.trim()) {
                            setpassword(e.target.value)
                            } else if (e.target.value.length === 0) {
                            setpassword(e.target.value)
                            }
                          password?.length >= 0 ? setpasswordError(false) : setpasswordError(true);
                          }}  
                        />
                  </InputGroup>
                  {passwordError ? <p className='errMsg'>Please enter a valid Password</p> : null}
                </Form.Group>
                {
                    loader ? <center><Spinner animation="border" variant="dark"/></center> :
                      <Button type='submit' className='loginButton w-100'>
                        <Icon.BoxArrowInRight size={18} className='me-2' /> SIGN IN
                      </Button>
                  }

                  <p className='text-end mt-2' style={{ fontSize: '12px', textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() => navigate('/forgot-pasword')}>Forgot Password ?</p>
                  </Form>
                
                </Card.Body>
            </Card>
          {/* <Row className='loginHeadding'>LOGIN</Row>
            <Card className='cardmain'>
              <Card.Body className='cardBody p-5' >
                <Form.Control 
                  className='inputField'
                  value={mobile}
                  name = "mobile"
                  placeholder="Enter Mobile *"
                  onChange={(e) => {
                      if (!e.target.value.match(/[A-Za-z+@#$&%!~=*./\s/g/\-/?<>,"/]/) && e.target.value.length <= 10) {
                          setMobile(e.target.value)
                        }
                        mobile?.length < 0 ? setmobileError(true) : setmobileError(false)
                  }}
                />
                {mobileError ? <p className='errMsg'>Please enter valid mobile number</p> : null}
                      
                <Form.Control className='inputField'
                    value={password}
                    placeholder="Enter Password *"
                    onChange={(e) => {
                      if (!e.target.value.match(
                          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$/g) && e.target.value.trim()) {
                          setpassword(e.target.value)
                          } else if (e.target.value.length === 0) {
                          setpassword(e.target.value)
                          }
                        password?.length >= 0 ? setpasswordError(false) : setpasswordError(true);
                        }}  
                      />
                        {passwordError ? <p className='errMsg'>Please enter valid password</p> : ''}
                      

                    {
                      loader ? <center><Spinner animation="border" variant="warning" /></center> :
                        <Button type='submit' className='loginButton'  onClick={handlerSubmit}  size="lg">
                          <Icon.BoxArrowInRight size={18} className='me-2 ' /> Login
                        </Button>
                    }
              </Card.Body>
            </Card> */}
          </Col>
      </Row>
      </Container>

      
    </div>
  )
}

export default LoginComp

