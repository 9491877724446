import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup, Col, Row, ToggleButton } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { getCybercrimePoliceStation, getVolunteerList } from './CybercrimeListSlice';
import TableData from '../../Component/Common/Tables';
import { useLocation, useNavigate, useNavigation } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

const AssignCybercrimePolice = () => {
  const dispatch = useDispatch()
  const navigation = useNavigate()

  // data from cybercrime list
  const { state } = useLocation();
  const cyberCrimeId = state?.data;

  const [loading, setLoading] = useState(true);

  // toast notification
  const policeStationAssigned = () =>{
    toast.success("Police Station Assigned successfully", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose:2000
      });
  }

  // data from store
  const cybercrimePoliceStationList = useSelector(state=>state?.cybercrimeListData?.cybercrimePoliceStation)
  const loginData = useSelector(state => state.loginData.userData.user._id)
  
  useEffect(()=>{
    dispatch(getCybercrimePoliceStation("/cybercrime/getCyberCrimePoliceStation"))
    setLoading(false)
  },[])

  // Assign police station 
  const assignPoliceStation = async(itm) =>{
    const payload = {
      policeStation_id: itm._id,
      cyberCrimeIncident_id: cyberCrimeId,
      updatedBy:loginData
    }
    await axios.post(process.env.REACT_APP_BASE_URL + "/cybercrime/assignpolicestation",payload)
    .then((res)=>{
      console.log("res",res.data.msg)
      if(res.data.msg === "Police Station Assign Successfully"){
        policeStationAssigned()
          setTimeout(()=>{
            navigation("/admin/CyberCrime")
          },1000)
      }
    })
    .catch((error)=>console.log("error",error))
  }

  // Police data
  const colums = [
    { header: 'Station', field: 'station' },
    { header: 'Telephone', field: 'telephone' },
    { header: 'Email Id', field: 'emailId' },
    { header: 'Distance', field: 'distance' },
    { header: 'Action', field: 'action' },
  ];

  const policeStation = cybercrimePoliceStationList?.data !== undefined && 
                        cybercrimePoliceStationList?.data !== null && 
                        cybercrimePoliceStationList?.data.length > 0 ? 
                        cybercrimePoliceStationList?.data.map((itm)=>{
                          return {
                            station: itm?.stationName,
                            telephone: itm?.telephoneNo,
                            emailId : itm?.emailId, 
                            distance: "",
                            action:<Button className='primaryBtn' onClick={()=>assignPoliceStation(itm)}>
                                      Assign
                                    </Button>,
                          };
    }) : []
                       
  return (
    <>
      <ToastContainer/>
      <div className="pageLayout">
    <div className='m-3'>
      <Button className='primaryBtn' onClick={() => window.history.back()}>Back</Button>
    </div>
      {
        loading ? (
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div> 
        ) : 
        (<TableData data={policeStation} columns={colums} />)
      }
      </div>
    </>
  )
}

export default AssignCybercrimePolice