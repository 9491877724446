import React, { useEffect, useState } from 'react'
import { getVolunteerList } from './CybercrimeListSlice';
import { useDispatch, useSelector } from 'react-redux';
import TableData from '../../Component/Common/Tables';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

export const AssignCybercrimeVolunteer = () => {
    const dispatch = useDispatch()
    const navigation = useNavigate()

    // data from cybercrime list
    const { state } = useLocation();
    const cyberCrimeId = state?.data;

    const [loading, setLoading] = useState(true);

    // data from store
    const volunteerList = useSelector(state =>state.cybercrimeListData.volunteerList)

    // toast notification
    const volunteerAssigned = () =>{
      toast.success("Volunteer Assigned successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose:2000
        });
    }

    useEffect(()=>{
        dispatch(getVolunteerList("/volunteer/getVolunteersOfCyberCrime"))
        setLoading(false)
    },[])

    // assign volunteer
    const assignCybercrimeVolunteer = async(itm) =>{
      const payload = {
        volunteerId : itm?._id,
        cyberCrimeIncident_Id : cyberCrimeId,
        userId  : itm.userId,
        firstName : itm.firstName,
        lastName  : itm.lastName,
        contactNo : itm.contactNo,
        emailId : itm.emailId,
        volunteers_id : itm?._id
      }
      await axios.post(process.env.REACT_APP_BASE_URL + 
                      "/cybercrime/assign/volunteer/cybercrimeincident",payload)
      .then((res)=>{
        console.log("res",res)
        if(res?.data?.msg === "Assign Volunteer Successfully"){
          volunteerAssigned()
          setTimeout(()=>{
            navigation("/admin/CyberCrime")
          },1000)
        }
      })
      .catch((error)=>{
        console.log(error)
      })
    }

    // volunteer data
    const columns = [
        { header: 'Name', field: 'Name' },
        { header: 'Contact Number', field: 'contact_number' },
        { header: 'Email Id', field: 'email' },
        { header: 'Status', field: 'status' },
        { header: 'Action', field: 'action' },
      ];
    
    const volunteer = volunteerList !== undefined && volunteerList !== null && volunteerList.length > 0 ? 
                      volunteerList.map((itm)=>{return {
                          Name: itm?.firstName + " " + itm?.lastName,
                          contact_number: itm?.contactNo,
                          email: itm?.emailId,
                          status: itm?.status,
                          action: <Button onClick={()=>assignCybercrimeVolunteer(itm)} className='primaryBtn'>Assign</Button>,
                        };
    }) : []

  return (
    <>
      <ToastContainer/>
      <div  className="pageLayout">
      <div className='m-3'>
        <Button className='primaryBtn' onClick={() => window.history.back()}>Back</Button>
      </div>

      {
          loading ? (
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div> 
          ) : 
          (<TableData data={volunteer} columns={columns} />)
        }
      </div>
    </>
    
  )
}
