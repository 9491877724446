import React, { useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { Icon } from '@iconify/react';
import Select from 'react-select'

const DateRange = ({ fromDate, toDate, handleFromChange, handleToChange, handleFilter, handleReset }) => {
  return (
    <div className='m-5'>
      <Row className='d-flex align-items-center justify-content-start align-items-center  w-100 mt-5'>
        <Row style={{ width: '100%' }}>
          <Col sm={12} md={4} className='ps-0 mb-2 mb-md-0 '>
            <Form.Group className='w-100 THIS'>
              <Form.Label> From </Form.Label>
              <Form.Control className='w-100' type="date" value={fromDate} onChange={(e) => handleFromChange(e)} />
            </Form.Group>
          </Col>

          <Col sm={12} md={4} className='ps-0'>
            <Form.Group>
              <Form.Label> To </Form.Label>
              <Form.Control type="date" value={toDate} min={fromDate} onChange={(e) => handleToChange(e)} />
            </Form.Group>
          </Col>

          <Col sm={12} md={4} className='mt-4'>
            <Button style={{ background: "#183F91" }}
              onClick={handleFilter}
            >
              <Icon icon="subway:round-arrow-1" />
            </Button>
            <Button style={{ background: "#183F91" }} className='ms-2' onClick={() => handleReset()}>Reset</Button>
          </Col>
        </Row>

      </Row>
    </div >
  )
}

export default DateRange