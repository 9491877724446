import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

const initialState = {
    TrackMeList: '',
    scrollTo: '',
    highlight: '',
    glowSos: [],
    dontGlowSos: [],
    previousSos: [],



    loading: 'idle', // idle, pending, failed

};

export const getTrackMeData = createAsyncThunk("getTrackMeDataPromise", async (data, {rejectWithValue})=>{
    const response = await fetch(process.env.REACT_APP_BASE_URL + data ,{
    method:"GET",
    headers:{"Content-Type":"application/json"}
    })
    try{
        const result = await response.json()
        return result
    }catch(error){
        return rejectWithValue(error); 
    }
  }
  )
const trackMeSlice = createSlice({
  name: 'trackMe',
  initialState,
  reducers: {
    setScrollTo: (state, action) => {
      state.scrollTo = action.payload;
    },
    setHighlightTo: (state, action) => {
      state.highlight = action.payload;
    },
    setGlowSos: (state, action) => {
      state.glowSos = action.payload;
    },
    setPreviousSos: (state, action) => {
      
      state.previousSos = action.payload;
    },
   
    setDontGlowSos: (state, action) => {
      state.dontGlowSos = action.payload;
    },
  },

  extraReducers: builder => {
    builder
      .addCase(getTrackMeData.pending, (s, a) => {
        s.loading = 'pending';
      })
      .addCase(getTrackMeData.rejected, (s, a) => {
        s.loading = 'idle';
      })
      .addCase(getTrackMeData.fulfilled, (s, a) => {
        s.TrackMeList = a.payload;
        s.loading = 'success';
      });
  }
  
});

export const { setScrollTo, setHighlightTo, setGlowSos,setPreviousSos, setDontGlowSos } = trackMeSlice.actions;

export default trackMeSlice.reducer;