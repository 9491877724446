import React from 'react';
import './Slider.css';
import { Button, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { CaretLeft, CaretRight, Download } from 'react-bootstrap-icons';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { setActiveKey } from '../../Component/Sidebar/sidebarSlice';
import { useDispatch } from 'react-redux';

function SlideDrawer({ show, title, incidentData, cyberCrimeData, onClose }) {
   const dispatch = useDispatch()
   const navigation = useNavigate()
   const drawerClasses = show ? 'side-drawer open' : 'side-drawer';

   // for date format
   let createdAtDate = null;

   if (cyberCrimeData) {
      createdAtDate = new Date(cyberCrimeData.createdAt);
   } else if (incidentData) {
      createdAtDate = new Date(incidentData.createdAt);
   }

   let formattedDate = "";
   let formattedTime = "";

   if (createdAtDate) {
      formattedDate = createdAtDate.toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' });

      formattedTime = createdAtDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true, });
   }

   const responsive = {
      superLargeDesktop: {
         breakpoint: { max: 4000, min: 1024 },
         items: 5,
         slidesToSlide: 3,
      },
      desktop: {
         breakpoint: { max: 1024, min: 800 },
         items: 4,
      },
      tablet: {
         breakpoint: { max: 800, min: 464 },
         items: 2,
      },
      mobile: {
         breakpoint: { max: 464, min: 0 },
         items: 1,
      }
   };

   const downloadImage = async (imageUrl, imageName) => {
      try {
         window.open(imageUrl)
      } catch (error) {
         console.error('Error downloading image:', error);
      }
   };

   return (
      <div className={drawerClasses}>
         {cyberCrimeData ?
            <div style={{ width: "100%", position: "relative" }}>
               <h2 style={{ textAlign: "center" }}>{title}</h2>
               <hr style={{ color: "##183F91", width: "100%" }} />
               <Row className='m-3'>
                  <Col className='fw-bold'>Name</Col>
                  <Col>{`${cyberCrimeData?.firstName} ${cyberCrimeData?.lastName}`}</Col>
               </Row>
               <Row className='m-3'>
                  <Col className='fw-bold'>Incident</Col>
                  <Col>{cyberCrimeData?.incidentType}</Col>
               </Row>
               <Row className='m-3'>
                  <Col className='fw-bold'>Address</Col>
                  <Col>{cyberCrimeData?.address}</Col>
               </Row>
               <Row className='m-3'>
                  <Col className='fw-bold'>Incident Time & Date</Col>
                  <Col>{cyberCrimeData?.date} {cyberCrimeData?.incidentTime}</Col>
               </Row>
               <Row className='m-3'>
                  <Col className='fw-bold'>Reported Time & Date</Col>
                  <Col>{`${formattedDate} ${formattedTime}`}</Col>
               </Row>
               <Row className='m-3'>
                  <Col className='fw-bold'>Details</Col>
                  <Col>{cyberCrimeData?.details}</Col>
               </Row>
               <Row className='m-3'>
                  <Col className='fw-bold'>Assigned Police Station</Col>
                  <Col>{cyberCrimeData?.details}</Col>
               </Row>
               <Row className='m-3'>
                  <Col className='fw-bold'>Assigned Volunteer</Col>
                  <Col>{cyberCrimeData?.details}</Col>
               </Row>
               <Row className='m-3'>
                  <Col className='fw-bold'>Attachments</Col>
               </Row>
               {cyberCrimeData.uploadEvidenceURL.length > 0 ? <Carousel responsive={responsive} className='corousalImg'>
                  <div className='img-div'>
                     <img className="cyberImages" alt='Your Image'
                        src={cyberCrimeData?.uploadEvidenceURL[0]}
                     // src='https://hips.hearstapps.com/hmg-prod/images/close-up-image-of-the-beautiful-vibrant-orange-royalty-free-image-1580855109.jpg?crop=0.668xw:1.00xh;0.0306xw,0&resize=980:*'
                     />
                     <Download className='downldIcn' size={20} onClick={() => {
                        downloadImage(cyberCrimeData?.uploadEvidenceURL[0], cyberCrimeData?.cyberCrimeIncidentId)
                     }} />
                  </div>
               </Carousel> : 'No Image uploaded'}
               <div style={{ display: "flex", position: "absolute", bottom: 20, right: 10 }}>
                  {
                     cyberCrimeData?.isPoliceStationAssign === true ?
                        <Button className='assiBtn me-3'>Police Station Assigned</Button> :
                        <Button className='primaryBtn me-3'
                           onClick={() => {
                              navigation("/admin/policeStationList", dispatch(setActiveKey({ activeKey: 5, activeTab: 'Police' })), {
                                 state: { data: cyberCrimeData?._id }
                              });
                           }}>
                           Assign Police Station
                        </Button>
                  }

                  {cyberCrimeData?.isCybercrimeVolunteerAssign === true ?
                     <Button className='assiBtn me-3'>Volunteer Assigned</Button> :
                     <Button className='primaryBtn me-3'
                        onClick={() => navigation("/admin/volunteer", dispatch(setActiveKey({ activeKey: 4, activeTab: 'Volunteer' })),
                           { state: { data: cyberCrimeData?._id } })}>
                        Assign Volunteer
                     </Button>}

               </div>
            </div>
            :
            <div style={{ width: "100%" }}>
               <h1 style={{ textAlign: "center" }}>{title}</h1>
               <hr style={{ color: "##183F91", width: "100%" }} />
               <Row className='m-2'>
                  <Col className='fw-bold'>Name</Col>
                  <Col>{`${incidentData?.first_name} ${incidentData?.last_name}`}</Col>
               </Row>
               <Row className='m-2'>
                  <Col className='fw-bold'>Incident</Col>
                  <Col>{incidentData?.reportincidentid}</Col>
               </Row>
               <Row className='m-2'>
                  <Col className='fw-bold'>Address</Col>
                  <Col>{incidentData?.address}</Col>
               </Row>
               <Row className='m-2'>
                  <Col className='fw-bold'>Incident Time & Date</Col>
                  <Col>{incidentData?.date} {incidentData?.incidentTime}</Col>
               </Row>
               <Row className='m-2'>
                  <Col className='fw-bold'>Reported Time&Date</Col>
                  <Col>{`${formattedDate} ${formattedTime}`}</Col>
               </Row>
               <Row className='m-2'>
                  <Col className='fw-bold'>Details</Col>
                  <Col>{incidentData?.details}</Col>
               </Row>
               <Row className='m-2'>
                  <Col className='fw-bold'>Attachments</Col>
               </Row>
               <Row className='m-1'>
                  <Col>
                     <img
                        src={incidentData?.uploadEvidenceURL}
                        alt="Your Image"
                        style={{
                           width: '100px',
                           height: '100px',
                        }}
                     />
                  </Col>
               </Row>
            </div>
         }
      </div>
   );
}

export default SlideDrawer;
