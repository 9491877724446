import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  cybercrimeList: [],
  volunteerList: [],
  cybercrimePoliceStation: [],
  loading: "idle",
};

export const getCybercrimeList = createAsyncThunk(
  "getCybercrimeDataPromise",
  async (data, { rejectWithValue }) => {
    const response = await fetch(process.env.REACT_APP_BASE_URL + data, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    try {
      const result = await response.json();
      console.log(result)
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getVolunteerList = createAsyncThunk(
  "getVolunteerDataPromise",
  async (data, { rejectWithValue }) => {
    const response = await fetch(process.env.REACT_APP_BASE_URL + data, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    try {
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCybercrimePoliceStation = createAsyncThunk(
  "getCybercrimePoliceStation",
  async (data, { rejectWithValue }) => {
    const response = await fetch(process.env.REACT_APP_BASE_URL + data, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    try {
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const CybercrimeSlice = createSlice({
  name: "cybercrime",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getCybercrimeList.pending, (s, a) => {
        s.loading = "pending";
      })
      .addCase(getCybercrimeList.rejected, (s, a) => {
        s.loading = "idle";
      })
      .addCase(getCybercrimeList.fulfilled, (s, a) => {
        s.cybercrimeList = a.payload;
        s.loading = "success";
      })

      .addCase(getVolunteerList.pending, (s, a) => {
        console.log("inside loading");
        s.loading = "pending";
      })
      .addCase(getVolunteerList.rejected, (s, a) => {
        s.loading = "idle";
      })
      .addCase(getVolunteerList.fulfilled, (s, a) => {
        s.volunteerList = a.payload;
        s.loading = "success";
      })

      .addCase(getCybercrimePoliceStation.pending, (s, a) => {
        console.log("inside loading");
        s.loading = "pending";
      })
      .addCase(getCybercrimePoliceStation.rejected, (s, a) => {
        s.loading = "idle";
      })
      .addCase(getCybercrimePoliceStation.fulfilled, (s, a) => {
        s.cybercrimePoliceStation = a.payload;
        s.loading = "success";
      });
  },
});

// export const {setCybercrimeList} = CybercrimeSlice.actions;
export default CybercrimeSlice.reducer;
