import { combineReducers, configureStore } from '@reduxjs/toolkit';
// import LoginReducer from '../Features/Auth/Login/LoginSlice';
// import LoginReducer from "../Component/Auth/loginSlice"
import LoginReducer from "../Component/Auth/loginSlice"
import SidebarReducer from "../Component/Sidebar/sidebarSlice"
import VolunteerReducer from "../pages/Volunteer/VolunteerSlice"
import IncidentListReducer from "../pages/Incident/IncidentListSlice"
import CybercrimeListReducer from '../pages/Cybercrime/CybercrimeListSlice';
import TrackMeReducer from '../pages/Trackme/TrackMeSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import SosListReducer from "../pages/Sos/SosListSlice"
import thunk from 'redux-thunk';
import SosReducer from '../pages/Sos/SosSlice';
import PoliceStationReducer from "../pages/PoliceStation/PoliceSlice"
// import sidebarReducer from '../Features/Common/ActiveKeySlice';
// import ProjectReducer from '../Features/Auth/Login/ProjectSlice';
// import MngReducer from "../Features/Manager/MngSlice";
// import TLReducer from '../Features/TeamLead/TLSlice';
// import AllTaskReducer from '../Features/Common/AllTaskSlice';
// import employeeReducer from '../Features/HR/HrSlice'



const reducers = combineReducers({
  loginData: LoginReducer,
  SideBarData : SidebarReducer,
  volunteerData: VolunteerReducer,
  cybercrimeListData : CybercrimeListReducer,
  IncidentListData : IncidentListReducer,
  TrackMelistData : TrackMeReducer,
  SosListData : SosListReducer,
  sosData : SosReducer,
  PoliceStationData : PoliceStationReducer,
})

const persistConfig = {
  key: 'root',
  storage
}

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);

